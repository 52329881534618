import { SocContactsActions, SocRemediationActions } from "@/constants/soc";
import {
  SocContactClosureNotification,
  SocContactNotificationTypes,
} from "@/_store/soc/soc-contact.module";
import { SocRemediationType } from "@/_store/soc/soc-remediation.module";
import { SensitiveDataScansActions } from "@/constants/endpoint-data-governance";
import {
  TicketTrigger,
  TicketType,
  TicketAction,
  TicketActionSeverity,
  SocStatus,
  TicketsModal,
  CommentAction,
} from "@/constants/tickets";
import {
  MspWorkspaceAction,
  SubscriptionAddon,
  SubscriptionBundle,
  SubscriptionModule,
  SubscriptionType,
  WorkspaceAction,
  WorkspaceLocale,
  WorkspaceType,
} from "@/constants/workplaces";
import { ConnectorActions, CoroProductNames, PsaProviders } from "@/constants/connectors";
import {
  AgentDeploymentAction,
  AgentDeploymentChannel,
  DeviceAction,
  DeviceAllowBlockListTypes,
  DeviceAllowListActions,
  DevicePostureAction,
  DriveEncryptionStatus,
  LabelAction,
} from "@/constants/devices";
import {
  GlobalRoleScopes,
  GlobalRoleScopeSection,
  MspPortalScopeSection,
  MspRolePermissionScopes,
  ProtectionScopeModules,
  RolePermissionsScope,
  WorkspaceManagementScopeSections,
} from "@/_store/roles.module";
import {
  GlobalRolesAction,
  MspRolesAction,
  RolePermissionAccessMode,
  RolesAction,
} from "@/constants/roles";
import {
  AdminUsersAction,
  GlobalAdminUsersAction,
  MspAdminUsersAction,
} from "@/constants/admin-accounts";
import {
  AccessPermissionAction,
  Service,
  ServiceStatus,
  ServiceAction,
  ThirdPartyAppsAction,
} from "@/constants/cloud-apps";
import { ActiveSessionsAction } from "@/constants/active-session";
import { EdrAction } from "@/constants/edr";
import { ExceptionsAction, UserAction, UserGroupAction } from "@/constants/users";
import { DataLossAction } from "@/constants/user-data-governance";
import { PhishingAction } from "@/constants/phishing";
import { EmailProxyAction, ProxySecurityMode } from "@/constants/email-proxy";
import { ActivityLogsType } from "@/constants/activity-logs";
import { MalwareAction } from "@/constants/malware-settings";
import { BackOfficeOverviewWidgetType, WorkspaceCodeActions } from "@/constants/back-office";
import { ExportCategory, ExportChoice, ExportFileExtension } from "@/_store/exports.module";
import { ExportAction } from "@/constants/exports";
import { ActivateMobileErrorType } from "@/views/ActivateMobilePage.vue";
import {
  WorkspaceCodeRelationType,
  WorkspaceCodeStatus,
  WorkspaceCodeType,
} from "@/_store/back-office/workspace-codes.module";
import {
  WebhookAction,
  WebhookStatus,
  WebhookTriggerType,
  WebhookTriggerTestStatus,
} from "@/constants/webhooks";
import {
  SecureWebGatewayAction,
  SiteToSiteTunnelsAction,
  VirtualOfficeAction,
  ZtnaAction,
} from "@/constants/network";
import {
  SiteToSiteTunnelStatus,
  SiteToSiteTunnelTestState,
} from "@/_store/network/site-to-site-tunnels.module";
import { ScheduledReportFrequency, ScheduledReportScope } from "@/_store/scheduled-reports.module";
import { ScheduledReportsAction } from "@/constants/scheduled-reports";
import { ThirdPartyAppStatus } from "@/_store/cloud-apps/third-party-apps.module";
import { BillingAction } from "@/constants/billing";
import {
  UserSettingsAppStatus,
  UserSettingsJoinedVia,
} from "@/_store/users-settings/users-tab.module";
import { EmailSettingsAction, SecurityMode, ScanSensitivityLevels } from "@/constants/emails";
import { ReportType } from "@/_store/reports.module";
import { MessageType } from "@/constants/messages";
import { AccountAction, AccountErrors } from "@/constants/account";
import type { MasterTranslationsSchema } from "@/translations/en";
import { NotificationsLevel } from "@/_store/msp.module";
import { es as vuetifyEsLocale } from "vuetify/locale";

export const es: MasterTranslationsSchema = {
  $vuetify: {
    ...vuetifyEsLocale,
  },
  validations: {
    invalidWorkspaceCode: "Utilice un código de Workspace válido",
    required: "El campo es obligatorio",
    domain: "Utilice solo dominios válidos",
    emailOrDomain: "Utilice solo correos electrónicos o dominios válidos",
    emailOrDomainOrIp:
      "Utilice solo correos electrónicos/dominios o rangos de IP/direcciones IP válidos",
    domainOrIp: "Please use only valid domains or IPs/IP ranges",
    workspaceShouldMatch: "Escriba el Workspace que quiere eliminar",
    onlyInteger: "Utilice solo números enteros",
    bundleOrModuleIsRequired: "El paquete o el módulo es obligatorio",
    onlyDigits: "El campo solo puede incluir dígitos",
    ip: "Utilice direcciones ipv4 o ipv6 válidas",
    ip4: "Utilice una dirección ipv4 válida",
    email: "Utilice solo correos electrónicos válidos",
    password:
      "La contraseña debe tener un mínimo de 8 símbolos e incluir al menos 1 mayúscula, 1 minúscula y 1 símbolo numérico",
    general: "Corrija los errores a continuación",
    matchesOldPassword: "Su contraseña no puede ser igual que la anterior",
    incorrectOldPassword: "Contraseña anterior incorrecta",
    maxNCharacters: "El campo no puede tener más de {n} caracteres",
    maxNItems: "No puede tener más de {n} elementos seleccionados",
    minNCharacters: "El campo no puede tener menos de {n} caracteres",
    json: "Cargue un archivo JSON válido",
    boxConfigurationFile: "Cargue un archivo de configuración de Box válido",
    onlyPositiveNumbers: "Solo se permiten números mayores que 0",
    maxNumberAllowed: "Solo se permiten números menores que {n}",
    minNumberAllowed: "Solo se permiten números mayores que {n}",
    maxNDecimalPoints: "El archivo no puede tener más de {n} puntos decimales",
    noSpecialCharacters: "Los caracteres especiales no están permitidos",
    onlyCertainSpecialCharactersAllowed:
      "Solo se permiten los siguientes caracteres especiales: {characters}",
    invalidSubdomain: "Subdominio no válido. Solo se permiten letras, números y guiones",
    strengthMeter: {
      great: "Excelente",
      good: "Buena",
      soso: "Regular",
      veryWeak: "Muy poco segura",
    },
    nDigits: "El campo debe contener exactamente {n} dígitos",
    invalidVerificationCode: "Código de verificación incorrecto",
    invalidPhoneNumber: "Número de teléfono no válido",
    tooManyOtpResetRequests:
      "Demasiadas solicitudes de SMS. La siguiente solicitud estará disponible en 1 minuto.",
    domainZoneIsNotAllowed: "La zona de dominio no está permitida",
    noSpacesAllowed: "Los espacios no están permitidos",
    invalidHexColor: "Color HEX no válido",
    maximumAllowedKeywords: "El número máximo de palabras clave es 200",
    script: "Cargue solo secuencias de comandos con extensiones permitidas",
    ssid: "Utilice solo nombres de red válidos",
    url: "La URL no es válida",
    domainAlreadyExists: "El dominio ya existe",
    emailIsNotInTheListOfProxyDomains:
      "La dirección de correo electrónico no pertenece a los dominios de servidores proxy enumerados",
    ipOrDomain: "Dominio o dirección IP no válidos. Introduzca valores válidos.",
    ipAddress: "Utilice una dirección IP válida.",
    atLeastOneFieldRequired: "Se requiere al menos un campo",
    pleaseSelectAtLeastOneOption: "Seleccione al menos una opción",
    eitherBothOrNone: "Especifique ambos valores o ninguno",
    ipRange: "Utilice un rango de IP válido en notación CIDR (/XX)",
    ipExists: "El rango de IP ya existe",
    overlap: "Rango superpuesto",
    reservedIp:
      "Esta subred está reservada para conexiones VPN y no se puede usar. Elija una subred distinta para su configuración.",
    fileRequired: "El archivo es obligatorio",
    sha256: "Introduzca un hash SHA-256 válido",
    sha256OrCdhash: "Introduzca un hash SHA-256 o CDHash válido",
    hashOrFilePath: "Introduzca un SHA-256 o CDHash o una ruta de archivo válida",
    filePath: "Introduzca una ruta de archivo válida",
    maximumNumberOfItemsExceeded: "Se ha superado el número máximo de elementos",
    port: "Utilice un número de puerto válido",
    tcpOrUdp: "Utilice el protocolo TCP o UDP",
    missingFile: "Añadir un archivo",
    fileSizeError: "Su archivo supera los 10 MB. Inténtelo de nuevo",
    punycodeDomain: "Tiene que ser un dominio válido que utilice solo caracteres ASCII",
    expiredTriggerCannotBeSelected: "No se puede seleccionar un activador vencido",
    accountAddedAsAdminUser:
      "Esta cuenta ya se ha añadido como administrador de canal en otro Workspace. ",
    typeApprove: {
      approve: "Aprobar",
      required: "Tiene que escribir APROBAR para confirmar esta acción",
      exactMatch: "La palabra APROBAR se tiene que escribir tal cual",
    },
  },
  services: {
    [Service.BOX]: "Box",
    [Service.G_SUITE]: "Google Workspace",
    [Service.GOOGLE]: "Google",
    googleDirectoryEnforcementAlias: "G-Suite",
    [Service.DROPBOX]: "Dropbox",
    [Service.OFFICE_365]: "Microsoft 365",
    emailProxyEnforcement: "servidor proxy del correo electrónico",
    [Service.SLACK]: "Slack",
    [Service.SALES_FORCE]: "Salesforce",
    [Service.CORONET]: "Coro",
    hubspotEnforcement: "Hubspot",
    oktaEnforcement: "Okta",
    status: {
      [ServiceStatus.CONNECTED]: "Conectado",
      [ServiceStatus.DISCONNECTED]: "Desconectado",
      [ServiceStatus.ENABLED]: "Conectado",
      disabled: "Deshabilitado",
      [ServiceStatus.INCOMPLETE]: "Incompleto",
      [ServiceStatus.NOT_CONNECTED]: "No conectado",
    },
  },
  snackbar: {
    close: "Cerrar",
    messages: {
      account: {
        mode: {
          visibility: "El modo descubrimiento se ha activado correctamente",
          nonIntrusive: "El modo protección se ha activado correctamente",
          enforcement: "El modo protección se ha activado correctamente",
        },
        modeChangedByRemoteUser: "Uno de los usuarios ha cambiado el modo del sistema",
        settingsSaved: "Configuración de la cuenta guardada.",
      },
      general: {
        actionError:
          "La acción no se ha realizado correctamente; consulte los registros de actividad para más información",
        actionSuccessful: "Acción correcta",
        oops: "¡Vaya! Se ha producido un error",
        copiedToClipboard: "Copiado en el portapapeles",
        settingsUpdated: "Configuración actualizada",
        exportCsvStarted:
          "Exportación a CSV en curso. Podrá acceder en la página de registros de actividad en breve.",
      },
      user: {
        usersUpdated: "Usuarios actualizados",
        usersAreAdding: "Hemos empezado a añadir usuarios; espere...",
        NO_USERS_PROTECTED: "No hay usuarios protegidos",
        usersSyncStarted:
          "Se ha iniciado la sincronización de usuarios desde aplicaciones en la nube",
        PROTECTED_USERS_MASS_REDUCTION: "{requestedLicenses} licencias eliminadas de los usuarios",
        LESS_THEN_8O_PERCENT_USERS_PROTECTED: "Menos del 80 % de los usuarios están protegidos",
        USER_SUSPENDED_BY_ADMIN: "{userEmail} retirado de {service} por {by}",
        USER_SIGNED_OUT_BY_ADMIN: "{by} ha cerrado la sesión de {userEmail} de {service}",
        USER_SUSPENDED_FROM_SERVICE: "{userEmail} retirado de {service} por la regla {by} ",
        MASS_USER_SIGNED_OUT_OF_SERVICE:
          "Se ha cerrado la sesión del usuario {userEmail} de {service} | Se ha cerrado la sesión de los usuarios {userEmails} de {service}",
      },
      users: {
        markAsProcessed: "Se han cerrado los tickets relacionados.",
        signInToAll:
          "Se ha enviado la solicitud para volver a iniciar sesión en todas las aplicaciones en la nube.",
        signInToService:
          "Se ha enviado la solicitud para volver a iniciar sesión en la aplicación en la nube.",
        suspendFromAll:
          "Se ha enviado la solicitud de suspensión a todas las aplicaciones en la nube.",
        contactUser: "El mensaje se ha enviado.",
      },
      userGroups: {
        userGroupsUpdated: "Grupos de usuarios actualizados",
      },
      exceptions: {
        exceptionsUpdated: "Exclusiones actualizadas",
      },
      operator: {
        OPEN_SENSITIVE_FILES_ALLOWED_TO_ADMINS:
          "Los administradores tienen permisos para descargar archivos confidenciales por {by}",
        VIEWER_USER_ADDED: "Nuevo visor {userEmail} añadido por {by}",
        ADMIN_USER_ADDED: "Nuevo administrador {userEmail} añadido por {by}",
      },
      service: {
        SERVICE_DISCONNECTED: "{service} se ha desconectado por {by}",
        SERVICE_NOT_MONITORED: "{service} no está supervisado por {by}",
        SERVICE_REMOVED: "{service} se ha eliminado por {by}",
      },
      notification: {
        CONTENT_SCAN_REPORT_READY:
          "El informe de análisis de descubrimiento de contenido está listo",
      },
      event: {
        newCommentIsAdded: "El comentario nuevo se ha añadido correctamente",
        allowProcess: "Se ha permitido el proceso",
        commentUpdated: "Comentario actualizado",
        ABNORMAL_INCREASE_IN_EVENTS: "Aumento anormal de tickets {ruleName} en el último periodo",
        MASS_FILE_COLLABORATION_BLOCKAGE:
          "Bloqueo de colaboración de archivos masivos. Números de archivos bloqueados: {filesNumber}; servicio: {service}",
        eventThreatStatusChanged: "Estado de amenaza del ticket actualizado",
        eventsUpdated: "Tickets actualizados",
        downloadEmlFile: "Se ha hecho una solicitud de descarga para el correo electrónico",
        markAsProcessed: "El ticket se ha cerrado. | {quantity} tickets se han cerrado.",
        markAsUnprocessed: "El ticket se ha reabierto. | {quantity} tickets se han reabierto.",
        treatFileAsSafe: "El archivo se tratará como seguro.",
        excludeFilePathAndCertificateFromScan:
          "El archivo se tratará como seguro (mediante certificado).",
        excludeFilePathAndFileHashFromScan: "El archivo se tratará como seguro (mediante hash).",
        treatFileAsMalicious: "El archivo se tratará como malicioso.",
        excludeFolderFromScan: "Carpeta excluida del análisis de malware.",
        excludeCertificateFromScan: "excludeCertificateFromScan",
        suspendFromAll:
          "Se ha enviado la solicitud de suspensión a todas las aplicaciones en la nube.",
        suspendFromService: "Se ha enviado la solicitud de suspensión a la aplicación en la nube.",
        signInToAll:
          "Se ha enviado la solicitud para volver a iniciar sesión en todas las aplicaciones en la nube.",
        signInToService:
          "Se ha enviado la solicitud para volver a iniciar sesión en la aplicación en la nube.",
        encryptDrive:
          "El proceso de cifrado de la unidad se iniciará en la siguiente comunicación con el dispositivo. | El proceso de cifrado de la unidad se iniciará en la siguiente comunicación con {quantity} dispositivos.",
        remoteScan:
          "El análisis remoto para buscar malware se iniciará en la siguiente comunicación con el dispositivo. | El análisis remoto para buscar malware se iniciará en la próxima comunicación con {quantity} dispositivos.",
        startDlpFullScan:
          "El análisis remoto para buscar datos confidenciales se iniciará en la siguiente comunicación con el dispositivo. | El análisis remoto para buscar datos confidenciales se iniciará en la siguiente comunicación con {quantity} dispositivos.",
        startDlpPartialScan:
          "El análisis remoto para buscar datos confidenciales se iniciará en la siguiente comunicación con el dispositivo. | El análisis remoto para buscar datos confidenciales se iniciará en la siguiente comunicación con {quantity} dispositivos.",
        uploadLogs: "Los registros se recopilarán en la próxima comunicación con el dispositivo.",
        collectQuarantineData:
          "La lista de elementos en cuarentena se recopilará en la próxima comunicación con el dispositivo.",
        stopRemoteScan:
          "El análisis remoto para buscar malware se detendrá en la siguiente comunicación con el dispositivo. | El análisis remoto para buscar malware se detendrá en la siguiente comunicación con {quantity} dispositivos.",
        stopDlpScan:
          "El análisis remoto para buscar datos confidenciales se detendrá en la siguiente comunicación con el dispositivo. | El análisis remoto para buscar datos confidenciales se detendrá en la siguiente comunicación con {quantity} dispositivos.",
        enableFirewall:
          "Cortafuegos habilitado. | El cortafuegos se ha habilitado para {quantity} dispositivos.",
        removeFromProtection:
          "Dispositivo eliminado de la protección. | {quantity} dispositivos se han eliminado de la protección.",
        updateAgentToLatest:
          "El dispositivo se actualizará a la última versión estable de Agent. | {quantity} dispositivos se actualizarán a la última versión estable de Agent.",
        enforceUAC:
          "Notificación de UAC habilitada de nuevo. | La notificación de UAC se ha habilitado de nuevo para {quantity} dispositivos.",
        disableDevMode:
          "Modo desarrollador deshabilitado. | El modo desarrollador se ha deshabilitado para {quantity} dispositivos.",
        removeExposingSharing: "Se ha enviado la solicitud de eliminación.",
        approveFile: "Se ha enviado la solicitud de aprobación del archivo.",
        deleteFile: "Se ha enviado la solicitud de eliminación de archivos.",
        generalApprove: "El correo electrónico se ha aprobado",
        specificApprove: {
          crowdblockedSender:
            "El remitente o el dominio se ha incluido en la lista de bloqueo global por contenido sospechoso.",
          blocklistedSender:
            "El remitente o el dominio se ha eliminado de la lista de bloqueo de contenido sospechoso.",
          missingRequiredAuthentication:
            "El remitente o el dominio se ha eliminado de la lista de bloqueo de errores de autenticación.",
        },
        approveEmailsFromDomain:
          "{item} se ha incluido en la lista de permitidos | Los dominios seleccionados se han incluido en la lista de permitidos",
        discardEmailsFromDomain:
          "{item} se ha incluido en la lista de bloqueados | Los dominios seleccionados se han incluido en la lista de bloqueados",
        approveEmailsFromSender:
          "{item} se ha incluido en la lista de permitidos | Los correos electrónicos seleccionados se han incluido en la lista de permitidos",
        discardEmailsFromSender:
          "{item} se ha incluido en la lista de bloqueados | Los correos electrónicos seleccionados se han incluido en la lista de bloqueados",
        approveEmail: "El correo electrónico se ha permitido",
        discardEmail: "El correo electrónico se ha eliminado",
        contactUser: "El mensaje se ha enviado",
        allowNoEncryption: "El disco duro se ha incluido en la lista de permitidos",
        approveProcessGroup: "El grupo de procesos se tratará como seguro",
        discardInfectingFile: "El archivo se tratará como malicioso",
        enableTamperResistance:
          "Protección contra manipulaciones habilitada. | La protección contra manipulaciones se ha habilitado para {quantity} dispositivos.",
        disableTamperResistance:
          "Protección contra manipulaciones deshabilitada. | La resistencia a la manipulación se ha deshabilitado para {quantity} dispositivos.",
        logForAuditReports: "El ticket se ha registrado.",
        unLogForAuditReports: "El registro del ticket se ha anulado.",
        disableNetworkBlockMode:
          "El aislamiento de la red se deshabilitará en la próxima comunicación con el dispositivo | El aislamiento de la red se deshabilitará en la próxima comunicación con los dispositivos",
        enableNetworkBlockMode:
          "El aislamiento de la red se habilitará en la próxima comunicación con el dispositivo | El aislamiento de la red se habilitará en la próxima comunicación con los dispositivos",
        shutdownDevice:
          "El apagado se realizará en la próxima comunicación con el dispositivo | El apagado se realizará en la próxima comunicación con los dispositivos",
        rebootDevice:
          "El reinicio se realizará en la próxima comunicación con el dispositivo | El reinicio se realizará en la próxima comunicación con los dispositivos",
        addLabel:
          "Se ha añadido la etiqueta al dispositivo | Se ha añadido la etiqueta a los dispositivos",
        removeLabel:
          "Se ha eliminado la etiqueta del dispositivo | Se ha eliminado la etiqueta de los dispositivos",
        addToDataGovernancePermissions:
          "Se ha autorizado al usuario {users} a acceder a {violationDirection} datos que contienen {trigger}.  | Se ha autorizado a los usuarios {users} a acceder a {violationDirection} datos que contienen {trigger}. ",
      },
      export: {
        exportCsvSuccessful:
          "El CSV se ha exportado correctamente. Vaya a la sección de informes para descargar el CSV.",
      },
      rule: {
        rulesUpdated: "Regla actualizada | Reglas actualizadas",
      },
      devices: {
        devicesUpdated: "Dispositivos actualizados",
        markAsProcessed: "Se han cerrado los tickets relacionados.",
        downloadLogs: "Descargando los registros; espere...",
        mergeSuccessful: "Los dispositivos se han agrupado correctamente.",
      },
      cloudApps: {
        disconnected: "Desconectado de {service}",
        connected: "{service} está conectado a Coro ",
      },
      accessPermissions: {
        accessPermissionsUpdated: "Permisos de acceso actualizados",
      },
      adminAccounts: {
        adminUsersUpdated: "Administradores actualizados",
        inviteSent: "1 administrador invitado",
        permissionsUpdated: "Permisos de contenido actualizados",
        mfaDeleted: "Datos de 2FA eliminados",
        mfaUpdated: "Configuración de 2FA actualizada",
        copyInviteLink: "Enlace de invitación copiado en el portapapeles",
      },
      resetPassword: {
        linkSent: "Se han enviado las instrucciones para restablecer la contraseña a {email}",
      },
      emails: {
        approveEmailsFromDomain:
          "{item} se ha incluido en la lista de permitidos | Los dominios seleccionados se han incluido en la lista de permitidos",
        discardEmailsFromDomain:
          "{item} se ha incluido en la lista de bloqueados | Los dominios seleccionados se han incluido en la lista de bloqueados",
        approveEmailsFromSender:
          "{item} se ha incluido en la lista de permitidos | Los correos electrónicos seleccionados se han incluido en la lista de permitidos",
        discardEmailsFromSender:
          "{item} se ha incluido en la lista de bloqueados | Los correos electrónicos seleccionados se han incluido en la lista de bloqueados",
        approveEmail: "El correo electrónico se ha permitido",
        discardEmail: "El correo electrónico se ha incluido en la lista de bloqueados",
        markAsProcessed: "Se han cerrado los tickets relacionados.",
        markAsUnprocessed: "Ticket reabierto.",
        contactUser: "El mensaje se ha enviado",
      },
      myAccount: {
        updated: "Perfil actualizado",
      },
      dashboard: {
        vulnerabilityMuted: "{vulnerability} se ha desactivado",
        vulnerabilityUnmuted: "{vulnerability} se ha activado",
      },
      demoMode: {
        dataResetCompleted: "Restablecimiento de datos completado",
      },
      manageWorkspaces: {
        workspaceCreated: "Workspace creado",
        workspaceUpdated: "Workspace actualizado",
        workspaceDeleted: "Workspace eliminado",
        subscriptionUpdated: "Suscripción actualizada",
        workspaceArchived: "Workspace archivado",
        exportToCsv:
          "Exportación a CSV en curso. Estará disponible en la página de registros de actividad más tarde.",
      },
      edr: {
        blockProcess: "El proceso se ha bloqueado | {quantity} procesos han sido bloqueado",
        unblockProcess: "El proceso se ha desbloqueado | {quantity} procesos han sido desbloqueado",
        enableNetworkBlockMode:
          "El aislamiento de la red se habilitará en la próxima comunicación con el dispositivo | El aislamiento de la red se habilitará en la próxima comunicación con los dispositivos",
        disableNetworkBlockMode:
          "El aislamiento de la red se deshabilitará en la próxima comunicación con el dispositivo | El aislamiento de la red se deshabilitará en la próxima comunicación con los dispositivos",
        shutdownDevice:
          "El apagado se realizará en la próxima comunicación con el dispositivo | El apagado se realizará en la próxima comunicación con los dispositivos",
        rebootDevice:
          "El reinicio se realizará en la próxima comunicación con el dispositivo | El reinicio se realizará en la próxima comunicación con los dispositivos",
      },
      connectors: {
        syncCompleted: "Sincronización completada",
        apiKeyAdded: "Se han creado las credenciales de API",
        apiKeyRemoved: "Se han anulado las credenciales de API",
      },
      emailProxy: {
        proxyDomainTestCompleted:
          "Se ha completado la verificación del servidor proxy del correo electrónico.",
      },
      secureWebGateway: {
        importUrlGroupToAllowlist:
          "La categoría {nickname} con {quantity} entrada se ha añadido a la lista de permitidos. | La categoría {nickname} con {quantity} entradas se ha añadido a la lista de permitidos.",
        importUrlGroupToBlocklist:
          "La categoría {nickname} con {quantity} entrada se ha añadido a la lista de bloqueados. | La categoría {nickname} con {quantity} entradas se ha añadido a la lista de bloqueados.",
        addUrlToAllowlist:
          "{quantity} entrada se ha añadido a la lista de permitidos. | {quantity} entradas se ha añadido a la lista de permitidos.",
        addUrlToBlocklist:
          "{quantity} entrada se ha añadido a la lista de bloqueados. | {quantity} entradas se ha añadido a la lista de bloqueados.",
      },
      sideToSideTunnels: {
        syncCompleted: "Sincronización completada",
        listUpdated: "Túneles de sitio a sitio actualizados",
      },
      voFirewall: {
        listUpdated: "Lista de cortafuegos actualizada",
      },
      roles: {
        [RolesAction.ADD]: "{name} se ha creado correctamente",
        [RolesAction.EDIT]: "{name} se ha actualizado correctamente",
        [RolesAction.DELETE]: "{name} se ha eliminado correctamente",
      },
      exports: {
        exportCsvStarted: "Exportación a CSV en curso. En breve estará disponible para descargar.",
      },
      socket: {
        suspendFromAll: {
          ok: "Se ha suspendido el acceso del usuario a todas las aplicaciones en la nube.",
          failed:
            "Error: retirar de todas las aplicaciones en la nube. Consulte los registros de actividad para más información.",
        },
        csvExport: {
          ok: "Se ha exportado a CSV correctamente. Podrá acceder en la página «Registros de actividad».",
          failed:
            "No se ha podido exportar a CSV. Consulte los registros de actividad para más información.",
        },
        suspendFromService: {
          ok: "El acceso del usuario a {service} se ha suspendido.",
          failed: "Error: la suspensión del usuario de {service} se ha denegado por el servicio.",
        },
        signInToAll: {
          ok: "La solicitud para volver a iniciar sesión en todas las aplicaciones en la nube se ha enviado correctamente.",
          failed:
            "Error: iniciar sesión en todo. Consulte los registros de actividad para más información.",
        },
        signInToService: {
          ok: "La solicitud para volver a iniciar sesión en la aplicación en la nube se ha enviado correctamente.",
          failed:
            "Error: iniciar sesión en {service}. Consulte los registros de actividad para más información.",
        },
        removeExposingSharing: {
          ok: "Se han eliminado todos los intercambios con personas ajenas a su empresa.",
          failed:
            "Error: eliminar la exposición de recursos compartidos. Consulte los registros de actividad para más información.",
        },
        approveFile: {
          ok: "El archivo {fileName} se ha restaurado.",
          failed:
            "Error: el archivo {fileName} se ha restaurado. Consulte los registros de actividad para más información.",
        },
        deleteFile: {
          ok: "El archivo {fileName} se ha eliminado.",
          failed:
            "Error: el archivo {fileName} no se ha eliminado. Consulte los registros de actividad para más información.",
        },
        importUsersFromCsv: {
          ok: "La lista de direcciones de correo electrónico se ha importado correctamente. {validUsersCount} usuarios del archivo CSV ahora están protegidos.",
          failed:
            "Error en la importación de la lista de direcciones de correo electrónico por el siguiente motivo: {reason}",
        },
        [DeviceAllowListActions.IMPORT_ALLOW_BLOCK_LIST_CSV]: {
          ok: "La lista de permitidos o bloqueados se ha importado correctamente. {validSenderRecordsCount} registros del archivo CSV se han añadido a la lista.",
          failed:
            "Error en la importación de la lista de permitidos o bloqueados por el siguiente motivo: {reason}",
        },
        operatorExportCreated: {
          ok: "El informe CSV está listo.",
          failed: "Se ha producido un error al generar el informe CSV; vuelva a intentarlo.",
        },
      },
    },
  },
  smartSearch: {
    search: "Buscar",
    placeholder: "Buscar uno o varios valores...",
    enterValue: "Introducir valor...",
    moreOptions: "Más opciones de búsqueda",
    lessOptions: "Menos opciones de búsqueda",
    searchBy: "Buscar por:",
    addOperator: "Añadir operador",
    searchByForensic: "Buscar por valores forenses:",
    noRecentSearches: "No tiene ninguna búsqueda reciente",
    searchPrefixes: {
      name: "Entrada principal",
      processHash: "Hash de proceso",
      hostname: "Dispositivo",
      enrollmentCode: "ID del dispositivo de Coro",
      processName: "Proceso",
      user: "Nombre del usuario",
      eventId: "ID del evento",
      currentValue: "Valor actual",
      oldValue: "Valor anterior",
      operationType: "Tipo de operación",
      registryKeyOperationType: "Tipo de operación",
      scheduledTaskOperationType: "Tipo de operación",
      action: "Acción",
      targetAccount: "Cuenta objetivo",
      domain: "Domino de la cuenta",
    },
    clearRecentSearches: "Limpiar búsquedas recientes",
  },
  general: {
    allowed: "Permitido",
    description: "Descripción",
    coroConsole: "Coro console",
    workspacesManagement: "Workspaces Management",
    workspace: "Workspace",
    review: "Revisar",
    notify: "Notificar",
    comment: "Comentario",
    or: "O",
    during: "Durante",
    type: "Tipo",
    device: "Dispositivo",
    process: "Proceso",
    enrollmentCode: "Código de inscripción",
    added: "Añadido",
    noActionsAvailable: "No hay acciones disponibles",
    itemsPerPage: "Elementos por página",
    sortBy: "Ordenar por",
    aliases: "alias",
    exportCsv: "Exportar CSV",
    cloudApps: "Aplicaciones en la nube",
    backToControlPanel: "‹ Panel de control",
    genericBackTo: "‹ Atrás",
    backToDashboard: "‹ Panel de acciones",
    users: "Usuarios protegidos",
    messages: "Mensajes",
    manageWorkspaces: "Gestionar Workspaces",
    tickets: "Registro de tickets",
    socTicketLog: "Registro de ticket del servicio gestionado",
    controlPanel: "Configuración",
    devices: "Dispositivos",
    backOffice: "Oficina administrativa",
    cancel: "Cancelar",
    apply: "Aplicar",
    admin: "administrador",
    yes: "Sí",
    no: "No",
    applyFilters: "Aplicar filtros",
    clearFilters: "Borrar filtros",
    create: "Crear",
    reset: "Restablecer",
    back: "Atrás",
    save: "Guardar",
    close: "Cerrar",
    imDone: "He terminado",
    done: "Hecho",
    login: "Iniciar sesión",
    copied: "Copiado",
    finish: "Finalizar",
    confirm: "Confirmar",
    gotIt: "Entendido",
    ok: "Aceptar",
    search: "Buscar...",
    clearAllFilters: "Borrar todos los filtros",
    clearAll: "Borrar todo",
    disconnected: "Desconectado",
    connected: "Conectado",
    incomplete: "Incompleto",
    notConnected: "No conectado",
    enabled: "Habilitado",
    disabled: "Deshabilitado",
    bulkActions: "Acciones masivas",
    copyToClipboard: "Copiar en el portapapeles",
    copy: "Copiar",
    viewEvents: "Ver tickets",
    viewAll: "Ver todo",
    view: "Ver",
    viewUsers: "Ver usuarios",
    service: "Servicio",
    needHelp: "Necesito ayuda con esto",
    pleaseCorrectErrors: "Corrija los errores",
    home: "Panel de acciones",
    events: "Tickets",
    ticketLog: "Registro de ticket",
    ticketDetails: "Datos del ticket",
    goToDashboard: "Ir a «Mi panel de control»",
    desktopNotSupported:
      "Nuestro sistema está diseñado para ejecutarlo en un ordenador de escritorio o portátil y con un navegador abierto en pantalla completa. Asegúrese de estar utilizando su ordenador de escritorio o portátil y maximice la ventana de su navegador.",
    low: "Baja",
    medium: "Media",
    high: "Alta",
    noData: "No hay datos",
    download: "Descargar",
    learnMore: "Más información",
    poweredBy: "Presentado por",
    outlook: "Outlook 365",
    google: "Google",
    gmail: "Gmail",
    connect: "Conectar",
    enable: "Habilitar",
    offline: "Desconectado",
    mute: "Desactivar",
    unmute: "Activar",
    resolve: "Resolver",
    actions: "Acciones",
    detectionLog: "Registro de detección",
    activityLog: "Registro de actividad",
    allActivity: "Toda la actividad",
    openTickets: "Tickets abiertos",
    lastLoggedInUsers: "Usuarios que han iniciado sesión recientemente",
    allOpenTickets: "Todos los tickets abiertos",
    noOpenTickets: "No hay tickets abiertos",
    noRecentLogins: "No hay inicios de sesión recientes",
    noActivity: "No hay ninguna actividad registrada",
    protectedSince: "Protegido desde",
    processed: "Cerrado",
    processedOn: "Cerrado el",
    unresolvedTickets: "Tickets sin resolver",
    noTickets: "No hay tickets",
    select: "Seleccionar",
    selected: "Seleccionado",
    all: "Todo",
    open: "Abrir",
    revoke: "Anular",
    notAvailable: "N/A",
    addon: "Complemento",
    step: "Paso",
    osVersion: {
      android: "Android",
      ios: "iOS",
      windows: "Windows",
      macOs: "macOS",
      osx: "macOS",
    },
    refresh: "Actualizar",
    at: "en",
    until: "hasta",
    everyXDays: "Todos los días | Cada {n} días",
    none: "Nada",
    and: "y",
    on: "el",
    of: "de",
    group: "grupo | grupos",
    recaptcha:
      'Esta página está protegida por reCAPTCHA y sujeta a la <a class=\'coro-link\' data-testid="login-privacy-policy" href="https://www.google.com/policies/privacy/" target="_blank"> política de privacidad </a>y<a class=\'coro-link\' data-testid="login-terms-of-service" href="https://www.google.com/policies/terms/" target="_blank">\na los términos del servicio</a> de Google',
    add: "Añadir",
    tooltips: {
      usersSaySafe: "Usuarios marcados como seguros",
      usersSayPhishing: "Usuarios marcados como phishing",
      syncUsers: "Sincronizar usuarios de las aplicaciones en la nube ahora",
    },
    by: "por",
    seeNMore: "Ver {n} más",
    seeMore: "Ver más",
    resolvedLastNinetyDays: "Resuelto en los últimos 90 días",
    nUsers: "{n} usuario | {n} usuarios",
    edr: "Endpoint Detection & Response",
    seeLess: "Ver menos",
    drives: "Unidades",
    name: "Nombre",
    status: "Estado",
    key: "Clave",
    blocked: "Bloqueado",
    nonBlocked: "No bloqueado",
    closeUponConfirmation: "Cerrar este y los tickets relacionados al confirmar",
    closeOneTicketUponConfirmation: "Cerrar este ticket al confirmar",
    showAll: "Mostrar todo",
    emails: "Correos electrónicos",
    email: "Correo electrónico",
    remove: "Eliminar",
    edit: "Editar",
    hours: "{n} hora | {n} horas",
    minutes: "{n} minuto | {n} minutos",
    test: "Prueba",
    format: "Formato",
    success: "Acción correcta",
    tryAgain: "Inténtelo de nuevo",
    subscriptionExpired: "Su suscripción ha vencido.",
    noPermissions: "No tiene permisos para realizar esta acción.",
    optional: "opcional",
    next: "Siguiente",
    filters: "Filtros",
    accessToSensitiveDataRestricted: "El acceso a los datos confidenciales está restringido",
    localeDisplayNames: {
      [WorkspaceLocale.EN_US]: "English (US)",
      [WorkspaceLocale.EN_GB]: "English (UK)",
      [WorkspaceLocale.ES]: "Español (ES)",
    },
    viewByList: "Ver por lista",
    views: "Vistas",
    loadingItems: "Cargando elementos...",
    noDataAvailable: "No hay datos disponibles",
    days: "{n} day | {n} days",
  },
  appBar: {
    userContextMenu: {
      logout: "cerrar sesión",
      myAccount: "Mi cuenta",
      contactSupport: "Contactar con el equipo de asistencia",
    },
  },
  errors: {
    somethingWentWrong: "Ocurrió un error al obtener “{url}”. Error ${status}",
    invalidVerificationCode: "Código de verificación incorrecto",
    tooManyOtpResetRequests:
      "Demasiadas solicitudes de SMS. La siguiente solicitud estará disponible en 1 minuto.",
    incorrectPSACredentials:
      "Se ha producido un error. Compruebe si sus credenciales son correctas e inténtelo de nuevo",
    incorrectSIEMCredentials: "Ha facilitado credenciales incorrectas para el proveedor SIEM",
    siemProviderNotAvailable:
      "¡Vaya! El proveedor SIEM no está disponible en este momento. Inténtelo de nuevo más tarde.",
    generic: "Se ha producido un error. Inténtelo de nuevo",
    webhookTriggerTestFailed: "Error en la prueba de activadores de webhook",
    webhookTestFailed: "Error en la prueba de webhook",
    domainAlreadyExists: "El dominio ya existe",
    emailProxyRelayHostIsNotReachable:
      "Coro no puede comunicarse con su host de retransmisión SMTP. Compruebe la configuración del host y del puerto.",
    emailProxyCoroMxIsNotPrimary:
      "Primary MX for the domain, is not pointing to CORO SMTP proxy server. Please verify DNS settings",
  },
  modals: {
    [TicketAction.CONFIGURE_PROXY]: {
      title: "{proxyDirection} Gateway proxy settings are missing or incorrect",
      description: "Correct your settings through the Control Panel, then return to this ticket.",
      actionBtn: "Configure",
    },
    newOfficeAccountError: {
      title: "Parece que esta cuenta de Microsoft 365 es nueva",
      description:
        "Como Microsoft 365 tarda unas horas en configurar privilegios para las nuevas cuentas, regístrese en Coro un poco más tarde.",
      actionButton: "Entendido",
    },
    [WorkspaceCodeActions.CREATE]: {
      title: "Crear código de Workspace",
      actionBtn: "Crear",
      codeName: "Nombre del código",
      codeType: "Tipo de código",
      priceBookNumber: "Número del libro de precios",
      expirationDate: "Fecha de vencimiento",
      workspaceType: "Tipo de Workspace",
      bundles: "Paquetes",
      commercialTerms: "Condiciones comerciales",
      discount: "Descuento",
      trialDuration: "Duración del periodo de prueba",
      creditCardRequired: "Tarjeta de crédito obligatoria",
      relatedTo: "Relacionado con",
      agentDetails: "Agente",
      salesDetails: "AE (ventas)",
      mspDetails: "MSP",
    },
    [WorkspaceCodeActions.DELETE]: {
      title: "¿Eliminar el código de Workspace?",
      actionBtn: "Sí, eliminar",
      description: "Se eliminará el código de Workspace con toda su configuración",
    },
    [WorkspaceCodeActions.ACTIVATE]: {
      title: "¿Activar el código?",
      actionBtn: "Sí, activar",
      validTill: "Validez hasta",
      description:
        "El código de Workspace estará activo y disponible para nuevos Workspaces. Si lo necesita, puede establecer una fecha máxima de validez. Si no lo necesita, puede dejar el campo vacío.",
    },
    [WorkspaceCodeActions.DEACTIVATE]: {
      title: "¿Desactivar el código de Workspace? ",
      actionBtn: "Sí, desactivar",
      description:
        "El código de Workspace está asociado a los Workspaces y, por lo tanto, no se puede eliminar. Sin embargo, puede desactivar el código para que no esté disponbile para Workspaces nuevos",
    },
    [UserGroupAction.ADD_NEW_GROUP]: {
      title: "Añadir grupos a la protección de Coro",
      actionBtn: "Añadir grupos",
      description:
        "Puede añadir usuarios a la protección en grupos utilizando los grupos definidos en su directorio de usuarios",
      placeholder: "Introduzca el nombre del grupo o de los grupos",
      noData: "No se ha encontrado ningún grupo que coincida",
      label: "Nombre del grupo",
    },
    [UserGroupAction.REMOVE_GROUPS]: {
      title: "¿Eliminar {n} grupo? | ¿Eliminar {n} grupos?",
      description: "A estos grupos ya no se les aplicarán los servicios de protección de Coro.",
      actionBtn: "Sí, eliminar",
    },
    [UserAction.IMPORT_USERS_FROM_CSV]: {
      title: "",
      actionBtn: "Añadir usuarios",
      disclaimer:
        "La primera columna del archivo CSV tiene que incluir las direcciones de correo electrónico válidas de todos los usuarios que se añadirán a la protección de Coro. Se ignorarán las columnas adicionales y las entradas que no sean válidas.",
      parsingCsv: "Analizando CSV; espere",
      uploadTxt: "Suba un archivo CSV",
      subtitle: "o arrastre y suelte el archivo aquí",
      downloadTemplate: "Descargue nuestra plantilla para evitar entradas no válidas.",
      errors: {
        general: "Se ha producido un error al importar el archivo CSV. Inténtelo de nuevo.",
      },
      importInProgress:
        "La importación ya está en curso. Consulte el registro de actividad para confirmar el resultado.",
    },
    [UserAction.ADD_ALL_USERS_CONFIRMATION]: {
      title: "¿Añadir protección para todos los usuarios de {service}?",
      description:
        "Todos los usuarios actuales de {service} se añadirán a la protección tras una sincronización con {service}. En el futuro, los nuevos usuarios de {service} se añadirán de forma automática a la protección de Coro.",
      actionBtn: "Añadir protección",
    },
    [UserAction.ADD_USERS]: {
      title: "Añadir usuarios a la protección de Coro",
      actionBtn: "Añadir usuarios",
      label: "Correo electrónico",
      description: "Tiene 19 puestos disponibles en su plan.",
      placeholder: "Introduzca el o los correos electrónicos",
    },
    [UserAction.ADD_ALL_USERS]: {
      title: "Añadir protección para todos los usuarios.",
      description:
        "Seleccione la aplicación en la nube para la que quiere aplicar protección para todos los usuarios",
      noAppsProtected: "Actualmente no tiene ninguna aplicación en la nube protegida",
      noAppsProtectedDescription:
        "Conecte al menos un servicio en la nube para iniciar la protección",
      connectServicesBtn: "Conectar",
      actionBtn: "Añadir protección",
    },
    [ExceptionsAction.ADD_NEW_EXCEPTION]: {
      title: "Añadir usuarios individuales a la lista de exclusión",
      description: "Los usuarios añadidos a la lista de exclusión quedarán exentos de protección",
      actionBtn: "Añadir usuarios",
    },
    [UserAction.REMOVE_USERS]: {
      title: "Eliminar usuario",
      description: "Los servicios de protección de Coro ya no se aplicarán a <b>{email}</b>.",
      checkbox: "Añadir a la exclusión",
      actionBtn: "Sí, eliminar",
    },
    [UserAction.ADD_TO_EXCEPTION]: {
      title: "Añadir usuario individual a la lista de exclusión",
      description: "Los usuarios añadidos a la lista de exclusión quedarán exentos de protección",
      actionBtn: "Añadir usuarios",
    },
    [UserAction.REMOVE_EXCEPTION]: {
      title: "Eliminar exclusión",
      actionBtn: "Sí",
      description: "Los servicios de protección Coro se aplicarán de nuevo a este usuario",
    },
    [TicketAction.SUSPEND_FROM_ALL]: {
      title:
        "¿Retirar al usuario de todas las aplicaciones protegidas? | ¿Retirar a los usuarios de todas las aplicaciones protegidas?",
      description:
        "Se le retirará el acceso del usuario de forma temporal a sus cuentas en todas las aplicaciones en la nube protegidas por Coro. | Se le retirará el acceso de los usuarios de forma temporal a sus cuentas en todas las aplicaciones en la nube protegidas por Coro.",
      actionBtn: "Retirar",
    },
    [TicketAction.SUSPEND_FROM_SERVICE]: {
      title: "¿Retirar al usuario de {service}?",
      description:
        "Se le retirará el acceso del usuario de forma temporal a su cuenta en {service}.",
      actionBtn: "Retirar",
    },
    [TicketAction.SIGN_IN_TO_ALL]: {
      title:
        "¿Solicitar al usuario que inicie sesión en todas las aplicaciones protegidas? | ¿Solicitar a los usuarios que inicien sesión en todas las aplicaciones protegidas?",
      description:
        "Se solicitará al usuario que vuelva a iniciar sesión en todas las aplicaciones en la nube protegidas por Coro que esté utilizando. | Se solicitará a los usuarios que vuelvan a iniciar sesión en todas las aplicaciones en la nube protegidas por Coro que estén utilizando.",
      actionBtn: "Solicitar",
    },
    [TicketAction.MARK_AS_PROCESSED]: {
      title: "¿Cerrar los tickets seleccionados?",
      description: "Los tickets cerrados se eliminarán de su panel de acciones.",
      permissionsBanner: "No tiene permiso para cerrar tickets relacionados con:",
      notRestrictedModules:
        "Los tickets cerrados en los siguientes módulos se eliminarán de su panel de acciones:",
      actionBtn: "Cerrar tickets",
    },
    [TicketAction.MARK_AS_UNPROCESSED]: {
      title: "¿Volver a abrir los tickets seleccionados?",
      description: "Los tickets que se hayan vuelto a abrir se añadirán a su panel de acciones.",
      actionBtn: "Volver a abrir tickets",
    },
    [MalwareAction.TOGGLE_INIT_SCAN]: {
      title: "Advertencia",
      description:
        "El análisis de las unidades de terminales es una operación que gasta recursos y, por lo tanto, puede afectar a la experiencia de los usuarios finales.",
      actionBtn: "Continuar",
    },
    [AdminUsersAction.ADD]: {
      title: "Añadir administrador",
      firstName: {
        placeholder: "Nombre",
        label: "Nombre",
      },
      lastName: {
        placeholder: "Apellido",
        label: "Apellido",
      },
      email: {
        placeholder: "Correo electrónico",
        label: "Correo electrónico",
      },
      role: {
        placeholder: "Función",
        label: "Función",
      },
      actionBtn: "Guardar",
      errors: {
        general: "Corrija los errores a continuación",
        restriction: "Seleccione el tipo de permiso",
      },
    },
    [AdminUsersAction.EDIT]: {
      title: "Editar administrador",
      actionBtn: "Guardar",
    },
    addConnectwiseConnector: {
      integrationName: {
        label: "Nombre de la integración",
        placeholder: "Introducir nombre de la integración",
      },
      title: {
        generic: "Añada una conexión a su entorno de PSA",
        add: {
          [PsaProviders.GRADIENT]: "Añadir una conexión de Gradient",
          [PsaProviders.CONNECTWISE]: "Añadir una conexión de ConnectWise",
          [PsaProviders.AUTOTASK]: "Añadir una conexión de AutoTask",
        },
        edit: {
          [PsaProviders.GRADIENT]: "Editar la conexión de Gradient",
          [PsaProviders.CONNECTWISE]: "Editar la conexión de ConnectWise",
          [PsaProviders.AUTOTASK]: "Editar la conexión de AutoTask",
        },
      },
      companyName: {
        label: "Nombre de la empresa",
        placeholder: "Introduzca el nombre de la empresa (el MSP)...",
      },
      domain: {
        label: "Dominio de ConnectWise",
        placeholder: "Introduzca el dominio de ConnectWise...",
      },
      publicKey: {
        label: "Clave pública",
        placeholder: "Introduzca la clave pública...",
      },
      privateKey: {
        label: "Clave privada",
        placeholder: "Introduzca la clave privada...",
      },
      enableTicketing: "Habilitar emisión de tickets",
      enableBilling: "Habilitar datos de facturación",
    },
    [ConnectorActions.ADD_SIEM]: {
      title: "Añadir conexión",
      actionBtn: "Añadir",
      listenerUrlLabel: "URL del receptor",
      listenerUrlHint: "Compatible con HTTP y HTTPS",
      customHeaders: "Encabezados personalizados",
      applyToAll: "Aplicar a todos los clientes",
      header: "Encabezado",
      value: "Valor",
      token: "Token",
      addBtn: "+ Encabezado",
      azureSentinel: {
        workspaceId: "ID del Workspace",
        primaryKey: "Clave principal",
        logType: "Tipo de registro",
      },
    },
    [ConnectorActions.UPDATE_SIEM]: {
      title: "Editar conexión",
      actionBtn: "Guardar",
    },
    [ConnectorActions.DELETE_SIEM]: {
      title: "¿Eliminar la conexión?",
      description:
        "La conexión {name} se deshabilitará y se eliminará de su Workspace. | La conexión {name} se deshabilitará y se eliminará de todos los Workspaces de los clientes.",
      actionBtn: "Sí, eliminar",
    },
    [PhishingAction.ADD_TO_ALLOWLIST]: {
      title: "Añadir a la lista de permitidos",
      label:
        "Introduzca los correos electrónicos, los dominios, las direcciones IP o los rangos de IP",
      addToListLabel:
        "Añada los correos electrónicos, los dominios, las direcciones IP o los rangos de IP",
      placeholder:
        "Introduzca una o más direcciones de correo electrónico, dominios, direcciones IP o rangos de IP",
      actionBtn: "Añadir a la lista",
      authList: "Error de autenticación {listType}",
      suspiciousContentList: "Contenido sospechoso {listType}",
    },
    [PhishingAction.ADD_TO_BLOCKLIST]: {
      title: "Añadir a la lista de bloqueados",
      actionBtn: "Añadir a la lista",
    },
    [PhishingAction.REMOVE_FROM_BLOCKLIST]: {
      title: "¿Eliminar de la lista de bloqueados?",
      description: "{name} ya no estará en la lista de bloqueados.",
      actionBtn: "Sí, eliminar",
    },
    [PhishingAction.REMOVE_FROM_ALLOWLIST]: {
      title: "¿Eliminar de la lista de permitidos?",
      description: "{name} ya no estará en la lista de permitidos.",
      actionBtn: "Sí, eliminar",
    },
    [PhishingAction.DOWNLOAD_ALLOW_BLOCK_LIST_EXAMPLE_CSV]: {
      title: "",
      actionBtn: "Añadir usuarios",
      disclaimer:
        "La primera columna del archivo CSV tiene que incluir las direcciones de correo electrónico válidas, los dominios, las direcciones IP o los rangos de las direcciones IP de todos los usuarios que se añadirán a la protección de Coro. Las columnas adicionales y las entradas no válidas se ignoran.",
      parsingCsv: "Analizando CSV; espere",
      parsingFile: "Analizando el archivo; espere",
      uploadTxt: "Suba un archivo CSV",
      subtitle: "o arrastre y suelte el archivo aquí",
      downloadTemplate: "Descargue nuestra plantilla para evitar entradas no válidas.",
      errors: {
        general: "Se ha producido un error al importar el archivo CSV. Inténtelo de nuevo.",
      },
      importInProgress:
        "La importación ya está en curso. Consulte el registro de actividad para confirmar el resultado.",
    },
    [DataLossAction.ADD_PERMISSION_FOR_ALL_USERS]: {
      title: "Añadir permiso para todos los usuarios",
      actionBtn: "Añadir",
    },
    [DataLossAction.ADD_PERMISSION_FOR_SPECIFIC_GROUPS]: {
      title: "Añadir permiso para grupos específicos",
      placeholder: "Introducir nombres de grupos protegidos",
      label: "Nombre del grupo",
      actionBtn: "Añadir",
    },
    [DataLossAction.ADD_PERMISSION_FOR_SPECIFIC_USERS]: {
      title: "Aplicar la política a estos usuarios o dominios",
      placeholder:
        "Introduzca los correos electrónicos de los usuarios o los nombres de los dominios...",
      label: "Introduzca los correos electrónicos de los usuarios o los nombres de los dominios...",
      actionBtn: "Añadir",
    },
    addAutotaskConnector: {
      userName: {
        label: "Nombre de usuario",
        placeholder: "Introduzca su nombre de usuario...",
      },
      secret: {
        label: "Secreto",
        placeholder: "Introduzca el secreto...",
      },
    },
    removePsa: {
      title: "¿Desconectar la conexión?",
      description: "¿Seguro que quiere desconectar la conexión?",
      actionBtn: "Sí, desconectar",
    },
    [AdminUsersAction.DELETE]: {
      title: "¿Eliminar administrador?",
      description:
        "<span class='text--semibold'>{email}</span> ya no tendrá acceso a este Workspace.",
      channelDescription:
        "<span class='text--semibold'>{email}</span> ya no tendrá acceso a este Workspace. Si se añade este administrador como administrador de MSP, los permisos también se eliminarán.",
      actionBtn: "Sí, eliminar",
    },
    [AdminUsersAction.DELETE_MFA]: {
      title: "¿Eliminar datos de 2FA?",
      description:
        "A <span class='text--semibold'>{email}</span> se le solicitará que vuelva a configurar los datos de 2FA.",
      actionBtn: "Sí, eliminar",
    },
    [AdminUsersAction.TOGGLE_WORKSPACE_MFA]: {
      title: "¿No pedir 2FA?",
      description:
        "Si se deshabilita la autenticación de dos pasos, la identidad de los administradores del Workspace se comprobará solo mediante sus contraseñas y hará que el Workspace sea menos seguro.",
      actionBtn: "Sí",
    },
    [AccountAction.TOGGLE_MFA]: {
      title: "¿Deshabilitar 2FA?",
      description:
        "Cuando se deshabilita la autenticación de dos pasos, la identidad de un administrador se comprueba solo mediante contraseñas. Esto hace que el Workspace sea menos seguro.",
      actionBtn: "Sí, deshabilitar",
    },
    removeApiKey: {
      title: "¿Anular las credenciales de API?",
      description:
        "Estas credenciales de API dejarán de ser válidas. Esta acción no se puede deshacer.",
      actionBtn: "Anular",
    },
    addApiKey: {
      title: "Crear credenciales de API",
      description: "Descripción",
      expirationDate: "Fecha de vencimiento",
      makeSureYouSaveIt: "Asegúrese de guardarla porque no podrá volver a acceder a ella.",
      yourNewToken: "Se ha generado su nuevo token de acceso personal",
      actionBtn: "Crear",
      clientId: "ID del cliente",
      clientSecret: "Secreto del cliente",
    },
    copyInviteLink: {
      title: "Copiar enlace de invitación",
      description: "Los destinatarios utilizarán este enlace para iniciar sesión en Coro.",
      actionBtn: "Copiar",
    },
    [DevicePostureAction.ADD]: {
      title: "Add new device policy to {osType}",
      macOsDisclaimer:
        "Los dispositivos con macOS 15 solo admiten la acción de revisión y la aplicación de políticas no está disponible.",
      selectPolicyType: "Seleccionar el tipo de póliza",
      action: "Acción",
      postureActions: {
        enforce: "Imponer",
        review: "Revisar",
      },
      applyPolicyToDevices: "Aplicar la política a los dispositivos con estas etiquetas",
      labels: "Etiquetas",
      actionBtn: "Guardar",
      wifiSettings: {
        connection: "Conexión",
        connectionTypes: {
          ALL_ENCRYPTED: "Deny connection to all unencrypted networks",
          SPECIFIC: "Permitir la conexión a redes específicas",
        },
        specificConnectionDescription:
          "Restrict Wi-Fi connection to this list of trusted networks. Separate multiple entries with the Enter or Tab key.",
        networksPlaceholder: "Introduzca las redes específicas...",
      },
      usbLockdown: {
        blockPortableDevices: "Bloquear dispositivos portátiles",
        blockMassStorage: "Bloquear dispositivos de almacenamiento masivo",
        atLeastOneRequired: "Hace falta al menos una póliza",
        policyIsRequired: "La política es obligatoria",
      },
      dlpScanSchedule: {
        days: "{n} día | {n} días",
        every: "Cada",
        preferredTime: "Hora preferida",
        applyTo: "Aplicar a",
        driveTypes: {
          ALL: "Todas las unidades",
          UNENCRYPTED: "Unidades sin cifrar",
        },
      },
      userAccountPolicy: {
        password: "Contraseña",
        screenLockout: "Bloqueo de pantalla",
        minimumLength: "Longitud mínima",
        maximumAge: "Antigüedad máxima (días)",
        minimumAge: "Antigüedad mínima (días)",
        enforcePasswordHistory: "Imponer el historial de contraseñas",
        passwordComplexity: "La contraseña debe cumplir con la dificultad",
        passwordComplexityDescription:
          "Enable this setting to allow Windows and macOS to enforce password complexity requirements set by your organization.",
        lockoutDuration: "Duración del bloqueo",
        lockoutThreshold: "Límite de bloqueo (intentos)",
      },
    },
    [DevicePostureAction.EDIT]: {
      title: "Editar la política de dispositivos {osType}",
      actionBtn: "Guardar",
    },
    [DevicePostureAction.REMOVE]: {
      title: "¿Eliminar la política?",
      description:
        "La política de posición de dispositivos eliminados ya no se aplicará a los dispositivos respectivos.",
      actionBtn: "Confirmar",
    },
    [SocContactsActions.EDIT]: {
      title: "Editar contacto del servicio gestionado",
      email: "Correo electrónico",
      firstName: "Nombre",
      lastName: "Apellido",
      notificationSettings: {
        title: "Configuración de notificaciones",
        [SocContactNotificationTypes.EMAIL]: "Correo electrónico",
        [SocContactNotificationTypes.DO_NOT_NOTIFY]: "No notificar",
      },
      closureSettings: {
        title:
          "Recibir informes sobre tickets gestionados por nuestro equipo de servicios gestionados por correo electrónico",
        [SocContactClosureNotification.WEEKLY]: "Resumen semanal",
        [SocContactClosureNotification.PER_TICKET]: "Por ticket",
        [SocContactClosureNotification.DO_NOT_NOTIFY]: "No notificar",
      },
      actionBtn: "Guardar",
    },
    [SocContactsActions.CREATE]: {
      title: "Crear contacto del servicio gestionado",
      email: "Correo electrónico",
      firstName: "Nombre",
      lastName: "Apellido",
      notificationSettings: {
        title: "Configuración de notificaciones",
        [SocContactNotificationTypes.EMAIL]: "Correo electrónico",
        [SocContactNotificationTypes.DO_NOT_NOTIFY]: "No notificar",
      },
      actionBtn: "Guardar",
    },
    [SocContactsActions.REMOVE]: {
      title: "Eliminar contacto del servicio gestionado",
      description: "¿Desea eliminar el contacto del servicio gestionado?",
      actionBtn: "Sí, eliminar",
    },
    [ServiceAction.CONNECT]: {
      title: "Conectarse a {service}",
      noServiceTitle: "Conectar aplicaciones en la nube",
      description: "¿Seguro que quiere eliminar '{service}'?",
      actionBtn: "Ok",
    },
    [ServiceAction.DISCONNECT]: {
      title: "¿Quiere desconectar {service} de la protección de Coro?",
      description: "Su servicio de {service} y los datos ya no se supervisarán ni se protegerán.",
      actionBtn: "Sí, desconectar",
    },
    [ServiceAction.GRANT_PRIVILEGES]: {
      title: "Actualizar permisos de Google Workspace",
      actionBtn: "Aceptar",
    },
    [ServiceAction.CONNECTION_STATUS]: {
      title: "Estado de conexión de {service}",
      lastHeartbeat: "Último latido",
    },
    [SensitiveDataScansActions.CREATE]: {
      title: "Añadir nuevo programa de análisis",
      selectOs: "Seleccionar sistema operativo",
      actionBtn: "Guardar",
      every: "Cada",
      preferredTime: "Hora preferida",
      applyTo: "Aplicar a",
      applyToLabels: "Aplicar la política a los dispositivos con estas etiquetas",
      labelsPlaceholder: "Seleccionar etiquetas...",
      labelsLabel: "Etiquetas",
      driveTypes: {
        ALL: "Todo",
        UNENCRYPTED: "Sin cifrar",
      },
    },
    [SensitiveDataScansActions.EDIT]: {
      title: "Editar programa de análisis",
      selectOs: "Seleccionar sistema operativo",
      actionBtn: "Guardar",
      every: "Cada",
      preferredTime: "Hora preferida",
      applyTo: "Aplicar a",
      applyToLabels: "Aplicar la política a los dispositivos con estas etiquetas",
      labelsPlaceholder: "Seleccionar etiquetas...",
      labelsLabel: "Etiquetas",
      driveTypes: {
        ALL: "Todo",
        UNENCRYPTED: "Sin cifrar",
      },
    },
    [SensitiveDataScansActions.DELETE]: {
      title: "¿Eliminar el programa de análisis?",
      description:
        "La política de programación de análisis eliminada ya no se aplicará a los dispositivos respectivos.",
      actionBtn: "Eliminar",
    },
    [WorkspaceAction.SELECT_WORKPLACE]: {
      title: "Seleccione el Workspace que quiere ver",
      actionBtn: "Iniciar",
    },
    [WorkspaceAction.LAUNCH_WITH_2FA]: {
      title: "¿Configurar 2FA?",
      description:
        "El Workspace <b>{workspaceId}</b> necesita la configuración de la autenticación de dos pasos.",
      actionBtn: "Sí, configurar",
    },
    [SocRemediationActions.CHANGE_REMEDIATION_TYPE]: {
      title: "Confirme su selección",
      description:
        "<b>Tiene que leer esto</b><br><br>Al hacer clic en confirmar, reconozco que doy permiso para realizar acciones en remoto en el Workspace de Coro y para acceder al correo electrónico, la nube o el terminal de los usuarios finales, que se hace con mi total conocimiento de las posibles consecuencias. Entiendo que Coro no se hará responsable de ninguna pérdida comercial, interrupción del trabajo u otros problemas resultantes, ya que estas acciones se llevan a cabo por a mi solicitud y con mi consentimiento expreso.<br>",
      actionBtn: "Confirmar",
    },
    [DeviceAllowListActions.ADD_PROCESS_RECORD]: {
      title: "Añadir nuevo registro de proceso",
      descriptionTitle: "Añadir descripción",
      namePlaceholder: "Introduzca el texto...",
      descriptionPlaceholder: "Introduzca el texto...",
      descriptionLabel: "Descripción",
      list: "Lista",
      listDescription: "Los procesos se añaden de forma automática a la lista de bloqueados.",
      allow: "Permitir",
      block: "Bloquear",
      nameLabel: "Hash",
      nameDescription: "Añadir hash",
      actionBtn: "Guardar",
    },
    [DeviceAllowListActions.ADD_FOLDER_RECORD]: {
      title: "Añadir nuevo registro de carpeta",
      descriptionTitle: "Añadir descripción",
      namePlaceholder: "Introduzca el texto...",
      descriptionLabel: "Descripción",
      descriptionPlaceholder: "Introduzca el texto...",
      list: "Lista",
      listDescription: "Las carpetas se añaden de forma automática a la lista de permitidos.",
      allow: "Permitir",
      block: "Bloquear",
      nameLabel: "Carpeta",
      nameDescription: "Añadir carpeta",
      nameDescription2:
        "Se usan variables de entorno para aplicar valores en varias entradas. Más información en nuestro artículo <a class='coro-link' href='https://docs.coro.net/endpointsecurity/allow-block/' target=\"_blank\"></a>.",
      actionBtn: "Guardar",
    },
    [DeviceAllowListActions.ADD_FILE_RECORD]: {
      title: "Añadir nuevo registro de archivo",
      descriptionTitle: "Añadir descripción",
      namePlaceholder: "Introduzca el texto...",
      descriptionLabel: "Descripción",
      descriptionPlaceholder: "Introduzca el texto...",
      list: "Lista",
      listDescription: "Los archivos se añaden de forma automática a la lista de permitidos.",
      allow: "Permitir",
      block: "Bloquear",
      nameLabel: "Ruta",
      nameDescription: "Añadir ruta del archivo",
      nameDescription2:
        "Se usan variables de entorno para aplicar valores en varias entradas. Más información en nuestro artículo <a class='coro-link' href='https://docs.coro.net/endpointsecurity/allow-block/' target=\"_blank\"></a>",
      actionBtn: "Guardar",
    },
    [DeviceAllowListActions.EDR_ADD_TO_ALLOWLIST]: {
      title: "Añadir a la lista de permitidos",
      infoText:
        "Los procesos especificados por la ubicación del hash o del archivo de imagen a continuación se marcarán como seguros y no generarán tickets de EDR.<br>No se recopilará información del proceso relacionado en las ventanas de telemetría y procesos.",
      namePlaceholder: "Introducir hash o ruta de la carpeta...",
      nameLabel: "Hash o ruta",
      nameDescription: "Añadir hash del proceso o ruta de la carpeta",
      descriptionTitle: "Añadir descripción",
      descriptionLabel: "Descripción",
      descriptionPlaceholder: "Introduzca la descripción...",
      actionBtn: "Guardar",
    },
    [DeviceAllowListActions.EDR_ADD_TO_BLOCKLIST]: {
      title: "Añadir a la lista de bloqueados",
      infoText: "Evita que los procesos bloqueados se ejecuten en toda la empresa.",
      namePlaceholder: "Introduzca el hash...",
      nameLabel: "Hash",
      nameDescription: "Añadir hash del proceso",
      descriptionTitle: "Añadir descripción",
      descriptionLabel: "Descripción",
      descriptionPlaceholder: "Introduzca la descripción...",
      actionBtn: "Guardar",
    },
    [DeviceAllowListActions.REMOVE_RECORD]: {
      title: "¿Eliminar el registro?",
      descriptionBlocked: "El registro ya no estará en la lista de bloqueados",
      descriptionAllowed: "El registro ya no estará en la lista de permitidos",
      actionBtn: "Sí, eliminar",
    },
    [DeviceAllowListActions.IMPORT_ALLOW_BLOCK_LIST_CSV]: {
      title: "Importar CSV a la lista de permitidos o bloqueados",
      actionBtn: "Importar",
      disclaimer:
        "Las entradas deben incluir valores válidos en las 4 columnas. Se ignorarán las entradas con columnas adicionales o con valores no válidos.",
      parsingFile: "Analizando CSV; espere",
      downloadTemplate: "Descargue nuestra plantilla para evitar entradas no válidas.",
      errors: {
        general: "Se ha producido un error al importar el archivo CSV. Inténtelo de nuevo.",
      },
      importInProgress:
        "La importación ya está en curso. Consulte el registro de actividad para confirmar el resultado.",
      uploadTxt: "<span class='coro-link'>Haga clic para subir</span> o arrastre y suelte",
      subtitle: "CSV (máximo {size})",
      successfullyUploaded: "{n} registros subidos correctamente",
      invalidRecords: "{n} de {sum} registros no son válidos",
      notAllRecordsUploaded: "Algunos registros no se han subido correctamente",
    },
    importUrlGroupAllowBlockListFromCsv: {
      allowListTitle: "Importar grupo de URL a la lista de permitidos",
      blockListTitle: "Importar grupo de URL a la lista de bloqueados",
      actionButton: "Importar",
      disclaimer:
        "Las entradas deben contener valores válidos en una columna. Se ignorarán las entradas con columnas adicionales o con valores no válidos.",
      parsingFile: "Analizando el archivo; espere",
      uploadTxt: "Suba un archivo TXT",
      uploadTextSubtitle: "o arrastre y suelte el archivo aquí",
      downloadTemplate: "Descargue nuestra plantilla para evitar entradas no válidas.",
      errors: {
        general: "Se ha producido un error al importar el archivo. Inténtelo de nuevo.",
      },
      importInProgress: "Entradas añadidas correctamente",
    },
    [RolesAction.ADD]: {
      title: "Añadir función",
      role: "Función",
      roleName: "Nombre de la función",
      workspacePermissions: "Permisos del Workspace",
      actionBtn: "Guardar",
    },
    [RolesAction.EDIT]: {
      title: "Editar función",
      actionBtn: "Guardar",
    },
    [RolesAction.DUPLICATE]: {
      title: "Función duplicada",
      actionBtn: "Guardar",
    },
    [RolesAction.DELETE]: {
      title: "¿Eliminar la función?",
      description: "Se eliminará la función <strong>{name}</strong>",
      actionBtn: "Sí, eliminar",
    },
    [RolesAction.UNABLE_TO_DELETE]: {
      title: "No se puede eliminar la función",
      description:
        "Solo se pueden eliminar funciones que no estén asociadas con administradores. Elimine esta función de los administradores e inténtelo de nuevo.",
      actionBtn: "Sí, eliminar",
    },
    [MspRolesAction.ADD]: {
      title: "Añadir función de MSP",
      role: "Función",
      roleName: "Nombre de la función",
      childWorkspacePermissions: "Permisos del Workspace descendiente",
      workspace: {
        placeholder: "Seleccionar Workspace",
        label: "Workspace",
        hint: "Seleccione el Workspace de canal al que está añadiendo la función. Esta función define los permisos para este Workspace y sus descendientes.",
      },
      tooltips: {
        [MspRolePermissionScopes.MSP_PORTAL_SCOPE]:
          "Estos permisos aplican a los elementos y a las acciones del portal de MSP.",
        [RolePermissionsScope.WORKSPACE_MANAGEMENT]:
          "Estas opciones determinan los permisos de los administradores del MSP para la sección de Workspace del panel de control en todos los Workspaces descendientes.",
        [RolePermissionsScope.VIEWS]:
          "Estas opciones determinan los permisos de los administradores del MSP para las vistas de usuarios y dispositivos en todos los Workspaces descendientes.",
        [RolePermissionsScope.PROTECTION]:
          "Estas opciones determinan los permisos de los administradores del MSP para la sección de protección del panel de control en todos los Workspaces descendientes.",
        [RolePermissionsScope.TICKETS]:
          "Estas opciones determinan los permisos de los administradores de MSP para los tickets del panel de acciones en todos los Workspaces descendientes.",
      },
      actionBtn: "Guardar",
    },
    [MspRolesAction.EDIT]: {
      title: "Editar función",
      actionBtn: "Guardar",
    },
    [MspRolesAction.DUPLICATE]: {
      title: "Función duplicada",
      actionBtn: "Guardar",
    },
    [MspRolesAction.DELETE]: {
      title: "¿Eliminar la función?",
      description: "Se eliminará la función <strong>{name}</strong>",
      actionBtn: "Sí, eliminar",
    },
    [MspRolesAction.UNABLE_TO_DELETE]: {
      title: "No se puede eliminar la función",
      description:
        "Solo se pueden eliminar funciones que no estén asociadas con administradores. Elimine esta función de los administradores e inténtelo de nuevo.",
      actionBtn: "Sí, eliminar",
    },
    [GlobalRolesAction.ADD]: {
      title: "Añadir función global",
      actionBtn: "Guardar",
    },
    [GlobalRolesAction.EDIT]: {
      title: "Editar función",
      actionBtn: "Guardar",
    },
    [GlobalRolesAction.DUPLICATE]: {
      title: "Función duplicada",
      actionBtn: "Guardar",
    },
    [GlobalRolesAction.DELETE]: {
      title: "¿Eliminar la función?",
      description: "Se eliminará la función <strong>{name}</strong>",
      actionBtn: "Sí, eliminar",
    },
    [GlobalRolesAction.UNABLE_TO_DELETE]: {
      title: "No se puede eliminar la función",
      description:
        "Solo se pueden eliminar funciones que no estén asociadas con administradores. Elimine esta función de los administradores e inténtelo de nuevo.",
      actionBtn: "Sí, eliminar",
    },
    [ActiveSessionsAction.REVOKE]: {
      title: "¿Anular esta sesión activa?",
      description: "La sesión del usuario <strong>{email}</strong> se cerrará en el sistema.",
      actionBtn: "Sí, anular",
    },
    [AccessPermissionAction.ADD]: {
      title: "Nuevos permisos de acceso para {type}",
      description: "Permitir el acceso a {service} desde países o direcciones IP específicos",
      restrict: {
        title: "Permitir el acceso desde:",
        placeholder: "Seleccionar",
        allCountries: "Todos los países",
        usaStates: "Estados de EE. UU.",
        country: {
          placeholder: "Introduzca una lista de países desde los cuales se debe permitir el acceso",
          label: "Países",
          noData: "No se ha encontrado ningún país que coincida",
        },
        ip: {
          placeholder:
            "Introduzca una lista de direcciones IP o de rangos de IP en notación CIDR desde los cuales se debe permitir el acceso",
          label: "Direcciones IP",
        },
        ipDescription: {
          placeholder:
            "Descripción breve opcional del conjunto seleccionado de direcciones IP (p. ej., oficina)",
          label: "Descripción",
        },
        types: {
          country: "País",
          ip: "Dirección IP",
        },
      },
      group: {
        title: "Se permitirá el acceso a:",
        label: "Grupos",
        placeholder: "Introduzca el nombre del grupo protegido",
        noData: "No se ha encontrado ningún grupo que coincida",
        types: {
          all: "Todos los usuarios protegidos",
          group: "Grupos protegidos específicos",
        },
      },
      users: {
        label: "Correo electrónico",
        placeholder: "Introduzca el o los correos electrónicos",
      },
      remediation: {
        title: "Corrección automática",
        types: {
          none: "Nada",
          suspend: "Retirar",
          signin: "Cerrar sesión",
        },
      },
      actionBtn: "Guardar permisos",
      errors: {
        general: "Falta información",
        restriction: "Seleccione el tipo de permiso",
      },
    },
    [AccessPermissionAction.EDIT]: {
      title: "Editar permiso de acceso",
      actionBtn: "Guardar permisos",
    },
    [AccessPermissionAction.DELETE]: {
      title: "¿Eliminar permiso de acceso?",
      description: "Los permisos de acceso ya no se aplicarán a los usuarios respectivos.",
      actionBtn: "Sí, eliminar",
    },
    [LabelAction.CREATE_LABEL]: {
      title: "Añadir etiqueta",
      placeholder: "Título",
      actionBtn: "Añadir",
      selectColor: "Seleccionar color",
    },
    [LabelAction.EDIT_LABEL]: {
      title: "Editar etiqueta",
      actionBtn: "Guardar",
    },
    [LabelAction.DELETE_LABEL]: {
      title: "¿Eliminar etiqueta {name}?",
      description:
        "Tenga en cuenta que la etiqueta <b>{name}</b> se eliminará para el dispositivo <b>{deviceCount}</b>. | Tenga en cuenta que la etiqueta <b>{name}</b> se eliminará para los dispositivos <b>{deviceCount}</b>.",
      actionBtn: "Sí, eliminar",
    },
    [AgentDeploymentAction.SELECT_CHANNEL]: {
      title: "Mostrar canales de versiones",
      description: "Configurar qué canales de versiones están permitidos en el Workspace",
      channelChoices: {
        stable: "Canales de versiones estables",
        all: "Todos los canales de versiones",
      },
      actionBtn: "Guardar",
    },
    [DeviceAction.REMOVE_FROM_PROTECTION]: {
      title: "¿Deshabilitar la protección? | ¿Ocultar estos dispositivos?",
      multipleDeviceTitle: "¿Ocultar estos dispositivos?",
      description:
        "El dispositivo ya no estará supervisado ni protegido por el agente de protección de terminales de Coro. | Los dispositivos ya no estarán supervisados ni protegidos por los agentes de protección de terminales de Coro.",
      actionBtn: "Confirmar",
    },
    [DeviceAction.ENFORCE_UAC]: {
      title: "¿Imponer la notificación de UAC?",
      description: "Se habilitará de nuevo la notificación de UAC en este dispositivo.",
      actionBtn: "Imponer",
    },
    [DeviceAction.DISABLE_DEVELOPER_MODE]: {
      title: "¿Deshabilitar el modo desarrollador?",
      description: "Se deshabilitará el modo desarrollador en este dispositivo.",
      actionBtn: "Deshabilitar",
    },
    [DeviceAction.DISABLE_TAMPER_RESISTANCE]: {
      title: "¿Deshabilitar la protección contra manipulaciones?",
      description:
        "Esta acción hace que tanto el agente de terminales de Coro como las copias de seguridad de recuperación sean vulnerables a ataques de malware y ransomware. Por lo tanto, la protección contra manipulaciones se debe considerar solo para el mantenimiento controlado del dispositivo o para permitir al usuario desinstalar Coro Endpoint Agent.",
      actionBtn: "Deshabilitar",
    },
    [DeviceAction.ENABLE_FIREWALL]: {
      title: "Habilitar cortafuegos",
      description: "¿Imponer la habilitación del cortafuegos?",
      actionBtn: "Sí",
    },
    [DeviceAction.REMOTE_SCAN]: {
      title: "Análisis remoto para buscar malware",
      description: "¿Hacer un análisis remoto para buscar malware?",
      actionBtn: "Sí",
    },
    [DeviceAction.DLP_PARTIAL_SCAN]: {
      title: "Análisis remoto para buscar datos confidenciales",
      description:
        "El análisis de unidades de puntos finales para buscar datos confidenciales es una operación que consume recursos (CPU/memoria).\n¿Iniciar el análisis remoto?\n",
      partialScan:
        "Detener el análisis cuando los resultados sean lo suficientemente sustanciales.",
      actionBtn: "Sí",
    },
    [DeviceAction.STOP_REMOTE_SCAN]: {
      title: "Detener el análisis remoto para buscar malware",
      description: "¿Detener el análisis remoto para buscar malware?",
      actionBtn: "Sí",
    },
    [DeviceAction.STOP_DLP_SCAN]: {
      title: "Detener el análisis para buscar datos confidenciales",
      description: "¿Detener el análisis para buscar datos confidenciales?",
      actionBtn: "Sí",
    },
    [DeviceAction.ALLOW_NO_ENCRYPTION]: {
      title: "¿Añadir este disco duro a la lista de permitidos?",
      description:
        "En el futuro, se considerará que este disco duro no incluye datos confidenciales por los que haya que cifrar el disco.",
      actionBtn: "Confirmar",
    },
    [DeviceAction.COLLECT_LOGS]: {
      title: "¿Recopilar registros?",
      description:
        "¿Seguro que desea recopilar registros para el dispositivo <b>{enrollmentCode}</b>?",
      actionBtn: "Sí, recopilar",
    },
    [DeviceAction.ENABLE_NETWORK_BLOCK_MODE]: {
      title: "¿Aislar el dispositivo afectado? | ¿Aislar los dispositivos afectados?",
      description:
        "El dispositivo se aislará de la red. | {quantity} dispositivos se aislarán de la red.",
      actionBtn: "Confirmar",
    },
    [DeviceAction.DISABLE_NETWORK_BLOCK_MODE]: {
      title:
        "¿Deshabilitar el aislamiento del dispositivo? | ¿Deshabilitar el aislamiento de los dispositivos?",
      description:
        "Se deshabilitará el aislamiento del dispositivo de la red. | Se deshabilitará el aislamiento de {quantity} dispositivos de la red.",
      actionBtn: "Confirmar",
    },
    [DeviceAction.REBOOT_DEVICE]: {
      title: "¿Reiniciar el dispositivo? | ¿Reiniciar los dispositivos?",
      description: "El dispositivo se reiniciará. | {quantity} dispositivos se reiniciarán.",
      actionBtn: "Confirmar",
    },
    [DeviceAction.SHUTDOWN_DEVICE]: {
      title: "¿Apagar el dispositivo? | ¿Apagar los dispositivos?",
      description: "El dispositivo se apagará. | {quantity} dispositivos se apagarán.",
      actionBtn: "Confirmar",
    },
    [DeviceAction.UPDATE_AGENT_TO_LATEST]: {
      title: "¿Actualizar el agente a la última versión estable?",
      description:
        "El agente en los dispositivos seleccionados se actualizarán a la última versión estable.",
      actionBtn: "Sí",
    },
    [DeviceAction.ENABLE_TAMPER_RESISTANCE]: {
      title: "¿Permitir etiqueta?",
      description:
        "Permitir la desinstalación expone las copias de seguridad de terminales de Coro Agent y de recuperación a ataques de malware y ransomware. Permitir la desinstalación del agente solo se debe habilitar para el mantenimiento controlado del dispositivo o para permitir que el usuario desinstale el terminal de Coro Agent.",
      actionBtn: "Permitir",
    },
    [DeviceAction.COLLECT_QUARANTINE_DATA]: {
      title: "¿Recopilar lista en cuarentena?",
      description:
        "¿Seguro que quiere recopilar la lista en cuarentena del dispositivo <b>{enrollmentCode}</b>?",
      actionBtn: "Sí, recopilar",
    },
    [DeviceAction.ADD_LABEL]: {
      title: "Añadir etiqueta de dispositivo",
      placeholder: "Nombre...",
      actionBtn: "Guardar",
      manageLabels: "Gestionar etiquetas ›",
    },
    [DeviceAction.REMOVE_LABEL]: {
      title: "Eliminar etiqueta del dispositivo",
      placeholder: "Nombre...",
      actionBtn: "Guardar",
      manageLabels: "Gestionar etiquetas ›",
    },
    [DeviceAction.OPEN_REMOTE_SHELL_SESSION]: {
      title: "Conexión de shell remoto",
      connectingTo: "Conectando a {name}. La conexión puede tardar hasta {minutos} minutos",
      connectedTo: "Se ha conectado a {name}",
      commandInProgress: "Esperando la ejecución del comando",
      helpTitle: "A continuación, se muestra la lista de comandos compatibles:",
      unknownCommand:
        "Comando desconocido: {command}. Escriba 'ayuda' para ver la lista de comandos compatibles.",
      combinedCommandNotSupported:
        'Los comandos combinados no son compatibles; para los comandos compatibles, ejecute "ayuda"',
      uploadScriptLabel: "Subir secuencia de comandos",
      uploadScriptHint: "Solo se permiten archivos con extensión .ps1",
      run: "Ejecutar",
      errors: {
        shellSessionOpeningFailed:
          "El dispositivo no responde a la solicitud del shell remoto.<br> Inténtelo de nuevo más tarde.",
        shellSessionClosed: "La sesión del shell remoto se ha cerrado en el dispositivo.",
        commandFailed:
          "El dispositivo no responde a los comandos.<br> Inténtelo de nuevo más tarde.",
        tooManyShellSessionsOpened:
          "El dispositivo tiene demasiadas conexiones activas de shell remotos.",
      },
    },
    [EdrAction.BLOCK_PROCESS]: {
      title: "¿Bloquear el proceso? | ¿Bloquear los procesos seleccionados?",
      description:
        "Se bloqueará el proceso <b>{name}</b> con SHA-256 <b>{processHash}</b>. | Se bloquearán los procesos seleccionados.",
      actionBtn: "Confirmar",
    },
    [EdrAction.UNBLOCK_PROCESS]: {
      title: "¿Desbloquear el proceso? | ¿Desbloquear los procesos seleccionados?",
      description:
        "Se desbloqueará el proceso <b>{name}</b> con SHA-256 <b>{processHash}</b>. | Se desbloquearán los procesos seleccionados.",
      actionBtn: "Confirmar",
    },
    [MspWorkspaceAction.EDIT_SUBSCRIPTION]: {
      title: "Editar suscripción",
      actionBtn: "Guardar",
    },
    [MspWorkspaceAction.STOP_SUBSCRIPTION]: {
      title: "¿Cancelar la suscripción?",
      description:
        "Cancele su contrato de suscripción y facturación con Coro. Se deshabilitará la protección de sus usuarios y dispositivos.",
      actionBtn: "Sí",
    },
    [MspWorkspaceAction.EDIT_SUBSCRIPTION_CONFIRMATION]: {
      title: "¿Deshabilitar módulos?",
      description:
        "Deshabilita los módulos <b>{removedModules}</b>. Los tickets relacionados con estos módulos se cerrarán de forma automática.",
      actionBtn: "Confirmar",
    },
    [MspWorkspaceAction.EXTEND_TRIAL]: {
      title: "¿Ampliar el periodo de prueba?",
      description: "Seleccione una nueva fecha de vencimiento para este periodo de prueba",
      expirationDate: "Fecha de vencimiento del periodo de prueba",
      actionBtn: "Confirmar",
    },
    [MspWorkspaceAction.START_SUBSCRIPTION]: {
      title: "¿Iniciar suscripción?",
      description: "Inicie su contrato de suscripción y facturación con Coro.",
      actionBtn: "Sí",
    },
    [MspWorkspaceAction.ARCHIVE_WORKSPACE]: {
      title: "¿Archivar Workspace?",
      description: "A partir de ahora, este Workspace quedará archivado.",
      actionBtn: "Confirmar",
    },
    [MspWorkspaceAction.UNARCHIVE_WORKSPACE]: {
      title: "¿Restaurar Workspace?",
      description:
        "Se restaurará su Workspace archivado. Antes de usarlo, primero debe volver a conectar sus aplicaciones en la nube, añadir usuarios a la protección y reconfigurar sus ajustes de protección.",
      actionBtn: "Confirmar",
    },
    [MspWorkspaceAction.CONVERT]: {
      title: "Convertir Workspace",
      selectTypeLabel: "Seleccione el tipo al que desea convertir su Workspace",
      selectChannelLabel: "Seleccione el Workspace de canal que debe usarse como principal",
      actionBtn: "Convertir",
      convertToChannelDescription: "¿Seguro que desea convertir este Workspace a canal?",
      convertToRegular: "Convertir en Workspace regular",
      changeParent: "Cambiar Workspace principal",
      changeChannelParent: "Cambiar Workspace principal",
      convertToChannel: "Convertir en Workspace de canal",
      convertToChild: "Convertir en Workspace secundario",
      workspacesAutocomplete: {
        label: "Workspace",
        placeholder: "Seleccionar Workspace",
      },
      descendantConversionOptionsTitle:
        "Tu Workspace de canal podría tener Workspaces secundarios; selecciona a continuación que hay que hacer con ellos",
      descendantConversionOptions: {
        convertToRegular:
          "Convertir todos los Workspaces secundarios en regulares y mover solo este canal",
        cascadeMigration:
          "Unificar todos los Workspaces secundarios en este canal como uno principal nuevo",
      },
    },
    [MspWorkspaceAction.CREATE_CHILD_WORKSPACE]: {
      title: "Crear un Workspace secundario",
      actionBtn: "Crear Workspace",
    },
    [MspWorkspaceAction.CREATE_CHANNEL_WORKSPACE]: {
      title: "Crear un Workspace de canal",
      actionBtn: "Crear Workspace",
    },
    [MspWorkspaceAction.CREATE_REGULAR_WORKSPACE]: {
      title: "Crear un Workspace regular",
      actionBtn: "Crear Workspace",
    },
    [ThirdPartyAppsAction.BLOCK_APP]: {
      actionBtn: "Sí, bloquear",
      notifyUsersMessage: "Notificar a los usuarios que están usando esta aplicación bloqueada",
      description:
        "Esta acción impedirá que <b>{name}</b> acceda a los datos de sus usuarios en <b>{cloudAppName</b>}. La aplicación se prohibirá y los futuros accesos se anularán de forma automática.",
      title: "¿Quiere bloquear {name}?",
    },
    [EmailProxyAction.ADD_DOMAIN]: {
      title: "Añadir dominio al proxy {direction}",
      inboundDescription:
        "El proxy SMTP de Coro procesa los correos electrónicos enviados a cada uno de sus dominios empresariales que se reciben de uno de los retransmisores SMPT asociados a ese dominio.",
      outboundDescription: "lorem ipsum",
      domain: {
        label: "Dominio",
        placeholder: "Introduzca el nombre de dominio...",
      },
      smtpProxy: "Proxy SMTP de retransmisión",
      host: {
        title: "Host",
        placeholder: "Introduzca el host...",
      },
      ip: {
        placeholder:
          "Introduzca las direcciones IP (o los rangos de IP en notación CIDR) de los servidores SMTP permitidos",
      },
      port: "Puerto",
      actionBtn: "Añadir",
    },
    [EmailProxyAction.EDIT_DOMAIN]: {
      title: "Editar dominio {direction}",
      actionBtn: "Guardar",
    },
    [EmailProxyAction.REMOVE_DOMAIN]: {
      title: "¿Eliminar dominio?",
      description: "Coro dejará de procesar los correos electrónicos enviados a <b>{name}</b>.",
      actionBtn: "Sí, eliminar",
    },
    [EmailProxyAction.TEST_DOMAIN]: {
      title: "Probar proxy",
      description: "Pruebe que el proxy se haya establecido correctamente",
      mailTo: {
        title: "Enviar por correo a",
        placeholder: "Enviar por correo a...",
      },
      actionButton: "Enviar correo electrónico de prueba",
      emailSentMessage:
        "Se ha enviado un correo electrónico de verificación a {email}. Haga clic en el enlace del correo electrónico para finalizar la prueba.",
      proxyFailedMessage:
        "<b>Se ha producido un error en la prueba de proxy</b>. Asegúrese de que el proxy esté establecido de acuerdo con las instrucciones de la documentación.",
    },
    [WebhookAction.ADD]: {
      title: "Crear webhook",
      actionBtn: "Crear",
      generalInfo: {
        title: "Información general",
        name: "Nombre",
        description: "Descripción",
      },
      authentication: {
        title: "Autenticación",
        url: "URL",
        secret: "Secreto",
        authorization: "Autorización",
      },
      triggers: {
        title: "Activar",
      },
      additionalConfiguration: {
        applyToChildWorkspace: "Aplicar al Workspace secundario",
      },
    },
    [WebhookAction.EDIT]: {
      title: "Editar webhook",
      actionBtn: "Guardar",
    },
    [WebhookAction.REMOVE]: {
      title: "¿Eliminar webhook?",
      description: "¿Seguro que quiere eliminar este webhook?",
      actionBtn: "Sí, eliminar",
    },
    [WebhookAction.DEACTIVATE]: {
      title: "¿Deshabilitar webhook?",
      description: "¿Seguro de que desea deshabilitar este webhook?",
      actionBtn: "Sí, deshabilitar",
    },
    [WebhookAction.ACTIVATE]: {
      title: "¿Habilitar webhook?",
      description: "¿Seguro que quiere habilitar este webhook?",
      actionBtn: "Sí, habilitar",
    },
    [WebhookAction.ACTION_REQUIRED]: {
      description:
        'Hay disponible una nueva versión del activador <b>{name}</b>. Para mantener su webhook activo, seleccione <b>Editar</b> en el menú de tres puntos y configúrelo para el {expirationDate}. <b>Después de esta fecha, este webhook dejará de funcionar.</b><br> <span style="line-height: 2">Actualice a la nueva versión del activador para asegurarse de que siga funcionando.</span> | Hay disponible una nueva versión del activador <b>{name}</b>. Para mantener su webhook activo, seleccione <b>Editar</b> en el menú de tres puntos y configúrelo para el {expirationDate}. <b>Después de esta fecha, estos webhooks dejarán de funcionar.</b><br> <span style="line-height: 2">Actualice a la nueva versión del activador para asegurarse de que siga funcionando.</span>',
      actionBtn: "Configurar webhook",
      cancelBtn: "Ignorar",
    },
    [WebhookAction.CANNOT_ACTIVATE]: {
      title: "El webhook no se puede reactivar",
      description:
        'Este webhook no se puede activar porque está usando un activador obsoleto. <br> <span style="line-height: 2">Actualice a la última versión del activador para reactivarlo.</span>',
      actionBtn: "Configurar webhook",
      cancelBtn: "Cerrar",
    },
    createWorkspace: {
      chooseYourSubscription: "Elija sus suscripciones",
      recommendedModules: "Módulos recomendados",
      addons: "Complementos",
      includedInBundles: "Incluido en tus paquetes",
      includedInBundle: "Incluido en el paquete",
      companyDetails: "Datos de la empresa",
      brandingSettings: "Configuración de la marca",
      replaceCoroIcons: "Reemplazar iconos de Coro",
      promotionalCodes: "Códigos promocionales",
      reviewSubscription: "Revisar su suscripción",
      advancedSettings: "Configuración avanzada",
      workspaceSettings: "Configuración del Workspace",
      descriptions: {
        createChannelMspWorkspace:
          "Al crear un Workspace, se enviará una invitación al correo electrónico del representante.",
        createChildMspWorkspace:
          "Se enviará una invitación al administrador de este Workspace. Tendrá función de administrador para esta cuenta.",
        createRegularWorkspace:
          "Se enviará una invitación al administrador de este Workspace. Tendrá función de administrador para esta cuenta.",
      },
      workspaceCreated: "{workspace} se ha creado correctamente",
      inviteSent: "Se ha enviado una invitación a {email}",
      mspAdminExists:
        "Tenga en cuenta que la cuenta de administrador {email} ya existe como administrador de MSP en el Workspace principal. Por lo tanto, esta cuenta no se añadirá como administrador a su Workspace, pero aún podrá gestionar su Workspace y sus descendientes de acuerdo con los permisos de la función de MSP.",
      requestInProgress: "Espere, por favor. Estamos procesando su solicitud",
      requestFailed:
        "Se ha producido un error al crear el Workspace. Inténtelo de nuevo más tarde.",
      companyName: "Nombre de la empresa",
      domain: "Nombre del dominio",
      parentWorkspace: "Workspace principal",
      domainPlaceholder: "Nombre del dominio (por ejemplo, «customerdomain.com»)",
      domainHint: "Introduzca el dominio completo del correo electrónico del cliente",
      displayName: "Nombre para mostrar",
      displayNameHint: "30 caracteres como máximo",
      limitProtectedUsers: "Limitar usuarios protegidos (opcional)",
      limitProtectedUsersPlaceholder: "Opcional: limitar el número de usuarios protegidos",
      limitProtectedUsersHint:
        "Si se limitan, el cliente no podrá añadir más usuarios del número especificado a la protección de Coro.",
      adminEmail: "Correo electrónico del administrador",
      adminEmailOptional: "Correo electrónico del administrador (opcional)",
      adminEmailPlaceholder: "Correo electrónico del administrador del cliente",
      adminEmailHint:
        "Se invitará al administrador del cliente al Workspace. También puede hacerlo dentro del Workspace más adelante.",
      notifications: "Notificaciones",
      imageError: "La imagen no cumple con los requisitos",
      headerIcon: "Icono del encabezado",
      replaceImage: "Reemplazar",
      imageHint: "Icono cuadrado blanco sobre fondo transparente. Máximo 500 KB. png, svg",
      promoCodes: {
        label: "Código promocional",
        placeholder: "Introduzca un código de configuración de Workspace",
        description:
          "Rellene el código del Workspace para ayudar al equipo de Coro a configurar su Workspace",
      },
      language: {
        label: "Idioma predeterminado de la plataforma",
        placeholder: "Idioma predeterminado de la plataforma",
      },
      notificationLevels: {
        [WorkspaceType.CHANNEL]: {
          [NotificationsLevel.NONE]: "Ninguno",
          [NotificationsLevel.PARENT]: "Solo para administradores de MSP de <b>este</b> Workspace",
          [NotificationsLevel.ALL]: "Administradores locales y de MSP",
          [NotificationsLevel.LOCAL_ADMINS]: "Solo para administradores locales",
          subchannelParent: "Solo para administradores de MSP de Workspace <b>principal</b>",
        },
        [WorkspaceType.CHILD]: {
          [NotificationsLevel.NONE]: "Ninguno",
          [NotificationsLevel.PARENT]:
            "Solo para administradores de MSP de Workspace <b>este</b> principal",
          [NotificationsLevel.ALL]: "Administradores locales y de MSP",
          [NotificationsLevel.LOCAL_ADMINS]: "Solo para administradores locales",
        },
        [WorkspaceType.REGULAR]: {
          [NotificationsLevel.NONE]: "Nada",
          [NotificationsLevel.PARENT]:
            "Solo para administradores de <b>{domain}</b> y los usuarios de <b>este</b> Workspace",
          [NotificationsLevel.ALL]: "Todos los administradores",
        },
      },
      actionBtn: "Crear Workspace",
      edrEnabled: "Endpoint Detection & Response habilitado",
      psaEnabled: "PSA habilitada",
      showSupportLink: "Mostrar enlace para contactar con asistencia",
      scanUnprotectedUsers: "Analizar usuarios desprotegidos",
      showDisabledModules: "Mostrar módulos y complementos deshabilitados",
      coroEmployeeWorkspace: "Workspace para empleados de Coro",
      branding: {
        title: "Configuración de la marca",
        aliasLabel: "Subdominio",
        aliasHint: "40 caracteres como máximo",
        aliasFullDomainHint: "La dirección de dominio completa será {domain}.coro.net",
        supportEmailLabel: "Correo electrónico de asistencia",
        supportEmailHint: "100 caracteres como máximo",
        noReplyEmailLabel: "Correo electrónico de no respuesta",
        noReplyEmailHint:
          "El correo electrónico de no respuesta se genera de forma automática desde el campo de subdominio",
        brandColorLabel: "Color de la marca",
        brandColorHint: "Utilice un formato de color HEX válido (#262260)",
        companyAddressLabel: "Dirección de la empresa",
        companyAddressHint:
          "El nombre y la dirección de la empresa aparecerán en el pie de página de los correos electrónicos enviados desde un correo electrónico de no respuesta de la marca",
      },
      managed: "Gestionado",
      unmanaged: "No gestionado",
      summary: {
        modulesAndAddons: "Módulos y complementos",
        bundles: "Paquetes",
        title: "Resumen de la suscripción",
        userMonth: "/usuario/mo.",
        billedAnnually: "Facturación anual*",
        [SubscriptionBundle.CORO_CLASSIC]: {
          title: "Coro Classic",
          subtitle: "No gestionado",
        },
        [SubscriptionBundle.CORO_COMPLETE]: {
          title: "Coro Complete",
          subtitle: "No gestionado",
        },
        [SubscriptionBundle.MANAGED_CORO_COMPLETE]: {
          title: "Coro Complete",
          subtitle: "Gestionado",
        },
        [SubscriptionBundle.MANAGED_CORO_CLASSIC]: {
          title: "Coro Classic",
          subtitle: "Gestionado",
        },
        [SubscriptionBundle.ENDPOINT_PROTECTION]: {
          title: "Endpoint Protection",
          subtitle: "No gestionado",
        },
        [SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION]: {
          title: "Endpoint Protection",
          subtitle: "No gestionado",
        },
        [SubscriptionBundle.SASE]: {
          title: "SASE",
          subtitle: "No gestionado",
        },
        [SubscriptionBundle.MANAGED_SASE]: {
          title: "SASE",
          subtitle: "Gestionado",
        },
        [SubscriptionBundle.EMAIL_PROTECTION]: {
          title: "Email Protection",
          subtitle: "No gestionado",
        },
        [SubscriptionBundle.MANAGED_EMAIL_PROTECTION]: {
          title: "Email Protection",
          subtitle: "Gestionado",
        },
        [SubscriptionBundle.MANAGED_CORO_ESSENTIALS]: {
          title: "Coro Essentials",
          subtitle: "Gestionado",
        },
        [SubscriptionBundle.CORO_ESSENTIALS]: {
          title: "Coro Essentials",
          subtitle: "No gestionado",
        },
        [SubscriptionModule.EMAIL_SECURITY]: {
          title: "Email Security",
        },
        [SubscriptionModule.NETWORK]: {
          title: "Network",
        },
        [SubscriptionModule.USER_DATA_GOVERNANCE]: {
          title: "User Data Governance",
        },
        [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: {
          title: "Endpoint Data Governance",
        },
        [SubscriptionModule.MDM]: {
          title: "Mobile Device Mgmt",
        },
        [SubscriptionModule.ENDPOINT_SECURITY]: {
          title: "Endpoint Security",
        },
        [SubscriptionModule.CLOUD_SECURITY]: {
          title: "Cloud Security",
        },
        [SubscriptionModule.EDR]: {
          title: "EDR",
        },
        [SubscriptionAddon.WIFI_PHISHING]: {
          title: "Wi-Fi Phishing",
        },
        [SubscriptionAddon.SECURED_MESSAGES]: {
          title: "Secure Messages",
        },
        [SubscriptionAddon.INBOUND_GATEWAY]: {
          title: "Inbound Gateway",
        },
        [SubscriptionAddon.SWG]: {
          title: "Secure Web Gateway",
        },
        [SubscriptionAddon.ZTNA]: {
          title: "Zero Trust Network Access",
        },
      },
    },
    [MspWorkspaceAction.EDIT_WORKSPACE]: {
      titles: {
        channel: "Editar información del Workspace de canal",
        child: "Editar información del Workspace secundario",
        regular: "Editar información del Workspace",
      },
      companyName: "Nombre de la empresa",
      displayName: "Nombre para mostrar",
      displayNameHint: "30 caracteres como máximo",
      limitProtectedUsersPlaceholder: "Opcional: limitar el número de usuarios protegidos",
      limitProtectedUsersHint:
        "Si se limitan, el cliente no podrá añadir más usuarios del número especificado a la protección de Coro.",
      limitProtectedUsers: "Limitar usuarios protegidos (opcional)",
      notifications: "Notificaciones",
      imageError: "La imagen no cumple con los requisitos",
      headerIcon: "Icono del encabezado",
      replaceImage: "Reemplazar",
      deleteWorkspace: "Eliminar Workspace",
      imageHint: "Icono cuadrado blanco sobre fondo transparente. Máximo 500 KB. png, svg",
      notificationLevels: {
        [NotificationsLevel.NONE]: "Ninguno",
        [NotificationsLevel.PARENT]: "Solo para administradores de canal",
        [NotificationsLevel.ALL]: "Todos los administradores",
        [NotificationsLevel.LOCAL_ADMINS]: "Solo para administradores locales",
      },
      actionBtn: "Guardar",
      edrEnabled: "Endpoint Detection & Response habilitado",
      psaEnabled: "PSA habilitada",
      branding: {
        title: "Configuración de la marca",
        aliasLabel: "Subdominio",
        aliasHint: "40 caracteres como máximo",
        aliasFullDomainHint: "La dirección de dominio completa será {domain}.coro.net",
        supportEmailLabel: "Correo electrónico de asistencia",
        supportEmailHint: "100 caracteres como máximo",
        noReplyEmailLabel: "Correo electrónico de no respuesta",
        noReplyEmailHint:
          "El correo electrónico de no respuesta se genera de forma automática desde el campo de subdominio",
        brandColorLabel: "Color de la marca",
        brandColorHint: "Utilice un formato de color HEX válido (#262260)",
        companyAddressLabel: "Dirección de la empresa",
        companyAddressHint:
          "El nombre y la dirección de la empresa aparecerán en el pie de página de los correos electrónicos enviados desde un correo electrónico de no respuesta de la marca",
      },
    },
    [MspAdminUsersAction.ADD]: {
      title: "Añadir administrador de MSP",
      email: {
        placeholder: "Seleccionar administrador",
        label: "Administrador",
      },
      role: {
        placeholder: "Seleccionar función",
        label: "Función",
      },
      workspace: {
        placeholder: "Seleccionar Workspace",
        label: "Workspace",
        hint: "Seleccionar Workspace para añadir un administrador y una función",
      },
      actionBtn: "Guardar",
      errors: {
        general: "Corrija los errores a continuación",
        restriction: "Seleccione el tipo de permiso",
      },
    },
    [MspAdminUsersAction.EDIT]: {
      title: "Editar el administrador de MSP",
      actionBtn: "Guardar",
    },
    [MspAdminUsersAction.DELETE]: {
      title: "¿Eliminar el administrador de MSP?",
      description:
        "<span class='text--semibold'>{email}</span> ya no tendrá acceso a los Workspaces secundarios.",
      actionBtn: "Sí, eliminar",
    },
    [GlobalAdminUsersAction.ADD]: {
      title: "Añadir administrador global",
      actionBtn: "Guardar",
    },
    [GlobalAdminUsersAction.EDIT]: {
      title: "Editar el administrador global",
      actionBtn: "Guardar",
    },
    [GlobalAdminUsersAction.DELETE]: {
      title: "¿Eliminar el administrador global?",
      description:
        "<span class='text--semibold'>{email}</span> ya no tendrá acceso a los Workspaces secundarios.",
      actionBtn: "Guardar",
    },
    [ExportAction.EXPORT_ACTIVITY_LOGS]: {
      title: "Nueva exportación del registro de actividad",
      disclaimer: "Los datos de los últimos 90 días se incluirán de forma predeterminada",
      subtitle: "Actividades",
      choices: {
        [ExportChoice.ALL]: "Todas las actividades",
        [ExportChoice.SPECIFIC]: "Actividades específicas",
      },
      actionBtn: "Generar",
    },
    [ExportAction.EXPORT_PROTECTED_USERS]: {
      title: "Nueva exportación de usuarios protegidos",
      subtitle: "Usuarios protegidos",
      choices: {
        [ExportChoice.ALL]: "Todos los usuarios protegidos",
        [ExportChoice.SPECIFIC]: "Usuarios protegidos específicos",
      },
      actionBtn: "Generar",
    },
    [ExportAction.EXPORT_DEVICES]: {
      title: "Nueva exportación de dispositivos",
      subtitle: "Dispositivos",
      choices: {
        [ExportChoice.ALL]: "Todos los dispositivos",
        [ExportChoice.SPECIFIC]: "Dispositivos específicos",
      },
      actionBtn: "Generar",
    },
    [ExportAction.EXPORT_TICKETS]: {
      title: "Nueva exportación del registro de ticket",
      disclaimer: "Los datos de los últimos 90 días se incluirán de forma predeterminada",
      subtitle: "Tickets",
      choices: {
        [ExportChoice.ALL]: "Todos los tickets",
        [ExportChoice.SPECIFIC]: "Tickets específicos",
      },
      actionBtn: "Generar",
    },
    [SecureWebGatewayAction.TOGGLE_DNS_FILTERING]: {
      title: "Habilitar filtrado de DNS",
      description:
        "<b>Tiene que leer esto</b> <br> <br> La activación del filtrado de DNS bloquea el acceso a cualquier recurso externo sospechoso o explícitamente bloqueado de su oficina virtual.",
      actionBtn: "Sí, habilitar",
    },
    [SecureWebGatewayAction.REMOVE_URL_GROUP_FROM_ALLOW_LIST]: {
      title: "¿Eliminar de la lista de permitidos?",
      description: "Ya no se permitirán las URL de <b>{item}</b>",
      actionBtn: "Sí, eliminar",
    },
    [SecureWebGatewayAction.DISABLE_URL_GROUP_BLOCK_LIST]: {
      title: "¿Deshabilitar la lista de URL?",
      description: "Ya no se bloquearán las URL de <b>{name}</b>.",
      actionBtn: "Sí, deshabilitar",
    },
    [SecureWebGatewayAction.ENABLE_URL_GROUP_BLOCK_LIST]: {
      title: "¿Habilitar lista de URL?",
      description: "Se bloquearán las URL de <b>{name}</b>.",
      actionBtn: "Sí, habilitar",
    },
    [SecureWebGatewayAction.ENABLE_URL_GROUP_ALLOW_LIST]: {
      title: "¿Habilitar lista de URL?",
      description: "Se permitirán las URL de <b>{name}</b>.",
      actionBtn: "Sí, habilitar",
    },
    [SecureWebGatewayAction.REMOVE_FROM_ALLOWLIST]: {
      title: "¿Eliminar de la lista de permitidos?",
      description: "<b>{item}</b> ya no estará permitido.",
      actionBtn: "Sí, eliminar",
    },
    [SecureWebGatewayAction.DISABLE_URL_GROUP_ALLOW_LIST]: {
      title: "¿Deshabilitar la lista de URL?",
      description: "Ya no se permitirán las URL de <b>{name}</b>.",
      actionBtn: "Sí, deshabilitar",
    },
    [SecureWebGatewayAction.REMOVE_FROM_BLOCKLIST]: {
      title: "¿Eliminar de la lista de bloqueados?",
      description: "<b>{item}</b> ya no se bloqueará.",
      actionBtn: "Sí, eliminar",
    },
    [SecureWebGatewayAction.REMOVE_URL_GROUP_FROM_BLOCK_LIST]: {
      title: "¿Eliminar de la lista de bloqueados?",
      description: "Ya no se bloquearán las URL de <b>{item}</b>.",
      actionBtn: "Sí, eliminar",
    },
    [SecureWebGatewayAction.ADD_URL_TO_ALLOWLIST]: {
      actionBtn: "Añadir a la lista",
    },
    allowBlockListSwg: {
      allowListTitle: "Añadir a la lista de permitidos",
      blockListTitle: "Añadir a la lista de bloqueados",
      recordType: "Tipo de registro",
      addCustomCategory: "Añadir categoría personalizada",
      addSpecificUrl: "Añadir URL específica",
      uploadCategoryCsv: "Subir archivo de categoría",
      actionBtn: "Añadir a la lista",
      nicknamePlaceholder: "Introduzca el alias de la categoría...",
      nicknameLabel: "Alias",
      linkLabel: "URL",
      linkPlaceholder: "Enlace a la lista de URL externas",
      urlLabel: "URL",
      urlPlaceholder: "Introduzca las URL...",
      uploadFailed: "Error al subir",
      incorrectFileExtension: "Extensión de archivo incorrecta",
      fileMissing: "Añadir un archivo",
      fileSizeError: "El archivo es demasiado grande. Suba un archivo de menos de 1 MB",
    },
    [ZtnaAction.EDIT_ZTNA_RULE]: {
      title: "Editar una política de acceso",
      actionBtn: "Guardar",
    },
    [ZtnaAction.ADD_ZTNA_RULE]: {
      title: "Crear una política de acceso",
      actionBtn: "Guardar",
      titles: {
        resourceDetails: "Información del recurso",
        resourceConfigurations: "Ajustes de recursos",
        deviceLabels: "Aplicar a dispositivos con estas etiquetas",
      },
      placeholders: {
        resourceName: "Nombre del recurso",
        resourceDescription: "Descripción",
        ipAddress: "Dirección IP",
        labels: "Seleccione las etiquetas de los dispositivos que pueden conectarse…",
        port: "Puerto",
        protocol: "Protocolo",
      },
    },

    [ZtnaAction.DISABLE_ZTNA]: {
      actionBtn: "SÍ, DESHABILITAR",
      title: "¿Quiere deshabilitar ZTNA?",
      description:
        "Al deshabilitar Zero Trust Network Access (ZTNA), se eliminan las restricciones y se permiten todas las conexiones de red a todos los usuarios de la oficina virtual, no solo las permitidas en la política de recursos.",
    },
    [ZtnaAction.ENABLE_ZTNA]: {
      actionBtn: "SÍ, HABILITAR",
      title: "¿Quiere habilitar ZTNA?",
      description:
        "Habilitar el acceso a Zero Trust Network Access (ZTNA) bloquea todas las conexiones dentro de la red virtual de forma predeterminada. Solo los recursos que se hayan configurado mediante la política de recursos seguirán siendo accesibles.",
    },
    [VirtualOfficeAction.SETUP]: {
      title: "Confirme su selección",
      description:
        "La selección de región es un proceso que se realiza solo una vez durante la configuración. Para cambiar la región después de la configuración, póngase en contacto con el equipo de asistencia.",
      selectedRegion: "Región seleccionada:",
      confirmInputLabel: "Escriba APROBAR para confirmar:",
      actionBtn: "Confirmar",
    },
    [VirtualOfficeAction.SELECT_PREDEFINED_VPN_LABEL]: {
      title: "Confirme su selección",
      description:
        "Al asignar etiquetas predeterminadas podría estar exponiendo su red a riesgos de seguridad. Los dispositivos añadidos a esta etiqueta obtienen acceso de forma automática. <br>Recomendamos crear etiquetas de forma manual para supervisar y gestionar los servicios asignados.",
      selectedLabelConfirmation: "¿Seguro que quiere asignar esta etiqueta?",
      selectedLabel: "Etiqueta seleccionada: «{name}»",
      actionBtn: "Confirmar",
    },
    [VirtualOfficeAction.TOGGLE_ENCRYPTION_STRENGTH]: {
      title: "¿Cambiar la intensidad del cifrado?",
      description:
        "Al cambiar la intensidad del cifrado, su oficina virtual se desconecta de internet hasta dos minutos. Recomendamos realizar este cambio fuera del horario de atención.",
      selectedLabelConfirmation: "¿Seguro que quiere asignar esta etiqueta?",
      selectedLabel: "Etiqueta seleccionada: «{name}»",
      actionBtn: "Sí, cambiar",
    },
    [VirtualOfficeAction.VIEW_ALL_IPS]: {
      title: "Lista de direcciones IP",
      description: "{n} direcciones IP",
      actionBtn: "Cerrar",
    },
    [VirtualOfficeAction.REMOVE_FROM_INCLUDELIST]: {
      title: "¿Eliminar de la lista de incluidos?",
      description: "<b>{item}</b> ya no se incluirá.",
      actionBtn: "Sí, eliminar",
    },
    [VirtualOfficeAction.REMOVE_FROM_EXCLUDELIST]: {
      title: "¿Eliminar de la lista de excluidos?",
      description: "<b>{item}</b> ya no se excluirá.",
      actionBtn: "Sí, eliminar",
    },
    [VirtualOfficeAction.ADD_URL_TO_URL_LIST]: {
      includeListTitle: "Añadir a la lista de incluidos",
      excludedListTitle: "Añadir a la lista de excluidos",
      label: "Introduzca los dominios o las direcciones IP...",
      placeholder: "Introduzca el o los dominios o las direcciones IP…",
      actionBtn: "Añadir a la lista",
    },
    [VirtualOfficeAction.ENABLE_FULL_TUNNELING]: {
      title: "Activate full-tunneling VPN?",
      description:
        "Activating the full-tunneling VPN may result in <b>slower internet speeds, restricted access due to websites blocking VPN connections,</b> and other potential <b>functionality issues.</b>",
      actionBtn: "Yes, Activate",
    },
    [VirtualOfficeAction.DISABLE_FULL_TUNNELING]: {
      title: "Deactivate full-tunneling VPN?",
      description:
        "Deactivating the full-tunneling VPN <b>removes VPN access from assigned devices</b><br><br> If a device is also assigned to the split-tunneling VPN, it reverts to using the split-tunneling VPN instead.",
      actionBtn: "Yes, DEACTIVATE",
    },
    [ZtnaAction.DELETE_ZTNA_RULE]: {
      title: "¿Quiere eliminar la regla ZTNA?",
      description: "<b>{name}</b> se eliminará",
      actionBtn: "Sí, eliminar",
    },
    [SiteToSiteTunnelsAction.EDIT]: {
      title: "Editar túnel",
      actionBtn: "Guardar",
    },
    [SiteToSiteTunnelsAction.DELETE]: {
      title: "Eliminar túnel",
      description: "¿Quiere eliminar este túnel?",
      actionBtn: "Sí",
    },
    [SiteToSiteTunnelsAction.TEST]: {
      title: "Registro de prueba de túnel",
      actionBtn: "Cerrar",
    },
    [SiteToSiteTunnelsAction.ADD]: {
      title: "Añadir túnel",
      actionBtn: "Guardar",
      formTitle: "Información del sitio",
      inputs: {
        siteName: {
          placeholder: "Introduzca el nombre del sitio...",
          label: "Nombre del sitio",
        },
        siteDescription: {
          placeholder: "Introduzca la descripción del sitio...",
          label: "Descripción del sitio",
        },
        remoteGatewayIp: {
          placeholder: "Introduzca la IP pública de la puerta de enlace remota...",
          label: "IP pública de la puerta de enlace remota",
        },
        remoteNetworkIps: {
          subtitle: "Red de subred interna remota",
          placeholder: "Máscara de red o de IP de red interna",
          label: "Máscara de red o de IP de red interna",
          add: "+ Añadir red interna remota",
        },
        presharedKey: {
          placeholder: "Introduzca la clave precompartida...",
          label: "Clave precompartida",
          generateKey: "Generar clave",
        },
        lifetimeKey: {
          placeholder: "Introduzca la clave de por vida...",
          label: "Clave de por vida (en segundos)",
        },
      },
      detailsTitle: "Oficina virtual",
      gatewayIp: "IP de puerta de enlace",
      subnet: "Subred",
      settings: {
        title: "Configuración del cortafuegos",
        firewallType: "Tipo de cortafuegos",
        remoteNetworkMask: "Máscara de red remota",
        ikeVersion: "Versión de IKE",
        phase1Encryption: "Cifrado de la fase 1",
        phase2Encryption: "Cifrado de la fase 2",
        aggressiveMode: "Modo agresivo",
      },
      copySuccessMessage: "Clave precompartida copiada en el portapapeles",
    },
    [ScheduledReportsAction.ADD_EXPORT]: {
      title: "Nueva exportación programada",
      actionBtn: "Programa",
      frequency: "Frecuencia",
      scope: "Alcance",
      recipients: "Destinatarios",
      exportType: "Tipo de exportación",
      recipientsChoice: {
        allAdminUsers: "Todos los administradores del Workspace",
        specificAdminUsers: "Administradores específicos",
      },
      name: {
        label: "Nombre de la exportación",
      },
    },
    [ScheduledReportsAction.ADD_REPORT]: {
      title: "Nuevo informe programado",
      actionBtn: "Programar",
      frequency: "Frecuencia",
      scope: "Alcance",
      recipients: "Destinatarios",
      reportType: "Tipo de informe",
      recipientsChoice: {
        allAdminUsers: "Todos los administradores del Workspace",
        specificAdminUsers: "Administradores específicos",
      },
      name: {
        label: "Nombre del informe",
      },
    },
    [ScheduledReportsAction.DELETE]: {
      title: "¿Eliminar informe programado?",
      actionBtn: "Sí, eliminar",
      description: "Los informes ya no se enviarán.",
    },
    [ScheduledReportsAction.EDIT_EXPORT]: {
      title: "Editar exportación programada",
      actionBtn: "Programa",
    },
    [ScheduledReportsAction.EDIT_REPORT]: {
      title: "Editar informe programado",
      actionBtn: "Programar",
    },
    [BillingAction.UPDATE_SUBSCRIPTION]: {
      title: "Actualizar plan",
      actionBtn: "Guardar",
    },
    [TicketsModal.IPS_MODAL]: {
      title: "Direcciones IP",
    },
    [TicketsModal.DLP_FINDINGS_MODAL]: {
      title: "Resultados",
      type: "<div class='row'><div class='col-3'>Tipo:</div> <div class='col-9'>{type}</div></div>",
      quote:
        "<div class='row'><div class='col-3'>Cita:</div> <div class='col-9'><span class='modal-text--warning'>&quot;{quote}&quot;</span></div></div>",
      quotePlain:
        "<div class='row'><div class='col-3'>Cita:</div> <div class='col-9'><span class='modal-text--warning'>{quote}</span></div></div>",
      fileName:
        "<div class='row'><div class='col-3'>Nombre del archivo:</div> <div class='col-9'><span>{fileName}</span></div></div>",
      virusName:
        "<div class='row'><div class='col-3'>Nombre del virus:</div> <div class='col-9'><span>{virusName}</span></div></div>",
      longQuote:
        "<div class='row'><div class='col-3'>Cita:</div> <div class='col-9'>&quot;{textBeforeHighlight}<span class='modal-text--warning'>{highlightedText}</span>{textAfterHighlight}&quot;</div></div>",
      longQuoteWithoutHighlight:
        "<div class='row'><div class='col-3'>Cita:</div> <div class='col-9'>&quot;{longQuote}&quot;</div></div>",
      archivePath:
        "<div class='row'><div class='col-3'>Ruta del archivo:</div> <div class='col-9'><b>{archive}</b> &rarr; {path}<b>{file}</b></div></div>",
    },
    [TicketsModal.RECIPIENTS]: {
      title: "{n} destinatarios del correo electrónico",
    },
    [TicketsModal.USERS]: {
      title: "{n} usuarios",
    },
    [TicketsModal.BSSID_MODAL]: {
      title: "BSSID's",
    },
    [TicketsModal.SHARED_WITH]: {
      title: "Compartido con",
    },
    [CommentAction.ADD_COMMENT]: {
      title: "Comentario nuevo",
      label: "Comentario",
      placeholder: "Introducir el texto",
      notifyAffectedUsers: "Notificar a los usuarios afectados",
      notifyAdmins: "Notificar a todos los administradores del Workspace",
      notifyCustomRecipients: "Notificar a los destinatarios personalizados",
      notifySocContacts: "Notificar a los contactos del servicio gestionado",
      notifyAllWorkspaceAdmins: "Notificar a todos los administradores del Workspace",
      notifyCoroAdmins: "Notificar a todos los administradores de Coro",
      notifySpecificCoroAdmins: "Notificar a administradores de Coro específicos",
      actionBtn: "Comentario",
    },
    [CommentAction.BULK_ADD_COMMENT]: {
      title: "Comentario nuevo",
      actionBtn: "Comentario",
    },
    [TicketAction.APPROVE_EMAIL_WITH_OPTIONS]: {
      title: "Permitir",
      actionBtn: "Confirmar",
    },
    [TicketAction.DISCARD_EMAIL_WITH_OPTIONS]: {
      title: "Bloquear",
      actionBtn: "Confirmar",
    },
    [TicketAction.CONTACT_USER]: {
      title: "Contact User",
      description: "User | Users",
      actionBtn: "Send",
      more: "+ {n} more",
      placeholder: "Add Message",
      includeEventDetails: "Include ticket details in the message",
    },
    [TicketAction.REMOVE_EXPOSING_SHARING]: {
      title: "Remove exposure?",
      description: "All shares with people from outside of your organization will be removed.",
      actionBtn: "Remove",
    },
    [TicketAction.ADD_TO_DATA_GOVERNANCE_PERMISSIONS]: {
      title: "¿Ampliar los permisos de gobernanza de datos?",
      description:
        "Se autorizará al usuario {users} a acceder a {violationDirection} datos que contienen {trigger}. | Se autorizará a los usuarios {users} a acceder a {violationDirection} datos que contienen {trigger}.",
      share: "Acceder y exponer",
      access: "Acceder",
      actionBtn: "Confirmar",
    },
    [TicketAction.GENERAL_APPROVE]: {
      title: "Permitir",
      description:
        "El remitente, el dominio, la dirección IP o el rango se eliminarán de la lista de bloqueados.",
      specificDescription: {
        crowdblockedSender:
          "El remitente, el dominio, la dirección IP o el rango de la lista de bloqueados global se incluirá en la lista de permitidos de contenido sospechoso.",
        blocklistedSender:
          "El remitente, el dominio, la dirección IP o el rango se eliminarán de la lista de bloqueo de contenido sospechoso.",
        missingRequiredAuthentication:
          "El remitente, el dominio, la dirección IP o el rango se eliminarán de la lista de bloqueo de errores de autenticación.",
      },
      proxy:
        "Este correo electrónico se restaurará en las bandejas de entrada de los destinatarios.",
      actionBtn: "Confirmar",
    },
    [TicketAction.APPROVE_EMAILS_FROM_DOMAIN]: {
      title:
        "¿Añadir todos los remitentes de {name} a la lista de permitidos? | ¿Añadir todos los remitentes de los dominios seleccionados a la lista de permitidos?",
      description:
        "En el futuro, todos los correos electrónicos de <b>{name}</b> se aprobarán de forma automática. | En el futuro, todos los correos electrónicos de los dominios seleccionados se aprobarán de forma automática.",
      actionBtn: "Confirmar",
    },
    [TicketAction.ENCRYPT_DRIVE]: {
      title: "¿Cifrar la unidad?",
      description: "Se cifrará este disco duro.",
      actionBtn: "Si",
    },
    [TicketAction.DISCARD_EMAILS_FROM_DOMAIN]: {
      title:
        "¿Incluir todos los remitentes de este dominio en la lista de bloqueados? | ¿Añadir todos los remitentes de los dominios seleccionados a la lista de bloqueados?",
      description:
        "En el futuro, todos los correos electrónicos de <b>{name}</b> se eliminarán de forma automática. | En el futuro, todos los correos electrónicos de los dominios seleccionados se eliminarán de forma automática.",
      error: "El remitente del dominio {domain} no se puede incluir en la lista de bloqueados",
      actionBtn: "Confirmar",
    },
    [TicketAction.APPROVE_EMAILS_FROM_SENDER]: {
      title:
        "¿Añadir {name} a la lista de permitidos? | ¿Añadir los correos electrónicos seleccionados a la lista de permitidos?",
      description:
        "En el futuro, todos los correos electrónicos de <b>{name}</b> se aprobarán de forma automática. | En el futuro, todos los correos electrónicos de los remitentes seleccionados se aprobarán de forma automática.",
      actionBtn: "Confirmar",
    },
    [TicketAction.DISCARD_EMAILS_FROM_SENDER]: {
      title:
        "¿Incluir este remitente en la lista de bloqueados? | ¿Añadir los correos electrónicos seleccionados a la lista de bloqueados?",
      description:
        "En el futuro, todos los correos electrónicos de <b>{name}</b> se eliminarán de forma automática. | En el futuro, todos los correos electrónicos de los remitentes seleccionados se eliminarán de forma automática.",
      error: "El remitente del dominio {domain} no se puede incluir en la lista de bloqueados",
      actionBtn: "Confirmar",
    },
    [TicketAction.APPROVE_EMAIL]: {
      title:
        "¿Tratar el correo electrónico como legítimo? | ¿Tratar los correos electrónicos seleccionados como legítimos?",
      description:
        "Este correo electrónico se restaurará de forma automática en las bandejas de entrada de los destinatarios | Los correos electrónicos seleccionados se restaurarán de forma automática en las bandejas de entrada de los destinatarios",
      actionBtn: "Confirmar",
    },
    [TicketAction.DISCARD_EMAIL]: {
      title:
        "¿Tratar el correo electrónico como malicioso? | ¿Tratar los correos electrónicos seleccionados como maliciosos?",
      description:
        "Este correo electrónico se eliminará de forma automática para todos sus destinatarios | Los correos electrónicos seleccionados se eliminarán de forma automática para todos sus destinatarios",
      actionBtn: "Confirmar",
    },
    [TicketAction.DOWNLOAD_EML_FILE]: {
      title: "¿Descargar el archivo EML?",
      description:
        "Dado que el correo electrónico se ha marcado como sospechoso, utilice sus archivos adjuntos y enlaces incrustados con mucho cuidado",
      actionBtn: "Confirmar",
    },
    [TicketAction.TREAT_FILE_AS_SAFE]: {
      title: "¿Tratar el archivo como seguro?",
      description:
        "El archivo seleccionado se liberará de la cuarentena (si corresponde). En el futuro, los archivos idénticos se considerarán seguros y no se pondrán en cuarentena.",
      actionBtn: "Confirmar",
    },
    [TicketAction.EXCLUDE_FILE_PATH_AND_CERTIFICATE_FROM_SCAN]: {
      title: "¿Tratar el archivo como seguro?",
      description:
        "En el futuro, los archivos idénticos al seleccionado se considerarán seguros y, por lo tanto, no se pondrán en cuarentena.",
      actionBtn: "Confirmar",
    },
    [TicketAction.EXCLUDE_FILE_PATH_AND_FILE_HASH_FROM_SCAN]: {
      title: "¿Tratar el archivo como seguro?",
      description:
        "En el futuro, los archivos idénticos al seleccionado se considerarán seguros y, por lo tanto, no se pondrán en cuarentena.",
      actionBtn: "Confirmar",
    },
    [TicketAction.TREAT_FILE_AS_MALICIOUS]: {
      title: "¿Tratar el archivo como malicioso?",
      description:
        "En el futuro, los archivos idénticos al seleccionado se considerarán maliciosos y, por lo tanto, se eliminarán de forma inmediata.",
      actionBtn: "Confirmar",
    },
    [TicketAction.EXCLUDE_FOLDER_FROM_SCAN]: {
      title: "¿Excluir la carpeta del análisis de malware?",
      description:
        "En el futuro, el contenido de la carpeta seleccionada no se examinará para buscar malware ni ransomware.",
      actionBtn: "Excluir",
    },
    [TicketAction.SIGN_IN_TO_SERVICE]: {
      title: "¿Solicitar al usuario que inicie sesión en {service}?",
      description: "Se solicitará al usuario que vuelva a iniciar sesión en {service}.",
      actionBtn: "Solicitar",
    },
    [TicketAction.APPROVE_FILE]: {
      title: "Aprobar archivo",
      description:
        "¿Seguro que quiere restaurar el acceso a este archivo y excluirlo de los análisis de malware en el futuro?",
      actionBtn: "Sí",
    },
    [TicketAction.DELETE_FILE]: {
      title: "Eliminar archivo",
      description: "¿Seguro que desea eliminar este archivo de forma permanente?",
      actionBtn: "Eliminar",
    },
    [TicketAction.APPROVE_PROCESS_GROUP]: {
      title: "¿Tratar el grupo de procesos como seguro?",
      description:
        "Los grupos de procesos idénticos a este se considerarán seguros y, por lo tanto, no se finalizarán.",
      actionBtn: "Confirmar",
    },
    [TicketAction.DISCARD_INFECTING_FILE]: {
      title: "¿Tratar el archivo como malicioso?",
      description:
        "En el futuro, los archivos idénticos a este se considerarán maliciosos y, por lo tanto, se eliminarán de forma inmediata.",
      actionBtn: "Confirmar",
    },
    [TicketAction.ENABLE_GATEKEEPER]: {
      title: "Habilitar Gatekeeper",
      description: "¿Imponer la habilitación de Gatekeeper?",
      actionBtn: "Sí",
    },
    [TicketAction.ENABLE_APPLE_MOBILE_FILE_INTEGRITY]: {
      title: "Habilitar la integridad de archivos móviles de Apple",
      description: "¿Imponer la habilitación de la integridad de archivos móviles de Apple?",
      actionBtn: "Sí",
    },
    [TicketAction.EXPORT_MASS_DOWNLOAD_FILES]: {
      title: "Exportar una lista de archivos afectados",
      description:
        "Descargue en su ordenador un archivo de valores separados por comas (CSV) que contenga la lista de archivos afectados.",
      actionBtn: "Confirmar",
    },
    [TicketAction.EXPORT_MASS_DELETE_FILES]: {
      title: "Exportar una lista de archivos afectados",
      description:
        "Descargue en su ordenador un archivo de valores separados por comas (CSV) que contenga la lista de archivos afectados.",
      actionBtn: "Confirmar",
    },
    [TicketAction.ALLOW_PROCESS]: {
      title: "Permitir proceso",
      description: "¿Añadir proceso a la lista de permitidos?",
      checkboxText: "Cerrar este y los tickets relacionados con este proceso al confirmar",
      checkboxDescription:
        "El hash de proceso correspondiente se considerará seguro y no generará tickets de EDR.<br>No se recopilará información del proceso relacionado en las ventanas de telemetría y procesos.",
      actionBtn: "Sí",
    },
    allowBlockEmail: {
      closeAllTicketsInGroup: "Cerrar todos los tickets relacionados",
      actions: {
        default: {
          approveEmailsFromSender: {
            title:
              "Permitir este correo electrónico y añadir la dirección de correo electrónico del remitente a la lista de permitidos",
          },
          approveEmailsFromDomain: {
            title:
              "Permitir este correo electrónico y añadir el dominio del remitente a la lista de permitidos",
          },
          discardEmailsFromSender: {
            title:
              "Añadir la dirección de correo electrónico del remitente a la lista de bloqueados y eliminar permanentemente este y todos los correos electrónicos futuros de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Añadir el dominio del remitente a la lista de bloqueados y eliminar permanentemente este y todos los correos electrónicos futuros de este dominio",
          },
          approveEmail: {
            title: "Permitir solo este correo electrónico",
          },
          discardEmail: {
            title: "Eliminar permanentemente solo este correo electrónico",
          },
          approveEmailsFromSenderIp: {
            title:
              "Permitir este correo electrónico y añadir la dirección IP del remitente a la lista de permitidos",
          },
          discardEmailsFromSenderIp: {
            title:
              "Añadir la dirección IP del remitente a la lista de bloqueados y eliminar permanentemente este y todos los correos electrónicos futuros de esta dirección IP",
          },
        },
        [ProxySecurityMode.WARNING]: {
          approveEmailsFromSender: {
            title:
              "Añadir la dirección de correo electrónico del remitente a la lista de permitidos",
          },
          approveEmailsFromDomain: {
            title: "Añadir el dominio del remitente a la lista de permitidos",
          },
          discardEmailsFromSender: {
            title:
              "Bloquear la dirección de correo electrónico del remitente y bloquear todos los correos electrónicos futuros de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Bloquear el dominio del remitente y bloquear todos los correos electrónicos futuros de este dominio",
          },
          approveEmailsFromSenderIp: {
            title: "Añadir la dirección IP del remitente a la lista de permitidos",
          },
          discardEmailsFromSenderIp: {
            title:
              "Bloquear la dirección IP del remitente y bloquear todos los correos electrónicos futuros de esta dirección IP",
          },
          approveEmail: {
            title: "Permitir solo este correo electrónico",
          },
          discardEmail: {
            title: "Eliminar permanentemente solo este correo electrónico",
          },
        },
        [ProxySecurityMode.BLOCK]: {
          approveEmailsFromSender: {
            title:
              "Permitir este correo electrónico y añadir la dirección de correo electrónico del remitente a la lista de permitidos",
          },
          approveEmailsFromDomain: {
            title:
              "Permitir este correo electrónico y añadir el dominio del remitente a la lista de permitidos",
          },
          discardEmailsFromSender: {
            title:
              "Añadir la dirección de correo electrónico del remitente a la lista de bloqueados y eliminar permanentemente este y todos los correos electrónicos futuros de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Añadir el dominio del remitente a la lista de bloqueados y eliminar permanentemente este y todos los correos electrónicos futuros de este dominio",
          },
          approveEmailsFromSenderIp: {
            title:
              "Permitir este correo electrónico y añadir la dirección IP del remitente a la lista de permitidos",
          },
          discardEmailsFromSenderIp: {
            title:
              "Añadir la dirección IP del remitente a la lista de bloqueados y eliminar permanentemente este y todos los correos electrónicos futuros de esta dirección IP",
          },
          approveEmail: {
            title: "Permitir solo este correo electrónico",
          },
          discardEmail: {
            title: "Eliminar permanentemente solo este correo electrónico",
          },
        },
      },
      proxyActions: {
        [ProxySecurityMode.WARNING]: {
          approveEmailsFromSender: {
            title:
              "Añadir la dirección de correo electrónico del remitente a la lista de permitidos",
          },
          approveEmailsFromDomain: {
            title: "Añadir el dominio del remitente a la lista de permitidos",
          },
          discardEmailsFromSender: {
            title:
              "Bloquear la dirección de correo electrónico del remitente y bloquear todos los correos electrónicos futuros de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Bloquear el dominio del remitente y bloquear todos los correos electrónicos futuros de este dominio",
          },
          approveEmailsFromSenderIp: {
            title: "Añadir la dirección IP del remitente a la lista de permitidos",
          },
          discardEmailsFromSenderIp: {
            title:
              "Bloquear la dirección IP del remitente y bloquear todos los correos electrónicos futuros de esta dirección IP",
          },
          approveEmail: {
            title: "Permitir solo este correo electrónico",
          },
          discardEmail: {
            title: "Eliminar permanentemente solo este correo electrónico",
          },
        },
        [ProxySecurityMode.BLOCK]: {
          approveEmailsFromSender: {
            title:
              "Permitir este correo electrónico y añadir la dirección de correo electrónico del remitente a la lista de permitidos",
          },
          approveEmailsFromDomain: {
            title:
              "Permitir este correo electrónico y añadir el dominio del remitente a la lista de permitidos",
          },
          discardEmailsFromSender: {
            title:
              "Añadir la dirección de correo electrónico del remitente a la lista de bloqueados y eliminar permanentemente este y todos los correos electrónicos futuros de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Añadir el dominio del remitente a la lista de bloqueados y eliminar permanentemente este y todos los correos electrónicos futuros de este dominio",
          },
          approveEmailsFromSenderIp: {
            title:
              "Permitir este correo electrónico y añadir la dirección IP del remitente a la lista de permitidos",
          },
          discardEmailsFromSenderIp: {
            title:
              "Añadir la dirección IP del remitente a la lista de bloqueados y eliminar permanentemente este y todos los correos electrónicos futuros de esta dirección IP",
          },
          approveEmail: {
            title: "Permitir solo este correo electrónico",
          },
          discardEmail: {
            title: "Eliminar permanentemente solo este correo electrónico",
          },
        },
      },
      malwareActions: {
        default: {
          discardEmailsFromSender: {
            title:
              "Bloquear la dirección de correo electrónico del remitente y eliminar permanentemente todos los correos electrónicos futuros de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Bloquear el dominio del remitente y eliminar permanentemente todos los correos electrónicos futuros de este dominio",
          },
          discardEmail: {
            title: "Eliminar permanentemente solo este correo electrónico",
          },
          approveEmailsFromSender: {
            title:
              "Añadir la dirección de correo electrónico del remitente a la lista de permitidos",
          },
          approveEmailsFromDomain: {
            title: "Añadir el dominio del remitente a la lista de permitidos",
          },
          approveEmailsFromSenderIp: {
            title: "Añadir la dirección IP del remitente a la lista de permitidos",
          },
          discardEmailsFromSenderIp: {
            title:
              "Añadir la dirección IP del remitente a la lista de bloqueados y eliminar permanentemente todos los correos electrónicos futuros de esta dirección IP",
          },
          approveEmail: {
            title: "Permitir solo este correo electrónico",
          },
        },
        [ProxySecurityMode.WARNING]: {
          approveEmailsFromSender: {
            title:
              "Permitir este correo electrónico y añadir la dirección de correo electrónico del remitente a la lista de permitidos",
          },
          approveEmailsFromDomain: {
            title:
              "Permitir este correo electrónico y añadir el dominio del remitente a la lista de permitidos",
          },
          discardEmailsFromSender: {
            title:
              "Añadir la dirección de correo electrónico del remitente a la lista de bloqueados y eliminar permanentemente este y todos los correos electrónicos futuros de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Añadir el dominio del remitente a la lista de bloqueados y eliminar permanentemente este y todos los correos electrónicos futuros de este dominio",
          },
          approveEmailsFromSenderIp: {
            title:
              "Permitir este correo electrónico y añadir la dirección IP del remitente a la lista de permitidos",
          },
          discardEmailsFromSenderIp: {
            title:
              "Añadir la dirección IP del remitente a la lista de bloqueados y eliminar permanentemente este y todos los correos electrónicos futuros de esta dirección IP",
          },
          approveEmail: {
            title: "Permitir solo este correo electrónico",
          },
          discardEmail: {
            title: "Eliminar permanentemente solo este correo electrónico",
          },
        },
        [ProxySecurityMode.BLOCK]: {
          approveEmailsFromSender: {
            title:
              "Permitir este correo electrónico y añadir la dirección de correo electrónico del remitente a la lista de permitidos",
          },
          approveEmailsFromDomain: {
            title:
              "Permitir este correo electrónico y añadir el dominio del remitente a la lista de permitidos",
          },
          discardEmailsFromSender: {
            title:
              "Añadir la dirección de correo electrónico del remitente a la lista de bloqueados y eliminar permanentemente este y todos los correos electrónicos futuros de este remitente",
          },
          discardEmailsFromDomain: {
            title:
              "Añadir el dominio del remitente a la lista de bloqueados y eliminar permanentemente este y todos los correos electrónicos futuros de este dominio",
          },
          approveEmailsFromSenderIp: {
            title:
              "Permitir este correo electrónico y añadir la dirección IP del remitente a la lista de permitidos",
          },
          discardEmailsFromSenderIp: {
            title:
              "Añadir la dirección IP del remitente a la lista de bloqueados y eliminar permanentemente este y todos los correos electrónicos futuros de esta dirección IP",
          },
          approveEmail: {
            title: "Permitir solo este correo electrónico",
          },
          discardEmail: {
            title: "Eliminar permanentemente solo este correo electrónico",
          },
        },
      },
    },
    [EmailSettingsAction.ADD_SECURITY_AWARENESS_CONFIGURATION]: {
      addHeaderBtn: "+ Añadir nuevo encabezado",
      actionBtn: "Añadir a la lista",
      title: "Añadir a la lista de permitidos",
      sectionsTitles: {
        details: "Información",
        emailAndDomain: "Dominios, IP o rangos de IP",
        emailHeader: "Encabezado del correo electrónico",
      },
      labels: {
        name: "Nombre",
        emailAndDomain: "Introduzca dominios o direcciones IP...",
        value: "Valor",
      },
      validations: {
        atLeastOneHeaderOrTermIsRequired: "At least one email header or domain or IP is required",
      },
    },
    [VirtualOfficeAction.GET_VPN_MOBILE_APP]: {
      title: "Coro VPN mobile app",
      description:
        "The Coro VPN mobile app is designed for end-users to install on their devices. The links below provide access to the respective app stores for downloading the app directly to their devices.",
      osTypes: {
        android: "Share download link for Android devices",
        ios: "Share download link for iOS devices",
      },
    },
    [EmailSettingsAction.EDIT_SECURITY_AWARENESS_CONFIGURATION]: {
      title: "Edit configuration",
      actionBtn: "Save",
    },
    [EmailSettingsAction.REMOVE_SECURITY_AWARENESS_CONFIGURATION]: {
      title: "Remove configuration?",
      description: "This action is irreversible.",
      actionBtn: "Yes, remove",
    },
  },
  accessControlPage: {
    title: "Control de acceso",
    adminUsers: {
      title: "Administradores",
      addAdmin: "Añadir administrador",
      grantLoginAccess:
        "Dar acceso de inicio de sesión a los agentes de asistencia técnica de Coro",
      requireMFA: "Pedir la autenticación de dos pasos a todos los administradores",
      table: {
        headers: {
          name: "Nombre",
          email: "Correo electrónico",
          status: "Estado",
          mfa: "2FA",
          role: "Función",
        },
        actions: {
          [AdminUsersAction.CONTENT_INSPECTION]: "Permisos de inspección de contenido",
          [AdminUsersAction.EDIT]: "Editar administrador",
          [AdminUsersAction.DELETE]: "Eliminar administrador",
          [AdminUsersAction.RESEND_INVITE]: "Reenviar invitación",
          [AdminUsersAction.DELETE_MFA]: "Eliminar datos de 2FA",
          [AdminUsersAction.COPY_INVITE_LINK]: "Copiar enlace de invitación",
        },
        you: "Usted",
      },
      status: {
        active: "Activo",
        invited: "Invitación del {date}",
      },
      mfaStatus: {
        none: "Deshabilitado",
        totp: "Habilitado",
      },
      details: {
        title: "{name}: permisos de inspección de contenido",
        description:
          "Utilice esta página para habilitar los permisos para el administrador designado pueda ver el contenido y los resultados en correos electrónicos marcados para usuarios protegidos. Tenga en cuenta que esto puede exponer información comercial y privada confidencial, o contenido protegido por ley, políticas u obligaciones contractuales.",
        connectMore: "Conectar más servicios en la nube ›",
        connectMoreProxies: "Conectar más servidores proxy de correo electrónico ›",
        noItems: {
          connectBtn: "Conectar aplicaciones en la nube",
          title: "Primero tiene que conectarse a los servicios en la nube que quiere proteger",
          description:
            "Cuando se conecte, configure los permisos de inspección de contenido para correos electrónicos y archivos sospechosos.",
        },
        noCloudServicesConnected: "No hay servicios conectados en la nube",
        noProxyConnected: "No hay proxies conectados",
      },
    },
    roles: {
      title: "Funciones",
      table: {
        headers: {
          role: "Función",
          assigneesCount: "N.º de usuarios",
        },
        actions: {
          [RolesAction.ADD]: "Añadir función",
          [RolesAction.EDIT]: "Editar función",
          [RolesAction.DUPLICATE]: "Función duplicada",
          [RolesAction.DELETE]: "Eliminar función",
        },
      },
      scopes: {
        [GlobalRoleScopes.GLOBAL_SCOPE]: {
          title: "Gestión global de Coro",
          [GlobalRoleScopeSection.GLOBAL_ADMIN_USERS]: {
            title: "Administradores globales",
            editAccessModePermission: {
              add: "Añadir administradores globales",
              remove: "Eliminar administradores globales",
              assignRoles: "Asignar funciones a administradores globales",
            },
          },
          [GlobalRoleScopeSection.GLOBAL_ROLES]: {
            title: "Funciones globales",
            editAccessModePermission: {
              add: "Añadir o duplicar funciones globales",
              edit: "Editar funciones globales",
              remove: "Eliminar funciones globales",
            },
          },
          [GlobalRoleScopeSection.SOC_PORTAL]: {
            title: "Portal de SOC",
            editAccessModePermission: {
              commentTickets: "Comentar tickets",
              [TicketActionSeverity.LOW]: "Acciones de impacto bajo",
              [TicketActionSeverity.MEDIUM]: "Acciones de impacto medio",
              [TicketActionSeverity.CRITICAL]: "Acciones de impacto crítico",
              tier1Statuses: "Estados de nivel 1",
              tier2Statuses: "Estados de nivel 2",
            },
          },
          [GlobalRoleScopeSection.WORKSPACE_SUBSCRIPTION_TYPE_ACCESS]: {
            title: "Acceso al Workspace por suscripción",
            editAccessModePermission: {
              prospects: "Prospectos (nuevos, inactivos, en periodo de prueba, archivados)",
              customers: "Clientes (suscripción)",
            },
          },
          [GlobalRoleScopeSection.WORKSPACE_TYPE_ACCESS]: {
            title: "Acceso al Workspace por tipo",
            editAccessModePermission: {
              regular: "Workspaces regulares",
              channel: "Workspaces de canal",
              child: "Workspaces secundarios",
            },
          },
          [GlobalRoleScopeSection.SPECIAL_PERMISSIONS]: {
            title: "Permisos específicos",
            editAccessModePermission: {
              collectLogs: "Recopilar registros",
              collectQuarantineData: "Recopilar datos en cuarentena",
              showAdvancedDeviceInformation: "Mostrar información avanzada de los dispositivos",
              backOffice: "Acceso a la parte administrativa",
              manageEndpointAutoUpdateGroups:
                "Administrar grupos de actualización automática de puntos finales",
              manageWorkspaceCodes: "Administrar códigos de Workspace",
              demoMode: "Modo de prueba",
            },
          },
        },
        [MspRolePermissionScopes.MSP_PORTAL_SCOPE]: {
          title: "Gestión de MSP",
          [MspPortalScopeSection.MSP_WORKSPACES]: {
            title: "Portal de MSP",
            editAccessModePermission: {
              editWorkspace: "Editar información del Workspace",
              startSubscription: "Iniciar suscripción",
              stopSubscription: "Cancelar suscripción",
              editSubscription: "Editar suscripción",
              createChildWorkspace: "Crear un Workspace secundario",
              createChannelWorkspace: "Crear un Workspace de canal",
              archiveWorkspace: "Archivar Workspace",
              generateMspExport: "Generar exportación de MSP",
              generateMspSummaryReport: "Generar informe del resumen de MSP",
              exportMspNotifications: "Exportar notificaciones de MSP",
              extendTrial: "Ampliar el periodo de prueba",
              convertWorkspace: "Convertir Workspace",
              restoreWorkspace: "Restaurar Workspace",
              createRegularWorkspace: "Crear un Workspace regular",
            },
          },
          [MspPortalScopeSection.MSP_ADMIN_USERS]: {
            title: "Administradores de MSP",
            editAccessModePermission: {
              add: "Añadir administradores de MSP",
              remove: "Eliminar administradores de MSP",
              assignRoles: "Asignar funciones a administradores de MSP",
            },
          },
          [MspPortalScopeSection.MSP_ROLES]: {
            title: "Funciones de MSP",
            editAccessModePermission: {
              add: "Añadir o duplicar funciones de MSP",
              edit: "Editar funciones de MSP",
              remove: "Eliminar funciones de MSP",
            },
          },
        },
        [RolePermissionsScope.WORKSPACE_MANAGEMENT]: {
          title: "Gestión",
          [WorkspaceManagementScopeSections.USERS]: "Usuarios",
          [WorkspaceManagementScopeSections.DEVICES]: "Dispositivos",
          [WorkspaceManagementScopeSections.CLOUD_APPS]: "Aplicaciones en la nube",
          [WorkspaceManagementScopeSections.ACTIVE_SESSIONS]: "Sesiones activas",
          [WorkspaceManagementScopeSections.ACTIVITY_LOGS]: "Registros de actividad",
          [WorkspaceManagementScopeSections.CONNECTORS]: "Conexiones",
          [WorkspaceManagementScopeSections.ROLES]: "Funciones",
          [WorkspaceManagementScopeSections.REPORTS]: "Informes",
          [WorkspaceManagementScopeSections.ADMIN_USERS]: {
            title: "Administradores",
            editAccessModePermission: {
              add: "Añadir",
              edit: "Editar",
              remove: "Eliminar",
              removeMfaData: "Eliminar datos de 2FA",
              assignRoles: "Asignar funciones",
              manageContentPermissions: "Gestionar permisos de contenido",
            },
          },
        },
        [RolePermissionsScope.VIEWS]: {
          title: "Vistas",
          usersView: "Usuarios",
          devicesView: "Dispositivos",
        },
        [RolePermissionsScope.PROTECTION]: {
          title: "Protección",
          [SubscriptionModule.CLOUD_SECURITY]: "Cloud Security",
          [SubscriptionModule.ENDPOINT_SECURITY]: "Endpoint Security",
          [SubscriptionModule.EMAIL_SECURITY]: "Email Security",
          [SubscriptionModule.USER_DATA_GOVERNANCE]: "User Data Governance",
          [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: "Endpoint Data Governance",
          [SubscriptionAddon.INBOUND_GATEWAY]: "Inbound Gateway",
          [SubscriptionModule.EDR]: "EDR",
          [SubscriptionModule.NETWORK]: "Network",
          [ProtectionScopeModules.MANAGED_SOC]: "Managed Service",
          [SubscriptionModule.MDM]: "MDM",
          [SubscriptionAddon.SWG]: "Secure Web Gateway",
          [SubscriptionAddon.WIFI_PHISHING]: "WiFi Phishing",
          [SubscriptionAddon.SECURED_MESSAGES]: "Secured Messages",
        },
        [RolePermissionsScope.TICKETS]: {
          title: "Tickets",
          [SubscriptionModule.CLOUD_SECURITY]: {
            title: "Cloud Security",
            editAccessModePermission: {
              commentTickets: "Comentar tickets",
              [TicketActionSeverity.LOW]: "Acciones de impacto bajo",
              [TicketActionSeverity.MEDIUM]: "Acciones de impacto medio",
              [TicketActionSeverity.CRITICAL]: "Acciones de impacto crítico",
            },
          },
          [SubscriptionModule.ENDPOINT_SECURITY]: {
            title: "Endpoint Security",
            editAccessModePermission: {
              commentTickets: "Comentar tickets",
              [TicketActionSeverity.LOW]: "Acciones de impacto bajo",
              [TicketActionSeverity.MEDIUM]: "Acciones de impacto medio",
              [TicketActionSeverity.CRITICAL]: "Acciones de impacto crítico",
            },
          },
          [SubscriptionModule.EMAIL_SECURITY]: {
            title: "Email Security",
            editAccessModePermission: {
              commentTickets: "Comentar tickets",
              [TicketActionSeverity.LOW]: "Acciones de impacto bajo",
              [TicketActionSeverity.MEDIUM]: "Acciones de impacto medio",
              [TicketActionSeverity.CRITICAL]: "Acciones de impacto crítico",
            },
          },
          [SubscriptionModule.USER_DATA_GOVERNANCE]: {
            title: "User Data Governance",
            editAccessModePermission: {
              commentTickets: "Comentar tickets",
              [TicketActionSeverity.LOW]: "Acciones de impacto bajo",
              [TicketActionSeverity.MEDIUM]: "Acciones de impacto medio",
              [TicketActionSeverity.CRITICAL]: "Acciones de impacto crítico",
            },
          },
          [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: {
            title: "Endpoint Data Governance",
            editAccessModePermission: {
              commentTickets: "Comentar tickets",
              [TicketActionSeverity.LOW]: "Acciones de impacto bajo",
              [TicketActionSeverity.MEDIUM]: "Acciones de impacto medio",
              [TicketActionSeverity.CRITICAL]: "Acciones de impacto crítico",
            },
          },
          [SubscriptionModule.EDR]: {
            title: "EDR",
            editAccessModePermission: {
              commentTickets: "Comentar tickets",
              [TicketActionSeverity.LOW]: "Acciones de impacto bajo",
              [TicketActionSeverity.MEDIUM]: "Acciones de impacto medio",
              [TicketActionSeverity.CRITICAL]: "Acciones de impacto crítico",
            },
          },
        },
      },
      details: {
        adminUsers: "Administradores",
        permissions: "Permisos",
        permissionModes: {
          [RolePermissionAccessMode.EDIT]: "Puede editar",
          [RolePermissionAccessMode.VIEW]: "Puede ver",
          [RolePermissionAccessMode.NO_ACCESS]: "Sin acceso",
        },
      },
    },
    activeSessions: {
      title: "Sesiones activas",
      description:
        "Estos dispositivos han iniciado sesión en el sistema como administradores. Anule las sesiones que no reconozca.",
      table: {
        headers: {
          ip: "Direcciones IP",
          user: "Usuario",
          country: "País",
          signedIn: "Sesión iniciada",
          lastAccessed: "Se ha accedido por última vez",
        },
        revoke: "Anular",
        currentSession: "Sesión actual",
      },
    },
  },
  soc: {
    title: "Servicio gestionado",
    tabs: {
      remediation: "Corrección",
      contacts: "Contactos",
    },
    contactsTab: {
      addBtn: "Añadir contacto del servicio gestionado",
      emptyState: {
        description: "Actualmente no tiene contactos del servicio gestionado",
        subtitle:
          "Coro necesita conocer los principales puntos de contacto en su empresa para todos los problemas del centro de operaciones de seguridad (servicio gestionado).",
      },
      table: {
        email: "Correo electrónico",
        name: "Nombre",
        notifications: "Notificaciones",
      },
      actions: {
        [SocContactsActions.EDIT]: "Editar",
        [SocContactsActions.REMOVE]: "Eliminar",
      },
      notificationType: {
        email: "Correo electrónico",
        emailCriticalIssues: "Correo electrónico: problemas críticos",
        doNotNotify: "No notificar",
      },
    },
    remediationTab: {
      title: "Elija cómo quiere gestionar la corrección",
      [SocRemediationType.REMEDIATE_MYSELF]:
        "Corregir los problemas por mi cuenta siguiendo las recomendaciones del equipo del servicio gestionado de Coro",
      remediateMyselfSubtitle:
        "Coro avisará sobre las recomendaciones a sus contactos del servicio gestionado de acuerdo con el nivel de notificación especificado.",
      [SocRemediationType.REMEDIATE_BY_CORO_SOC]:
        "Permitir que el equipo del servicio gestionado de Coro solucione los problemas de forma remota en mi nombre",
      remediateByCoroSocSubtitle:
        "Coro avisará a sus contactos del servicio gestionado sobre las medidas adoptadas de acuerdo con el nivel de notificación especificado.",
    },
  },
  endpointDataGovernance: {
    title: "Endpoint Data Governance",
    privacySensitiveDataTab: {
      title: "Datos confidenciales de privacidad",
      subtitle: "Seleccione los tipos que quiere analizar",
      monitorPII: "Información identificable personal (PII)",
      monitorPCI: "Información de las tarjetas de pago (PCI)",
      monitorPHI: "Información sanitaria protegida (PHI)",
      monitorNPI: "Información no pública (NPI)",
    },
    sensitiveDataScansTab: {
      title: "Análisis para buscar datos confidenciales",
      addBtn: "Añadir programa",
      emptyState: {
        description:
          "Actualmente no tiene programado ningún análisis para buscar datos confidenciales.",
        subtitle:
          "Busca e identifica posibles violaciones relacionadas con el almacenamiento de datos confidenciales en unidades de dispositivos terminales.",
      },
      table: {
        os: "SO",
        action: "Acción",
        drives: "Unidades",
        labels: "Etiquetas",
        appliedDriveTypes: {
          ALL: "Todo",
          UNENCRYPTED: "Sin cifrar",
        },
        actions: {
          [SensitiveDataScansActions.DELETE]: "Eliminar",
          [SensitiveDataScansActions.EDIT]: "Editar",
        },
      },
    },
  },
  cloudSecurity: {
    title: "Cloud Security",
    description: "Supervise y controle el acceso a aplicaciones y a datos en la nube",
    accessPermissions: "Permisos de acceso",
    noItems: {
      title: "Añada sus aplicaciones en la nube a la protección de Coro",
      description:
        "Supervise las cuentas de sus aplicaciones empresariales en la nube para proteger su entorno y cumplir \ncon las normas de privacidad.",
      connectBtn: "Conectar aplicaciones en la nube",
      modalDescription: "Seleccione la aplicación en la nube que quiere conectar",
    },
    tabs: {
      accessPermission: "Permiso de acceso",
      thirdPartyApps: "Aplicaciones de terceros",
      settings: "Ajustes",
    },
  },
  cloudSecuritySettings: {
    inactiveUsers: "Usuarios inactivos",
    default: "Predeterminado",
    custom: "Personalizado",
    numberOfDays: "Número de días",
    inactiveUsersSubtitle:
      "De forma predeterminada, los usuarios pasan a estar inactivos después de no usar una aplicación en la nube durante 30 días. Puede personalizar el número de días que pasan antes de que un usuario pase a estar inactivo.",
  },
  userDataGovernanceSettings: {
    title: "User Data Governance",
    description:
      "Supervisión y control del acceso a datos confidenciales y sensibles según la normativa",
    noEmailServicesConnected:
      "Conecte <b>Microsoft 365</b> o <b>G-Suite</b> de su empresa a Coro para permitir una gobernanza de datos eficaz y una protección frente a la pérdida de datos",
    connectCloudAppsBtn: "Conectar aplicaciones en la nube",
    addPermission: "Añadir permiso",
    tabs: {
      monitoring: "Supervisión",
      permissions: "Permisos",
      exclusions: "Exclusiones",
      outboundGateway: "Outbound Gateway",
    },
    dataLossModals: {
      setPermissions: "Aplicar la política a estos usuarios o dominios",
      selectDataType: "Seleccionar tipo de datos",
      dataTypes: {
        [TicketTrigger.DLP_PCI]: "Información de las tarjetas de pago (PCI)",
        [TicketTrigger.DLP_PHI]: "Información sanitaria protegida (PHI)",
        [TicketTrigger.DLP_PII]: "Información identificable personal (PII)",
        [TicketTrigger.DLP_NPI]: "Información no pública (NPI)",
        [TicketTrigger.CRITICAL_DATA_PASSWORD]: "Contraseñas",
        [TicketTrigger.CRITICAL_DATA_CERTIFICATE]: "Certificados",
        [TicketTrigger.CRITICAL_DATA_SOURCE_CODE]: "Código fuente",
        [TicketTrigger.CRITICAL_DATA_KEYWORDS]: "Palabras clave confidenciales",
        [TicketTrigger.CRITICAL_DATA_FILE_TYPES]: "Tipos de archivos confidenciales",
      },
    },
    permissionsTab: {
      usersAndGroups: "Usuarios/grupos",
      permission: "Permiso",
      allUsersPolicy: "Todos los usuarios del Workspace",
      dlpPci: "Información de las tarjetas de pago (PCI)",
      dlpPhi: "Información sanitaria protegida (PHI)",
      dlpPii: "Información identificable personal (PII)",
      dlpNpi: "Información no pública (NPI)",
      criticalDataPassword: "Contraseñas",
      criticalDataCertificate: "Certificados",
      criticalDataSourceCode: "Código fuente",
      criticalDataKeywords: "Objetos de datos con palabras clave específicas",
      criticalDataFileTypes: "Tipos de archivos confidenciales",
      addPermissionOptions: {
        addPermissionForAllUsers: "Todos los usuarios",
        addPermissionForSpecificGroups: "Grupos específicos",
        addPermissionForSpecificUsers: "Usuarios o dominios específicos",
      },
      actions: {
        accessOnly: "Puede acceder",
        accessAndExposure: "Puede acceder y exponer",
        remove: "Eliminar permiso",
      },
      noPermissionsDescription: "Actualmente no tiene permisos específicos sobre los datos",
    },
    monitoringTab: {
      dataPossession: "Posesión de datos",
      dataExposure: "Exposición de datos",
      dataExposureDescription:
        "Tipos de datos confidenciales para supervisar el acceso y la exposición en el intercambio de datos de unidades de correo electrónico y en la nube.",
      dataPossessionDescription:
        "Tipos de datos confidenciales para buscar en análisis iniciados de forma remota de las unidades de los dispositivos terminal.",
      monitorPII: "Información identificable personal (PII)",
      monitorPCI: "Información de las tarjetas de pago (PCI)",
      monitorPHI: "Información sanitaria protegida (PHI) ",
      monitorNPI: "Información no pública (NPI)",
      monitorCustomDataEntries: "Entradas de datos personalizadas",
      excludeEmailScans: "Excluir análisis de correos electrónicos salientes",
      keywordsTitle: "Palabras clave específicas en el asunto",
      keywordsPlaceholder: "Introduzca palabras clave como «keyword1» y «keyword2»",
      passwords: "Contraseñas",
      certificates: "Certificados",
      sourceCode: "Código fuente",
      dataObjectsWithSpecificKeywords: "Objetos de datos con palabras clave específicas",
      specificKeywordsPlaceholder: "Introduzca palabras clave como «keyword1» y «keyword2»",
      specificFileTypesTitle: "Tipos de archivos específicos",
      specificFileTypesPlaceholder: "png, psd, docx…",
      privacySensitiveData: "Datos confidenciales de privacidad",
      securityAndBusinessData: "Seguridad y datos comerciales confidenciales",
    },
    exclusionsTab: {
      title:
        "Configure los datos que deban excluirse de los análisis para buscar datos confidenciales",
      excludeEmailsWithKeywords:
        "Excluya correos electrónicos con palabras clave específicas en el asunto",
      specificKeywordsPlaceholder: "Introduzca palabras clave como «keyword1» y «keyword2»",
    },
  },
  myAccount: {
    tabs: {
      profile: "Perfil",
      password: "Contraseña",
      mfa: "Autenticación de dos pasos",
      notifications: "Notificaciones",
    },
    profile: {
      title: "Información del perfil",
      language: "Idioma",
      firstName: {
        label: "Nombre",
      },
      lastName: {
        label: "Apellido",
      },
      email: {
        label: "Correo electrónico",
        hint: "No se puede actualizar su correo electrónico",
      },
      updateProfile: "Actualizar perfil",
    },
    password: {
      title: "Contraseña",
      password: {
        label: "Contraseña",
      },
      oldPassword: {
        label: "Contraseña anterior",
      },
      newPassword: {
        label: "Nueva contraseña",
      },
      setPassword: "Establecer contraseña",
      changePassword: "Cambiar contraseña",
      warningMessage:
        "Aún no ha establecido ninguna contraseña. La contraseña establecida se aplicará a todos los Workspaces de los que forme parte.",
      successMessage: "Su contraseña se ha actualizado.",
      passwordSentMessage:
        "Le hemos enviado un correo electrónico con instrucciones para restablecer la contraseña.",
      forgotPasswordBtn: "¿Ha olvidado la contraseña?",
    },
    notifications: {
      generalNotifications: "Notificaciones generales",
      dailyEmailUpdates: "Actualizaciones del estado de Workspace (recomendado)",
      criticalTickets: "Tickets críticos que necesitan una acción a tiempo (recomendado)",
      releaseNotes: "Notas de la versión",
      productAnnouncements: "Anuncios de productos",
      specificNotifications: "Notificaciones de tickets específicos",
      cloudSecurity: "Cloud Security",
      endpointSecurity: "Endpoint Security",
      emailSecurity: "Email Security",
      userDataGovernance: "User Data Governance",
      endpointDataGovernance: "Endpoint Data Governance",
      edr: "EDR",
    },
    mfa: {
      title: "Autenticación de dos pasos",
      notice:
        "La actualización de su autenticación de dos pasos aquí también se aplicará a todos los Workspaces de los que forme parte.",
      mfaEnabled: "La autenticación de dos pasos está habilitada",
      mfaEnabledDesc:
        "Ha habilitado la autenticación de dos pasos para su cuenta mediante una aplicación de autenticación.",
      mfaDisabled: "La autenticación de dos pasos está deshabilitada",
      recoveryMobile: "Su número de teléfono móvil de recuperación",
      disableMFAButton: "Eliminar los datos de la autenticación de dos pasos",
      fallbackTitle: "Habilitar el método de recuperación alternativo",
      fallbackDesc:
        "Introduzca un número de teléfono en caso de que no pueda autenticarse y necesite recuperar su código por SMS.",
      fallbackCodeTitle: "Introduzca el código de 6 dígitos que ha recibido en su teléfono",
      finishSetupBtn: "Finalizar configuración",
      smsSent: "SMS enviado",
    },
  },
  tickets: {
    eventTypes: {
      [TicketType.ABNORMAL_ADMIN_ACTIVITY]: "Actividad administrativa anormal",
      [TicketType.MALWARE_IN_EMAIL_ATTACHMENTS]:
        "Malware en archivos adjuntos del correo electrónico",
      [TicketType.SUSPECTED_IDENTITY_COMPROMISE]: "Sospecha de identidad comprometida",
      [TicketType.MALWARE_IN_CLOUD_DRIVE]: "Malware en la unidad de la nube",
      [TicketType.ACCESS_PERMISSIONS_VIOLATION]: "Violación de los permisos de acceso",
      [TicketType.MALWARE_IN_DEVICE]: "Malware en el terminal",
      [TicketType.ENDPOINT_VULNERABILITY]: "Vulnerabilidad del terminal",
      [TicketType.EMAIL_PHISHING]: "Phishing por correo electrónico [obsoleto]",
      [TicketType.WIFI_PHISHING]: "Phishing por wifi",
      [TicketType.WIFI_FORBIDDEN_NETWORK]: "Red wifi prohibida",
      [TicketType.ABNORMAL_DATA_MANIPULATION]: "Manipulación anormal de datos",
      [TicketType.SUSPECTED_BOT_ATTACKS]: "Sospecha de ataques de bots",
      [TicketType.SUSPECTED_DATA_EXPOSURE_BY_EMAIL]:
        "Sospecha de exposición de datos críticos por correo electrónico",
      [TicketType.SUSPECTED_DATA_EXPOSURE_BY_SHARING]:
        "Sospecha de exposición de datos críticos por recursos compartidos",
      [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_EMAIL]:
        "Sospecha de violación de cumplimiento de datos por correo electrónico",
      [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_SHARING]:
        "Sospecha de violación de cumplimiento de datos por recursos compartidos",
      [TicketType.SUSPECTED_COMPLIANCE_VIOLATION_BY_DEVICE]:
        "Unidad terminal con datos confidenciales",
    },
    eventTriggers: {
      [TicketTrigger.CRITICAL_DATA_FILE_TYPES]: "Exposición sospechosa del tipo de archivo",
      [TicketTrigger.CRITICAL_DATA_SOURCE_CODE]: "Exposición sospechosa del tipo de archivo",
      [TicketTrigger.CRITICAL_DATA_PASSWORD]: "Exposición sospechosa de la contraseña",
      [TicketTrigger.CRITICAL_DATA_CERTIFICATE]: "Exposición sospechosa del certificado",
      [TicketTrigger.DLP_PII]: "PII (información identificable personal)",
      [TicketTrigger.DLP_PCI]: "PCI (información de las tarjetas de pago)",
      [TicketTrigger.DLP_PHI]: "PHI (información sanitaria protegida)",
      [TicketTrigger.DLP_NPI]: "NPI (información no pública)",
      [TicketTrigger.DEVICE_DLP_PII]: "Unidad terminal con PII",
      [TicketTrigger.DEVICE_DLP_PCI]: "Unidad terminal con PCI",
      [TicketTrigger.DEVICE_DLP_PHI]: "Unidad terminal con PHI",
      [TicketTrigger.DEVICE_DLP_NPI]: "Unidad terminal con NPI",
      [TicketTrigger.CRITICAL_DATA_KEYWORDS]: "Exposición sospechosa de datos críticos",
      [TicketTrigger.ENCRYPTION_DISABLED]: "Unidad terminal sin cifrar",
      [TicketTrigger.APPS_FROM_UNKNOWN_SOURCES]: "Aplicaciones de fuentes desconocidas",
      [TicketTrigger.ANTI_VIRUS_DISABLED]: "Antivirus deshabilitado",
      [TicketTrigger.DEVICE_PASSWORD_MISSING]: "Falta la contraseña del dispositivo",
      [TicketTrigger.DEVELOPMENT_MODE_ENABLED]: "Modo desarrollo habilitado",
      [TicketTrigger.VSS_BACKUP_PROTECTION]: "Protección de la copia de seguridad de VSS",
      [TicketTrigger.MOCK_LOCATION_ENABLED]: "Ubicación simulada habilitada",
      [TicketTrigger.NON_GENUINE_WINDOWS]: "Copia de Windows no original",
      [TicketTrigger.FIREWALL_DISABLED]: "Cortafuegos deshabilitado",
      [TicketTrigger.UAC_NOTIFICATIONS_MISSING]: "Falta la notificación de UAC",
      [TicketTrigger.ACTIVEX_CODE_EXECUTION_ENABLED]: "Ejecución activa de código X habilitada",
      [TicketTrigger.REMOTE_CODE_EXECUTION_ENABLED]: "Protocolo SMB no seguro",
      [TicketTrigger.CRITICAL_UPDATES_NOT_INSTALLED]: "Faltan actualizaciones críticas de Windows",
      [TicketTrigger.EXECUTION_POLICY_ENABLED]: "Configuración de PowerShell no segura",
      [TicketTrigger.INFECTED_PROCESS]: "Proceso infectado",
      [TicketTrigger.ABNORMAL_ADMIN_ACTIVITY]: "Actividad administrativa anormal",
      [TicketTrigger.SUSPECTED_IDENTITY_COMPROMISE]: "Sospecha de identidad comprometida",
      [TicketTrigger.MALWARE_IN_CLOUD_DRIVE]: "Malware en la unidad de la nube",
      [TicketTrigger.EMAIL_PHISHING]: "Phishing por correo electrónico [obsoleto]",
      [TicketTrigger.MALWARE_IN_EMAIL_ATTACHMENTS]:
        "Malware en archivos adjuntos del correo electrónico",
      [TicketTrigger.ABNORMAL_DATA_MANIPULATION]: "Manipulación anormal de datos",
      [TicketTrigger.ACCESS_PERMISSIONS_VIOLATION]: "Violación de los permisos de acceso",
      [TicketTrigger.MALWARE_ON_ENDPOINT]: "Malware en el terminal",
      [TicketTrigger.SUSPECTED_BOT_ATTACKS]: "Sospecha de ataques de bots",
      [TicketTrigger.MASS_DELETE]: "Eliminación masiva de datos",
      [TicketTrigger.MASS_DOWNLOAD]: "Descarga masiva de datos",
      [TicketTrigger.WIFI_PHISHING]: "Phishing por wifi",
      [TicketTrigger.FORBIDDEN_NETWORK_CONNECTION]: "Conexión wifi prohibida",
      [TicketTrigger.GATEKEEPER_UNAVAILABLE]: "Gatekeeper deshabilitado",
      [TicketTrigger.APPLE_MOBILE_FILE_INTEGRITY_UNAVAILABLE]:
        "Integridad de archivos móviles de Apple deshabilitada",
      [TicketTrigger.SYSTEM_INTEGRITY_PROTECTION_UNAVAILABLE]:
        "Protección de integridad del sistema deshabilitada",
      [TicketTrigger.USB_LOCKDOWN]: "Bloqueo de USB",
      [TicketTrigger.BLOCKLISTED_SENDER]: "Remitente incluido en la lista de bloqueados",
      [TicketTrigger.BRAND_IMPERSONATION]: "Suplantación de la marca",
      [TicketTrigger.CROWDBLOCKED_SENDER]: "Remitente bloqueado repetidamente",
      [TicketTrigger.DOMAIN_IMPERSONATION]: "Suplantación del dominio",
      [TicketTrigger.FORBIDDEN_ATTACHMENT_TYPE]: "Tipo de archivo adjunto prohibido",
      [TicketTrigger.MISSING_REQUIRED_AUTHENTICATION]: "Falta la autenticación requerida",
      [TicketTrigger.SUSPICIOUS_CONTENT]: "Contenido sospechoso",
      [TicketTrigger.SPAM]: "Correo no deseado",
      [TicketTrigger.UNVERIFIED_SENDER]: "Remitente no verificado",
      [TicketTrigger.USER_IMPERSONATION]: "Suplantación del usuario",
      [TicketTrigger.REPORTED_BY_USER]: "Reportado por el usuario",
      [TicketTrigger.DOMAIN_SPOOFING]: "Suplantación del dominio",
      [TicketTrigger.EDR_DETECTION_SUSPICIOUS_PROCESS]: "Proceso sospechoso",
      [TicketTrigger.AUTOMATIC_UPDATE_DISABLED]: "Actualizaciones de Windows deshabilitadas",
      [TicketTrigger.EDR_DETECTION_COLLECTION]: "Recopilación",
      [TicketTrigger.EDR_DETECTION_COMMAND_AND_CONTROL]: "Comando y control",
      [TicketTrigger.EDR_DETECTION_CREDENTIAL_ACCESS]: "Acceso a credenciales",
      [TicketTrigger.EDR_DETECTION_DEFENCE_EVASION]: "Evasión de defensa",
      [TicketTrigger.EDR_DETECTION_EXECUTION]: "Ejecución",
      [TicketTrigger.EDR_DETECTION_DISCOVERY]: "Descubrimiento",
      [TicketTrigger.EDR_DETECTION_EXFILTRATION]: "Exfiltración",
      [TicketTrigger.EDR_DETECTION_IMPACT]: "Impacto",
      [TicketTrigger.EDR_DETECTION_INITIAL_ACCESS]: "Acceso inicial",
      [TicketTrigger.EDR_DETECTION_LATERAL_MOVEMENT]: "Movimiento lateral",
      [TicketTrigger.EDR_DETECTION_PERSISTENCE]: "Persistencia",
      [TicketTrigger.EDR_DETECTION_PRIVILEGE_ESCALATION]: "Aumento de privilegios",
      [TicketTrigger.EDR_DETECTION_RECONNAISSANCE]: "Reconocimiento",
      [TicketTrigger.EDR_DETECTION_RESOURCE_DEVELOPMENT]: "Desarrollo de recursos",
      [TicketTrigger.SUSPICIOUS_METADATA]: "Metadatos sospechosos",
      [TicketTrigger.USER_INACTIVITY]: "Usuario inactivo",
    },
    filters: {
      type: "Tipo",
      during: "Durante",
      status: "Estado",
      searchTickets: "Buscar tickets...",
      widgets: {
        placeholder: "En todas partes",
        deviceSecurityWidget: "Endpoint Security",
        cloudSecurity: "Cloud Security",
        emailsWidget: "Email Security",
        usersWidget: "Usuarios",
        userDataGovernance: "User Data Governance",
        endpointDataGovernance: "Endpoint Data Governance",
        edr: "EDR",
      },
    },
    socStatuses: {
      [SocStatus.NEW]: "Nuevo",
      [SocStatus.WIP_TIER1]: "WIP nivel 1",
      [SocStatus.WIP_TIER2]: "WIP nivel 2",
      [SocStatus.REQUIRES_TIER2]: "Nivel 2 obligatorio",
      [SocStatus.NEW_COMMENTS]: "Comentarios nuevos",
      closed: "Cerrado",
    },
  },
  workspaces: {
    title: "Seleccione el Workspace que quiere ver",
    tabs: {
      workplaces: "Workspaces",
      pendingInvitations: "Invitaciones pendientes",
    },
    tableHeaders: {
      id: "ID",
      name: "Nombre del Workspace",
    },
    selected: "Seleccionado",
    search: "Buscar Workspace",
    actions: {
      [WorkspaceAction.INVITE]: "Invitar",
      [WorkspaceAction.LAUNCH]: "Ver",
      [WorkspaceAction.JOIN]: "Unirse",
    },
    modals: {
      invite: {
        title: "Invitar usuario",
        label: "Escriba los usuarios que quiere invitar al Workspace.",
        actionButton: "Invitar usuarios",
      },
      selectWorkplace: {
        title: "Seleccione el Workspace que quiere ver",
        actionButton: "Iniciar",
      },
    },
  },
  reports: {
    title: "Informes",
    tabs: {
      reports: "Informes",
      exports: "Exportaciones",
      scheduled: "Programado",
    },
    executiveSummaryReport: {
      download: {
        title: "Descargar",
        toPdf: "PDF",
      },
      executiveSummary: "Resumen ejecutivo",
      version: "Versión",
      generatedOn: "Generado el:",
      topVulnerabilities: "Principales vulnerabilidades",
      protection: "Protección",
      ticketsByType: "Tickets por tipo",
      dateRange: "Rango de fechas",
      disabledModuleNotification: "Actualmente este módulo está deshabilitado",
      ticketsGenerated: "Tickets generados",
      users: {
        total: "Usuarios totales",
        protected: "Protegido",
        unprotected: "Desprotegido",
      },
      devices: {
        total: "Dispositivos cubiertos",
      },
      tickets: {
        total: "Tickets generados",
        processed: "Tickets cerrados",
        unprocessed: "Tickets abiertos",
      },
      [SubscriptionModule.USER_DATA_GOVERNANCE]: {
        title: "User Data Governance",
        description:
          "Análisis de anomalías para identificar y mitigar la exposición de datos confidenciales a través del correo electrónico y el intercambio de archivos, respetando las configuraciones del Workspace; incluye exposición de PHI, PCI, PII, NPI y de datos comerciales confidenciales.",
        topViolatorsTitle: "Principales infractores",
      },
      [SubscriptionModule.EMAIL_SECURITY]: {
        title: "Email Security",
        description:
          "Protección contra la suplantación de los dominios, los correos electrónicos engañosos y los archivos adjuntos maliciosos, garantizando un entorno seguro del correo electrónico.",
        topViolatorsTitle: "Principales correos electrónicos sospechosos",
      },
      [SubscriptionModule.CLOUD_SECURITY]: {
        title: "Cloud Security",
        description:
          "Detección y corrección avanzadas de malware, ransomware y acceso no autorizado en unidades de la nube. Los administradores pueden configurar barreras geográficas o de red, y el módulo optimiza la detección de incidentes en tiempo real a través de análisis de datos consolidados.",
        topViolatorsTitle: "Principales usuarios objetivo",
      },
      [SubscriptionModule.ENDPOINT_SECURITY]: {
        title: "Endpoint Security",
        description:
          "Protección en tiempo real contra malware y ransomware a través de un antivirus de nueva generación (NGAV). Gestión integral de funciones de seguridad críticas de dispositivos, incluidos análisis de unidades, actualizaciones de agentes, supervisión de actividad, configuraciones de cumplimiento personalizables, gestión del cortafuegos y notificaciones de control de cuentas de usuario (UAC).",
        topViolatorsTitle: "Principales dispositivos vulnerables",
      },
      [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: {
        title: "Endpoint Data Governance",
        description:
          "Análisis de terminales para buscar datos confidenciales según la configuración del Workspace; incluye exposición de PHI, PCI, PII, NPI y de datos comerciales confidenciales.",
        topViolatorsTitle: "Principales infractores",
      },
      [SubscriptionModule.EDR]: {
        title: "EDR",
        description:
          "Detección y respuesta a amenazas avanzadas dirigidas a dispositivos terminales, como actividad sospechosa, comportamiento malicioso y signos de compromiso.",
        topViolatorsTitle: "Principales procesos vulnerables",
      },
    },
    managedServicesSummaryReport: {
      title: "Resumen de los servicios gestionados",
      totalTicketsResolved: "Tickets resueltos totales",
      resolvedTicketsByModules: "Tickets resueltos por módulos",
      ticketsResolved: "Tickets resueltos",
    },
    securedMessagesSummaryReport: {
      title: "Resumen de Secure Messages",
      totalSecuredMessagesSent: "Mensajes seguros enviados totales",
      securedMessagesSentByUser: "Mensajes seguros enviados por usuarios",
    },
    dnsSummaryReport: {
      title: "Resumen de DNS",
      totalQueries: "Consultas totales",
      blockedQueries: "Consultas bloqueadas",
      topDomainsPermitted: "Dominios permitidos principales",
      topDomainsBlocked: "Dominios bloqueados principales ",
      topClients: "Clientes permitidos principales",
      topClientsBlocked: "Clientes bloqueados principales",
      perHits: "por objetivos",
      perRequest: "por petición",
      queries: "Consultas",
      noData: "No tiene ningún dato",
      noItems: {
        title: "El filtro de DNS está desactivado",
        description: "Habilitar el filtro de DNS para ver el resumen de consultas de DNS",
        actionBtn: "Ir a la configuración de SWG",
      },
    },
    exports: {
      newExport: "Nueva exportación",
      actions: {
        [ExportAction.EXPORT_DEVICES]: "Dispositivos",
        [ExportAction.EXPORT_PROTECTED_USERS]: "Usuarios protegidos",
        [ExportAction.EXPORT_TICKETS]: "Registro de ticket",
        [ExportAction.EXPORT_ACTIVITY_LOGS]: "Registro de actividad",
      },
      disclaimer:
        "<b>Las exportaciones están disponibles para descargar durante 24 horas</b> Si su exportación ha vencido, genere una nueva para descargarla.",
      table: {
        name: "Nombre",
        category: "Categoría",
        created: "Creado",
      },
      categories: {
        [ExportCategory.ACTIVITY_LOGS]: "Registro de actividad",
        [ExportCategory.DEVICES]: "Dispositivos",
        [ExportCategory.USERS]: "Usuarios protegidos",
        [ExportCategory.TICKETS]: "Registro de ticket",
      },
      exportFileExtensions: {
        [ExportFileExtension.CSV]: "CSV",
        [ExportFileExtension.ZIP]: "ZIP",
      },
      filters: {
        types: "Categoría",
        dateRange: "Rango de fechas",
      },
    },
    scheduled: {
      schedule: "Programa",
      table: {
        name: "Nombre",
        reportType: "Tipo",
        frequency: "Frecuencia",
        recipients: "Destinatarios",
        scope: "Alcance",
      },
      type: {
        report: "Informar",
        export: "Exportar",
      },
      actions: {
        [ScheduledReportsAction.ADD_REPORT]: "Informar",
        [ScheduledReportsAction.EDIT_REPORT]: "Editar",
        [ScheduledReportsAction.ADD_EXPORT]: "Exportar",
        [ScheduledReportsAction.EDIT_EXPORT]: "Editar",
        [ScheduledReportsAction.DELETE]: "Eliminar",
      },
      subTypes: {
        [ExportCategory.ACTIVITY_LOGS]: "Exportación del registro de actividad",
        [ExportCategory.DEVICES]: "Exportación de dispositivos",
        [ExportCategory.USERS]: "Exportación de usuarios protegidos",
        [ExportCategory.TICKETS]: "Exportación del registro de ticket",
        [ReportType.DNS_SUMMARY]: "Resumen de DNS",
        [ReportType.SECURED_MESSAGES]: "Resumen de Secure Messages",
        [ReportType.EXECUTIVE_SUMMARY]: "Resumen ejecutivo",
        [ReportType.MANAGED_SERVICES_SUMMARY]: "Resumen de los servicios gestionados",
      },
      frequencies: {
        [ScheduledReportFrequency.DAILY]: "Diario",
        [ScheduledReportFrequency.WEEKLY]: "Semanal",
        [ScheduledReportFrequency.MONTHLY]: "Mensual",
      },
      scopes: {
        [ScheduledReportScope.WORKSPACE_AND_DIRECT_DESCENDANTS]:
          "Mi Workspace y los descendientes directos",
        [ScheduledReportScope.WORKSPACE]: "Mi Workspace",
        [ScheduledReportScope.WORKSPACE_AND_ALL_DESCENDANTS]:
          "Mi Workspace y todos los descendientes",
      },
      recipients: {
        allAdminUsers: "Todos los administradores del Workspace",
        specificAdminUsers: "Administradores específicos",
      },
    },
  },
  dateRangePicker: {
    cancel: "Cancelar",
    reset: "Restablecer",
    select: "Seleccionar",
    lastThreeDays: "Últimos 3 días",
    lastSevenDays: "Últimos 7 días",
    lastThirtyDays: "Últimos 30 días",
    lastNinetyDays: "Últimos 90 días",
    last365Days: "Últimos 365 días",
    all: "Todo",
    today: "Hoy",
    yesterday: "Ayer",
    thisMonth: "Este mes",
    lastMonth: "El mes pasado",
  },
  devicesSettings: {
    title: "Endpoint Security",
    devicesTitle: "Dispositivos",
    description:
      "Implemente agentes de protección de terminales de Coro en los dispositivos de los usuarios",
    helpText: "Necesito ayuda con esto",
    gpoDeployment: "Necesito una implementación masiva",
    universalDownloadCard: {
      title: "Envíe un enlace de descarga universal por correo electrónico",
      subtitle:
        "Los destinatarios utilizarán este enlace para instalar y activar los agentes de protección de terminales de Coro en sus dispositivos.",
      generateLink: "Generar nuevo enlace de invitación",
      copyLinkBtn: "Copiar enlace",
    },
    windowsDownloadCard: {
      title: "Descargar para Windows",
      subtitle:
        "Instálelo de forma remota en varios dispositivos Windows utilizando la herramienta de implementación masiva que elija.",
    },
    macOSDownloadCard: {
      title: "Descargar para macOS",
      subtitle:
        "Instálelo de forma remota en varios dispositivos macOS utilizando la herramienta de implementación masiva que elija.",
    },
    tabs: {
      agentDeployment: "Implementación de agentes",
      settings: "Configuración",
      devicePosture: "Posición del dispositivo",
      labels: "Etiquetas",
      ngav: "NGAV",
      allowBlock: "Permitir o bloquear",
      addons: "Complementos",
    },
    allowBlockList: {
      applyToChildWorkspaces:
        "Aplicar reglas de permitir o bloquear a todos los Workspaces secundarios",
      applyFilesToChildWorkspaces:
        "Aplicar reglas de permitir o bloquear para archivos y carpetas a todos los Workspaces secundarios",
      applyProcessesToChildWorkspaces:
        "Aplicar reglas de permitir o bloquear para procesos en todos los Workspaces secundarios",
      table: {
        value: "Valor",
        list: "Lista",
        description: "Descripción",
      },
      actions: {
        [DeviceAllowListActions.ADD_PROCESS_RECORD]: "Añadir registro de procesos",
        [DeviceAllowListActions.ADD_FOLDER_RECORD]: "Añadir registro de carpetas",
        [DeviceAllowListActions.ADD_FILE_RECORD]: "Añadir registro de archivos",
        [DeviceAllowListActions.REMOVE_RECORD]: "Eliminar registro",
        [DeviceAllowListActions.EDIT_ALLOW_BLOCK_LIST_RECORD]: "Editar registro",
        [DeviceAllowListActions.IMPORT_ALLOW_BLOCK_LIST_CSV]: "Importar de un CSV",
        [DeviceAllowListActions.EDR_ADD_TO_ALLOWLIST]: "Añadir a la lista de permitidos",
        [DeviceAllowListActions.EDR_ADD_TO_BLOCKLIST]: "Añadir a la lista de bloqueados",
      },
      filters: {
        type: "Tipo",
        types: {
          [DeviceAllowBlockListTypes.EDR_FOLDER]: "Carpeta",
          [DeviceAllowBlockListTypes.FOLDER]: "Carpeta",
          [DeviceAllowBlockListTypes.EDR_FILE]: "Archivo",
          [DeviceAllowBlockListTypes.FILE]: "Archivo",
          [DeviceAllowBlockListTypes.PROCESS]: "Proceso",
        },
      },
    },
    agentDeploymentTab: {
      version: "Versión",
      released: "Publicada",
      channel: "Canal",
      devices: "Dispositivos",
      universalLink: "Enlace universal",
      beta: "Beta",
      stable: "Estable",
      copyLink: "Copiar enlace",
      releaseNotes: "Notas de la versión",
      osTypes: {
        osx: "macOS",
        windows: "Windows",
      },
      actions: {
        copyDownloadLink: "Copiar enlace",
        download: "Descargar",
      },
      channels: {
        [AgentDeploymentChannel.BETA]: "Beta",
        [AgentDeploymentChannel.RELEASE_CANDIDATE]: "Publicar candidato",
        [AgentDeploymentChannel.GRADUAL_ROLLOUT]: "Lanzamiento progresivo",
        [AgentDeploymentChannel.GENERAL_AVAILABILITY]: "Disponibilidad general",
      },
    },
    settingsCard: {
      heartbeatInterval: "Intervalo de pulsaciones",
      selectOption: "Cada minuto | Cada {n} minutos",
      heartbeatIntervalDescription:
        "Un intervalo más corto de pulsaciones puede causar una carga adicional en la gestión. Un intervalo más largo de pulsaciones puede dar lugar a registros e informes menos actualizados.",
      allowToUninstallEnabled: "Allow agent uninstallation",
      allowToUninstallDescription:
        "Allowing uninstallation exposes the Coro endpoint Agent and recovery backups to malware and ransomware attacks. <b>Allow agent uninstallation</b> should only be enabled for controlled device maintenance or to allow the user to uninstall the Coro endpoint Agent.",
      vssPolicyEnabled: "Copias de seguridad",
      vssPolicyDescription:
        "Hace una copia de seguridad cada cuatro horas y bloquea los procesos que supongan un riesgo para dichas copias.",
      atcEnabled: "Control avanzado de amenazas",
      atcDescription:
        "Supervisa los procesos activos para buscar amenazas conocidas y potenciales y bloquea los procesos que muestran un comportamiento sospechoso sin estar explícitamente incluidos en la lista de permitidos.",
      initialDevicesScan: "Habilitar un análisis inicial de malware y ransomware",
      initialDevicesScanDescription:
        "Impone un análisis de malware del dispositivo cuando el agente se instala al principio. Los análisis más exhaustivos siempre se pueden iniciar de forma remota y en cualquier momento.",
      wifiPhishing: "Phishing por wifi",
      wifiPhishingDescription:
        "Detecte conexiones a  puntos de acceso wifi que sean sospechosas de ataques de intermediario destinados a secuestrar la comunicación del dispositivo.",
      realTimeMalwareProtection: "Protección contra malware y ransomware en tiempo real",
      applyToDevices: "Aplicar a dispositivos con estas etiquetas",
      deviceLabelsPlaceholder: "Etiquetas de dispositivos",
      autoUpdateTitle: "Permitir la actualización automática a la última versión estable",
      allowToUninstallTitle: "Permitir la desinstalación de Coro Agent",
      passcode: "Código de acceso",
      regenerateBtn: "Volver a generar",
      autoUpdateLabelsDescription:
        "En dispositivos macOS, el proceso de actualización pedirá la aprobación del usuario. En dispositivos Windows, la actualización será completamente automática.",
      visibilityMode: "Modo de visibilidad",
      visibilityModeDescription:
        "Restrinja los agentes terminales de Coro solo a la detección, sin acciones de corrección de respuesta automática.",
      enforceContainerQuarantine: "Poner en cuarentena los contenedores infectados",
      enforceContainerQuarantineDescription:
        "Cuando la protección del terminal Coro detecta un archivo malicioso dentro de un contenedor (archivo) y no puede extraerlo, pone en cuarentena el contenedor entero.",
    },
    devicePostureTab: {
      vulnerability: "Vulnerabilidad",
      action: "Acción",
      policies: "Políticas",
      required: "Obligatorio",
      policiesQuantity: "{n} política | {n} políticas",
      noPolicies: "No hay políticas",
      osTypes: {
        macOsSettingsLabeled: "macOS",
        windowsSettingsLabeled: "Windows",
        windowsServerSettingsLabeled: "Servidor de Windows",
      },
      everyXDays: "Todos los días | Cada {n} días",
      networks: "{n} red | {n} redes",
      encrypted: "Cifrado",
      choices: {
        enforce: "Imponer",
        review: "Revisar",
        ignore: "Ignorar",
      },
      actions: {
        addDevicePosturePolicy: "Añadir a {service}",
        deleteDevicePosturePolicy: "Eliminar política",
        editDevicePosturePolicy: "Editar política",
      },
      posturePolicies: {
        uacNotificationMissing: "Falta la notificación de UAC",
        missingPinAndPasswordProtection: "Falta la contraseña del dispositivo",
        isUnencrypted: "Unidad terminal sin cifrar",
        isDeveloperModeEnabled: "Modo desarrollo habilitado",
        firewallUnavailable: "Cortafuegos deshabilitado",
        isNonGenuineWindows: "Copia de Windows no original",
        wifiSettings: "Conexión wifi",
        dlpScanScheduleSettings: "Análisis para buscar datos confidenciales",
        userAccountPolicies: "Contraseña remota y bloqueo de sesión",
        gatekeeperUnavailable: "Gatekeeper deshabilitado",
        appleMobileFileIntegrityUnavailable:
          "Integridad de archivos móviles de Apple deshabilitada",
        systemIntegrityProtectionUnavailable: "Protección de integridad del sistema deshabilitada",
        usbLockdown: "Bloqueo de USB",
      },
    },
    labelsTab: {
      addLabel: "Añadir etiqueta",
      deviceCount: "N.º de dispositivos",
      actions: {
        editLabel: "Editar",
        deleteLabel: "Eliminar",
      },
      noLabelsTitle: "Gestionar dispositivos con diferentes etiquetas",
      noLabelsSubtitle:
        "Cree etiquetas de dispositivos para que pueda agrupar y gestionar sus dispositivos con más facilidad.",
      addNewLabel: "Añadir nueva etiqueta",
      labelInUseTooltip: "La etiqueta se está utilizando en la configuración del dispositivo",
    },
    channels: {
      all: "Todos los canales",
      stable: "Canales estables",
    },
  },
  phishingSettings: {
    applyToChildWorkspaces:
      "Aplicar reglas de permitir o bloquear a todos los Workspaces secundarios",
    title: "Phishing",
    description:
      "Defensa contra ataques de ingeniería social como phishing por correo electrónico, ataques dirigidos por correo electrónico y phishing por wifi",
    noEmailServicesConnected:
      "Conecte <b>Microsoft 365</b> o <b>G-Suite</b> de su empresa a Coro para permitir una protección antiphishing clara",
    connectCloudAppsBtn: "Conectar aplicaciones en la nube",
    tabs: {
      settings: "Configuración",
      allowBlockList: "Permitir o bloquear",
    },
    allowed: "Permitido",
    blocked: "Bloqueado",
    domain: "Dominio",
    email: "Correo electrónico",
    allow: "Lista de permitidos",
    block: "Lista de bloqueados",
    local: "Contenido sospechoso {listType}",
    auth: "Error de autenticación {listType}",
    table: {
      name: "Nombre",
      list: "Lista",
    },
    actions: {
      addToAllowlist: "Añadir a la lista de permitidos",
      addToBlocklist: "Añadir a la lista de bloqueados",
      remove: "Eliminar",
      importFromCSV: "Importar de un CSV",
    },
    card: {
      title: "Phishing por correo electrónico [obsoleto]",
      contentScan: "Contenido",
      suspiciousImpersonation: "Suplantación sospechosa",
      suspiciousLinksAndAttachments: "Enlaces y archivos adjuntos sospechosos",
      phishingTypeContent: "Contenido de tipo phishing",
      feedbackTitle: "Comentarios del usuario",
      feedbackDescription:
        "Para permitir comentarios de sus usuarios sobre correos electrónicos sospechosos y marcados incorrectamente, instale el complemento de Coro:",
      feedbackDisclaimer:
        "Coro recopila y agrega de forma continua comentarios de los usuarios sobre clasificaciones inexactas del correo electrónico y los facilita para que pueda examinarlos.",
      wifiPhishing: "Phishing por wifi",
      wifiPhishingDescription:
        "Detecte conexiones a puntos de acceso wifi sospechosos que pueden utilizarse para robar datos del usuario, incluidas credenciales de inicio de sesión y números de tarjetas de crédito.",
    },
    allEntities: "Todas las entidades",
    allLists: "Todas las listas",
  },
  ticketDetails: {
    processed: "Cerrado",
    triggers: "Activadores",
    severity: "Gravedad",
    rules: "Reglas",
    rule: "regla | reglas",
    service: "Servicio",
    actions: "Acciones",
    user: "Usuario",
    lastSeen: "Visto por última vez",
    firstSeen: "Visto por primera vez",
    duration: "Duración",
    showFindings: "Mostrar resultados",
    certificates: "Certificados",
    sourceCode: "Código fuente",
    otherFileTypes: "Otros tipos de archivos",
    sharedBy: "Compartido por",
    sharedWith: "Compartido con",
    receiveTime: "Hora de recepción",
    happened: "Ha sucedido {n} vez | Ha sucedido {n} veces",
    downloaded: "Se ha descargado {n} vez | Se ha descargado {n} veces",
    happenedMoreThanForHundredTimes: "Ha sucedido más de 400 veces",
    downloadedMoreThanForHundredTimes: "Se ha descargado más de 400 veces",
    registryValue: "Valor de registro",
    processName: "Nombre del proceso",
    threatStatus: "Estado de amenaza",
    hostName: "Nombre del host",
    inactiveSince: "Inactivo desde:",
    inactivityPeriod: "Periodo inactivo",
    osVersion: "Versión del SO",
    deviceId: "ID del dispositivo",
    affectedDevices: "Dispositivos afectados",
    upn: "UPN",
    times: "{n} vez | {n} veces",
    deviceLoginUserName: "Nombre de usuario de inicio de sesión del dispositivo",
    userName: "Nombre de usuario",
    email: "Correo electrónico",
    addProtectionBtn: "Añadir protección",
    notProtectedUsersNotice: "El usuario no está protegido | {n} usuarios no están protegidos",
    lastModifiedBy: "Última modificación por",
    lastModifiedTime: "Hora de la última modificación",
    interfaceType: "Tipo de interfaz",
    driveModel: "Modelo de la unidad",
    driveName: "Nombre de la unidad",
    processPath: "Ruta del proceso",
    processHash: "Hash del proceso",
    processGroup: "Grupo de procesos",
    type: "Tipo",
    threatType: "Tipo de amenaza",
    suspiciousCharacteristics: "Características sospechosas",
    reports: "Informes",
    users: "Usuarios",
    lastUsersOnDevice: "Últimos usuarios en el dispositivo",
    when: "Cuándo",
    from: "De",
    to: "A",
    customer: "Cliente",
    internetMessageId: "ID del mensaje",
    senderIp: "IP del remitente",
    attachments: "Archivo adjunto | Archivos adjuntos",
    labels: "Etiquetas",
    plusNFindings: "+ {n} resultados",
    plusNFiles: "+ {n} archivos",
    keyDetails: {
      title: "Información clave",
    },
    threatTypes: {
      virus: "Virus",
      spyware: "Spyware",
      ransomware: "Ransomware",
      adware: "Adware",
      dialer: "Marcador",
      potentiallyMaliciousApp: "Aplicación potencialmente maliciosa",
      archiveBombFile: "Archivar documento bomba",
      rootkit: "Rootkit",
      spam: "Correo no deseado",
      suspiciousBehavior: "Comportamiento sospechoso",
      trojan: "Troyano",
      worm: "Gusano",
      backdoor: "Backdoor",
      tool: "Herramienta",
      unknown: "Desconocido",
      potentiallyUnwantedSoftware: "Software potencialmente no deseado",
    },
    eventActions: {
      treatFileAsSafe: "Aprobar este archivo",
      treatFileAsMalicious: "Descartar este archivo",
      excludeFolderFromScan: "Excluir carpeta del análisis de malware",
      enableFirewall: "Habilitar cortafuegos",
      remoteScan: "Análisis remoto para buscar malware",
      markAsProcessed: "Cerrar ticket",
      markAsUnprocessed: "Volver a abrir",
      suspendFromAll: "Retirar al usuario de todas las aplicaciones en la nube",
      suspendFromService: "Retirar al usuario de {service}",
      signInToAll: "Solicitar al usuario que inicie sesión en todas las aplicaciones en la nube",
      signInToService: "Solicitar al usuario que inicie sesión en {service}",
      enforceUAC: "Imponer la notificación de UAC",
      disableDevMode: "Deshabilitar modo desarrollador",
      inspectEmail: "Inspeccionar correo electrónico",
      approveEmailsFromSender: "Añadir el remitente a la lista de permitidos",
      approveEmailsFromDomain: "Añadir el dominio del remitente a la lista de permitidos",
      discardEmailsFromSender: "Añadir el remitente a la lista de bloqueados",
      discardEmailsFromDomain: "Añadir el dominio del remitente a la lista de bloqueados",
      approveEmail: "Aprobar este correo electrónico",
      discardEmail: "Eliminar este correo electrónico",
      removeExposingSharing: "Eliminar la exposición de recursos compartidos",
      contactUser: "Contactar con el usuario",
      allowNoEncryption: "Permitir elementos no cifrados",
      approveProcessGroup: "Aprobar grupo de procesos",
      discardInfectingFile: "Descartar el archivo infectado",
      approveFile: "Aprobar archivo",
      deleteFile: "Eliminar archivo",
      logForAuditReports: "Registro y referencia de informes de auditoría",
      unLogForAuditReports: "Anular el registro y eliminar de los informes de auditoría",
      stopRemoteScan: "Detener el análisis remoto para buscar malware",
      encryptDrive: "Cifrar unidad",
      addToDataGovernancePermissions: "Añadir a los permisos de gobernanza de datos",
      downloadEmlFile: "Descargar archivo .eml",
      enableGatekeeper: "Habilitar Gatekeeper",
      enableAppleMobileFileIntegrity: "Habilitar la integridad de archivos móviles de Apple",
      approveEmailWithOptions: "Permitir",
      discardEmailWithOptions: "Bloquear",
      generalApprove: "Permitir",
      exportMassDownloadFiles: "Exportar lista de archivos",
      exportMassDeleteFiles: "Exportar lista de archivos",
      isolateDevice: "Aislar dispositivo",
      blockProcess: "Bloquear proceso",
      unblockProcess: "Desbloquear proceso",
      enableNetworkBlockMode: "Aislar los dispositivos afectados de la red",
      disableNetworkBlockMode: "Deshabilitar el aislamiento de dispositivos de la red",
      rebootDevice: "Reiniciar dispositivos",
      shutdownDevice: "Apagar dispositivos",
      allowProcess: "Permitir proceso",
    },
    eventBulkActions: {
      markAsProcessed: "Cerrar ticket | Cerrar tickets",
      markAsUnprocessed: "Reabrir ticket | Reabrir tickets",
      bulkAddComment: "Añadir comentario",
      exportCsv: "Exportar CSV",
    },
    actionTypes: {
      suspendUser: "Retirar al usuario de todos los servicios",
      alertUser: "Avisar al usuario",
    },
    fileName: "Nombre del archivo",
    fileSize: "Tamaño del archivo",
    path: "Ruta del archivo",
    allFiles: "Todos los archivos",
    link: "Enlace",
    file: "Archivo",
    ip: "IP",
    ipAddress: "Dirección IP",
    ipAndCountry: "IP/país",
    ticketDetails: "Información del ticket",
    eventHappenedMessages: {
      default: "Se produjo un evento",
      suspectedBotAttacks: "Error al iniciar sesión",
      suspiciousDownload: "Descargado",
      potentialRansomwareActivity: "Archivos sospechosos",
    },
    filesCount: "{n} archivo | {n} archivos",
    suspiciousFiles: "Archivos sospechosos",
    virusName: "Nombre del virus",
    virusType: "Tipo de virus",
    unknownVirus: "Desconocido (detectado por el servicio)",
    owner: "Propietario",
    sender: "Remitente",
    senderName: "Nombre del remitente",
    senderEmail: "Correo electrónico del remitente",
    recipients: "Destinatarios",
    subject: "Asunto",
    suspiciousContentLocation: "Ubicación del contenido sospechoso",
    sharedAt: "Compartido en",
    foundIn: "Encontrado en",
    performedBy: "Realizado por",
    ownedBy: "Propiedad de",
    lastModified: "Modificado por última vez",
    created: "Creado",
    mimeType: "Tipo de MIME",
    country: "País",
    foundInTypes: {
      attachment: "Archivo adjunto del correo electrónico",
      body: "Cuerpo del correo electrónico",
      subject: "Asunto del correo electrónico",
    },
    emailSubject: "Asunto del correo electrónico",
    emailSender: "Remitente del correo electrónico",
    inspectEmail: "Inspeccionar correo electrónico",
    device: "Dispositivo",
    fileHash: "Hash del archivo",
    filePath: "Ruta del archivo",
    enrollmentCode: "Código de inscripción | Códigos de inscripción",
    model: "Modelo",
    os: "SO",
    network: "Red",
    ssid: "SSID",
    bssid: "BSSID",
    security: "Seguridad",
    findings: "Resultados",
    additionalFindings: "Resultados adicionales",
    process: "Proceso",
    hash: "Hash",
    allowedOn: "Proceso permitido el {date}",
    blockedOn: "Proceso bloqueado el {date}",
    incidentFindings: {
      type: {
        PHISHING_GENERAL: "Phishing por correo electrónico [obsoleto]",
        PHISHING_ABNORMAL_SENDER: "Remitente anormal",
        MALWARE_DETECTION: "Archivo de malware",
        UNMET_ENFORCED_AUTHENTICATION_REQUIREMENTS:
          "No se han podido cumplir los requisitos de autenticación impuestos",
        UNVERIFIED_SENDER: "Remitente no verificado",
        SPEAR_PHISHING_USER_IMPERSONATION: "Spear phishing: suplantación de identidad del usuario",
        SUSPICIOUS_ENVELOPE: "Sobre sospechoso",
        COUSIN_DOMAIN_DOMAIN_IMPERSONATION:
          "Dominio similar: suplantación de identidad de la marca",
        MESSAGE_HONEYPOT_BRAND_IMPERSONATION:
          "Honeypot de mensaje: suplantación de identidad de la marca",
        BRAND_DOMAIN_SPOOFING: "Suplantación del dominio de la marca",
        ENVELOPE_HONEYPOT_DOMAIN_IMPERSONATION:
          "Honeypot de sobre: suplantación de identidad del dominio",
        COUSIN_DOMAIN_BRAND_IMPERSONATION: "Dominio similar: suplantación de identidad del dominio",
        DOMAIN_SPOOFING: "Suplantación del dominio",
        UNAUTHENTICATED_SELF_NOTE: "Nota propia no autenticada",
        HOMOGRAPHIC_DOMAIN_SPOOFING: "Suplantación del dominio homográfico",
        ENVELOPE_HONEYPOT_USER_IMPERSONATION:
          "Honeypot de sobre: suplantación de identidad del usuario",
        ENVELOPE_HONEYPOT_BRAND_IMPERSONATION:
          "Honeypot de sobre: suplantación de identidad de la marca",
        HOMOGRAPHIC_USER_ADDRESS_SPOOFING: "Suplantación de la dirección de usuario homográfica",
        HOMOGRAPHIC_USER_NAME_SPOOFING: "Suplantación del nombre de usuario homográfico",
        ABNORMAL_SENDER: "Remitente anormal",
        MALICIOUS_URL: "Enlace malicioso",
        MALICIOUS_QR_CODE: "Código QR sospechoso",
        EMAIL_MALWARE_ACTIVITY: "Malware en archivos adjuntos del correo electrónico",
        MALICIOUS_SENDER: "Remitente malicioso",
        PHISHING_TEST: "Prueba de phishing",
        CREDIT_CARD_NUMBER: "Número de la tarjeta de crédito",
        IBAN_CODE: "Código IBAN",
        SWIFT_CODE: "Código SWIFT",
        US_BANK_ROUTING_MICR: "Número de ruta bancaria de EE. UU.",
        AMERICAN_BANKERS_CUSIP_ID: "Número CUSIP de bancos de EE. UU.",
        EMAIL_ADDRESS: "Dirección de correo electrónico",
        IP_ADDRESS: "Dirección IP",
        IP_ADDRESS_KEYWORD: "Dirección IP",
        MAC_ADDRESS: "Dirección MAC",
        MAC_ADDRESS_KEYWORDS: "Dirección MAC",
        PERSON_NAME: "Nombre de la persona",
        ADDRESS: "Dirección",
        IMEI_HARDWARE_ID: "ID del hardware de IMEI",
        PHONE_NUMBER: "Número de teléfono",
        PHONE_NUMBER_KEYWORD: "Número de teléfono",
        US_PASSPORT: "Pasaporte de EE. UU.",
        US_PASSPORT_KEYWORD: "Pasaporte de EE. UU.",
        DRIVER_LICENSE: "Permiso de conducir",
        DRIVER_LICENSE_KEYWORD: "Permiso de conducir",
        DRIVER_LICENSE_NUMBER: "Número del permiso de conducir",
        BANK_ROUTING_NUMBER: "Número de la sucursal bancaria",
        BANK_ROUTING_NUMBER_KEYWORD: "Número de la sucursal bancaria",
        FINANCIAL_METADATA: "Metadatos financieros",
        FINANCIAL_CONTENT_KEYWORD: "Contenido financiero",
        FINANCIAL_CONTENT: "Contenido financiero",
        US_SOCIAL_SECURITY_NUMBER: "Número de la seguridad social de EE. UU.",
        US_SOCIAL_SECURITY_NUMBER_KEYWORD: "Seguridad social de EE. UU.",
        US_BANK_KEYWORD: "Banco de EE. UU.",
        US_EMPLOYER_IDENTIFICATION_NUMBER: "Número de identificación del empresario de EE. UU.",
        TAXPAYER_IDENTIFICATION_NUMBER: "Número de identificación del contribuyente",
        TAXPAYER_IDENTIFICATION_NUMBER_KEYWORD: "Número de identificación del contribuyente",
        US_INDIVIDUAL_TAXPAYER_IDENTIFICATION_NUMBER:
          "Número de identificación del contribuyente individual de EE. UU.",
        VEHICLE_IDENTIFICATION_NUMBER: "Número de identificación del vehículo",
        US_VEHICLE_IDENTIFICATION_NUMBER: "Número de identificación del vehículo de EE. UU.",
        CANADA_PASSPORT: "Pasaporte de Canadá",
        CANADA_SOCIAL_INSURANCE_NUMBER: "Número de la seguridad social de Canadá",
        ISRAEL_ID: "Carné de identidad de Israel",
        ICD9_CODE: "Código ICD9",
        ICD10_CODE: "Código ICD10",
        FDA_CODE: "Código FDA",
        US_HEALTHCARE_NPI: "NPI de atención sanitaria de EE. UU.",
        US_HEALTHCARE_NPI_KEYWORD: "NPI de atención sanitaria de EE. UU.",
        US_DEA_NUMBER: "Número DEA de EE. UU.",
        US_DEA_NUMBER_KEYWORD: "Número DEA de EE. UU.",
        CANADA_QUEBEC_HIN: "Número HIN de Quebec de Canadá",
        HEALTH_KEYWORDS: "Palabras clave sanitarias",
        DATE_OF_BIRTH: "Fecha de nacimiento",
        ISBN: "ISBN",
        ISBNKeyword: "ISBN",
        PASSWORD: "Contraseña",
        PASSWORD_KEYWORD: "Contraseña",
        KEYWORDS: "Palabras clave",
        REGEX: "Expresión regular",
        PHISHING_SUSPICIOUS_SUBJECT: "Asunto sospechoso",
        PHISHING_SUSPICIOUS_TAGS_IN_ATTACHMENT: "Contenido del archivo adjunto sospechoso",
        MSP_PHISHING_BLOCKED_SENDER: "Remitente sospechoso (incluido en la lista de bloqueados)",
        MSP_PHISHING_BLOCKED_URL:
          "Contenido incrustado sospechoso (incluido en la lista de bloqueados)",
        BLOCKLISTED_DOMAIN: "Dominio incluido en la lista de bloqueados",
        BLOCKLISTED_SENDER: "Remitente incluido en la lista de bloqueados",
        REPORTED_BY_USER: "Notificado por el usuario",
        HEALTH_CODE: "Código sanitario",
        IBAN: "IBAN",
        SWIFT: "SWIFT",
        SWIFT_KEYWORD: "SWIFT",
        ANNUAL_CREDIT_REPORT_FORM: "Informe de crédito anual",
        ANNUAL_CREDIT_REPORT_FORM_KEYWORD: "Informe de crédito anual",
        BILL_OF_SALE_FORM: "Factura de venta",
        BILL_OF_SALE_FORM_KEYWORD: "Factura de venta",
        INSURANCE_CARD_FORM: "Tarjeta del seguro",
        INSURANCE_CARD_FORM_KEYWORD: "Tarjeta del seguro",
        FR44_FORM: "FR44",
        FR44_FORM_KEYWORD: "FR44",
        SR22_FORM: "SR22",
        SR22_FORM_KEYWORD: "SR22",
        PAY_STUB_FORM: "Talón de pago",
        PAY_STUB_FORM_KEYWORD: "Talón de pago",
        MISC_1099_FORM: "MISC 1099",
        MISC_1099_FORM_KEYWORD: "MISC 1099",
        BANK_STATEMENT_FORM: "Extracto bancario",
        BANK_STATEMENT_FORM_KEYWORD: "Extracto bancario",
        PERSONAL_NET_WORTH_FORM: "Patrimonio neto personal",
        PERSONAL_NET_WORTH_FORM_KEYWORD: "Patrimonio neto personal",
        VEHICLE_REGISTRATION_APPLICATION_FORM: "Solicitud de matriculación de vehículos",
        VEHICLE_REGISTRATION_APPLICATION_FORM_KEYWORD: "Solicitud de matriculación de vehículos",
        LEASE_AGREEMENT_FORM: "Contrato de arrendamiento",
        LEASE_AGREEMENT_FORM_KEYWORD: "Contrato de arrendamiento",
        CAR_TITLE_FORM: "Titularidad del vehículo",
        CAR_TITLE_FORM_KEYWORD: "Titularidad del vehículo",
        CREDIT_CARD_STATEMENT_FORM: "Extracto de la tarjeta de crédito",
        CREDIT_CARD_STATEMENT_FORM_KEYWORD: "Extracto de la tarjeta de crédito",
        W2_FORM: "W2",
        W2_FORM_KEYWORD: "W2",
        ODOMETER_DISCLOSURE_FORM: "Información del odómetro",
        ODOMETER_DISCLOSURE_FORM_KEYWORD: "Información del odómetro",
        USERNAME: "Nombre de usuario",
        CUSTOM_KEYWORD: "Personalizado",
        US_EMPLOYER_ID_NUMBER: "Número de identificación del empresario de EE. UU.",
        US_EMPLOYER_ID_NUMBER_KEYWORD: "Número de identificación del empresario de EE. UU.",
        MEDICAL_BENEFICIARY_IDENTIFIER: "Identificador del beneficiario médico",
        MEDICAL_BENEFICIARY_IDENTIFIER_KEYWORD: "Identificador del beneficiario médico",
        MEDICAL_RECORDS_NUMBER_KEYWORD: "Número de informes médicos",
        DATE: "Fecha",
        DATE_KEYWORD: "Fecha",
        INSURANCE_PLAN_PAYMENT: "Pago del plan de seguro",
        GENERAL_MEDICAL_KEYWORD: "Información médica",
        MEDICAL_FILE: "Expediente médico",
        CREDIT_CARD_NUMBER_KEYWORD: "Número de la tarjeta de crédito",
        VEHICLE_IDENTIFICATION_NUMBER_KEYWORD: "Número de identificación del vehículo",
        US_ADDRESS: "Dirección de EE. UU.",
        HEALTH_INSURANCE_CLAIM_FORM: "Reclamación del seguro médico",
        US_DEATH_CERTIFICATE_FORM: "Certificado de defunción",
        US_BIRTH_CERTIFICATE_FORM: "Certificado de nacimiento",
        IMEI: "IMEI",
        PHISHING_SUSPICIOUS_CONTENT: "Contenido sospechoso de phishing",
        BLOCKED_ATTACHMENT_TYPE: "Tipo de archivo adjunto bloqueado",
        CUSTOM_FILE_TYPE: "Tipo de archivo personalizado",
        MISSING_REQUIRED_AUTHENTICATION: "Falta la autenticación obligatoria",
        CROWDBLOCKED_SENDER: "Remitente bloqueado repetidamente",
        MALWARE_IN_EMAIL_ATTACHMENT: "Malware en el archivo adjunto del correo electrónico",
        USER_IMPERSONATION: "Suplantación del usuario",
        DOMAIN_IMPERSONATION: "Suplantación del dominio",
        BRAND_IMPERSONATION: "Suplantación de la marca",
        FORBIDDEN_ATTACHMENT_TYPE: "Tipo de archivo adjunto prohibido",
        SUSPICIOUS_CONTENT: "Contenido sospechoso",
        SENDER_OR_DOMAIN_IN_GLOBAL_BLOCKLIST:
          "El remitente, el dominio, la dirección IP o el rango está en la lista de bloqueados global",
        SPF_AUTHENTICATION_FAILED: "Remitente no verificado",
        MALICIOUS_SENDER_DOMAIN: "Dominio de remitente malicioso",
        SPAM_CONTENT: "Contenido no deseado",
      },
    },
    fullDetails: {
      title: "Información completa",
      notAvailable: "La información completa no está disponible",
      unknownDetectedBy: "Desconocido {{detectedBy}}",
      detectedByBitdefender: "Detectado por BitDefender",
      detectedByMicrosoftDefender: "Detectado por Microsoft Defender",
      detectedByBoxShield: "Detectado por Box Shield",
      detectedByService: "Detectado por el servicio",
      emailAttachments: "Archivos adjuntos del correo electrónico",
      emailContent: "Contenido del correo electrónico",
      anomalyDetection: {
        login: "Iniciar sesión",
        failedLogin: "Error al iniciar sesión",
        share: "Compartir",
        upload: "Subir",
        download: "Descargar",
        collaborationInvite: "Invitación de colaboración",
        admin: "Inicio de sesión del administrador",
        countryAndIp: "País/IP",
        time: "Hora",
        type: "Tipo de registro",
        delete: "Eliminar",
        orgName: "ISP",
        threatType: "Tipo de amenaza",
        proxy: "Proxy",
        unhandled: "Evento de actividad del usuario",
        lastDetected: "Detectado por última vez:",
      },
      accessViolations: {
        title: "Violaciones de políticas",
        share: "El usuario {user} no tiene permisos para exponer {trigger}.",
        access: "El usuario {user} no tiene permisos para acceder a {trigger}.",
        dlpPci: "PCI",
        dlpPhi: "PHI",
        dlpPii: "PII",
        dlpNpi: "NPI",
        criticalDataSourceCode: "código fuente",
        criticalDataPassword: "contraseña",
        criticalDataCertificate: "certificado",
        criticalDataKeywords: "palabras clave",
        criticalDataFileTypes: "tipos de archivo",
      },
      devicePasswordMissing: {
        title: "Usuarios con acceso sin contraseña a este dispositivo",
        entry: "Usuario {userIdentifier}.",
      },
      fileFindings: {
        title: "Resultados",
        count: "N.º de resultados",
      },
      abnormalDataManipulation: {
        countryOrIP: "País / IP",
        host: "Host",
        files: "Archivos",
        name: "Nombre",
        mimeType: "Tipo de MIME",
        folder: "Ruta",
        filesAffected: "Archivos afectados",
        massDownloadFiles: "Archivos descargados",
        massDeleteFiles: "Archivos eliminados",
      },
      edrAffectedDevices: {
        device: "Dispositivo",
        executionTime: "Hora de ejecución",
        commandLine: "Línea de comandos",
        path: "Ruta",
        processChain: "Cadena de procesos",
        mitre: "MITRE",
        insights: "Información",
        processGraph: "Process graph",
      },
    },
    filters: {
      type: "Tipo",
      during: "Durante",
      status: "Estado",
      searchTickets: "Buscar tickets...",
      widgets: {
        placeholder: "En todas partes",
        deviceSecurityWidget: "Endpoint Security",
        cloudSecurity: "Cloud Security",
        emailsWidget: "Email Security",
        usersWidget: "Usuarios",
        userDataGovernance: "User Data Governance",
        endpointDataGovernance: "Endpoint Data Governance",
        edr: "EDR",
      },
    },
  },
  eventsPage: {
    duration: "Duración",
    whoShared: "Quién ha compartido",
    withWhom: "Con quién",
    user: "Usuario",
    sentBy: "Enviado por",
    sentTo: "Enviado a",
    lastLoggedInUser: "Último usuario que inició sesión",
    more: "+ {n} más",
  },
  edr: {
    title: "Endpoint Detection & Response",
    isolatedFromNetwork: "Aislado de la red",
    tabs: {
      telemetry: "Telemetría",
      processes: "Procesos",
      allowBlockList: "Permitir o bloquear",
    },
    table: {
      processes: "{n} proceso | {n} procesos",
      devices: "{n} dispositivo | {n} dispositivos",
      lastSeen: "Visto por última vez",
      multipleNameProcess: "Proceso con varios nombres",
      blocked: "Bloqueado",
      blockedOn: "Bloqueado el",
    },
    filters: {
      during: "Durante",
      types: "Tipos",
    },
    actions: {
      isolateDevice: "Aislar dispositivo | Aislar dispositivos",
      blockProcess: "Bloquear proceso | Bloquear procesos",
      allowProcess: "Permitir proceso | Permitir procesos",
      unblockProcess: "Desbloquear proceso | Desbloquear procesos",
      enableNetworkBlockMode:
        "Aislar el dispositivo afectado de la red | Aislar los dispositivos afectados de la red",
      disableNetworkBlockMode:
        "Deshabilitar el aislamiento del dispositivo de la red | Deshabilitar el aislamiento de los dispositivos de la red",
      rebootDevice: "Reiniciar el dispositivo | Reiniciar los dispositivos",
      shutdownDevice: "Apagar el dispositivo | Apagar los dispositivos",
      viewLog: "Ver registro completo",
    },
    details: {
      blockedOn: "Proceso bloqueado el ",
      hash: "Hash",
      devicesAffected: "Dispositivos",
      knownPaths: "Rutas conocidas",
      processAliases: "Alias del proceso",
      openTickets: "Tickets abiertos",
      isolated: "aislado",
      numberOfDevices: "Número de dispositivos",
      time: "Visto por última vez",
      viewTelemetry: "Ver telemetría",
      viewOpenTickets: "Todos los tickets abiertos",
      firstExecutionDetails: "Información de la primera ejecución",
    },
    telemetryTab: {
      types: {
        registryKey: "Clave de registro",
        scheduledTask: "Tarea programada",
        accountEvent: "Evento de la cuenta",
        usbDeviceActivity: "Actividad del dispositivo USB",
        groupPolicyChanges: "Cambios de la política del grupo",
      },
      table: {
        noData: {
          title: "No se han encontrado resultados",
          description:
            "Afine su búsqueda, aplique distintos filtros o limpie la búsqueda para intentarlo de nuevo",
          actionBtn: "Limpiar búsqueda y filtros",
        },
        forensic: "Forense",
        device: "Dispositivo",
        process: "Proceso",
        userName: "Nombre de usuario",
        eventId: "ID del evento",
        accountName: "Nombre de la cuenta",
        processName: "Nombre del proceso",
        parentProcessName: "Nombre del proceso principal",
        processHash: "Hash",
        commandLine: "Línea de comandos",
        action: "Acción",
        trigger: "Activar",
        operationType: "Tipo de operación",
        currentValue: "Valor actual",
        oldValue: "Valor anterior",
        accountDomain: "Dominio de cuenta",
        targetServer: "Servidor de destino",
        detailedProcessInfo: "Información detallada del proceso",
        affectedDevices: "Dispositivos afectados",
        success: "Acción correcta",
        authenticationType: "Tipo",
        uid: "UID",
        userType: "Tipo de usuario",
        dbPath: "Ruta",
        instigator: "Instigador",
        usbDevice: "Dispositivo USB",
        friendlyName: "Nombre descriptivo",
        targetAccount: "Cuenta objetivo",
        groupPolicyObjectName: "Nombre del objeto de la directiva de grupo",
        passwordMinimumAgePolicy: "Política de edad mínima para contraseñas",
        passwordLengthPolicy: "Longitud de la política de contraseñas",
      },
    },
    allowBlockListTab: {
      addProcessBtn: "Añadir proceso",
    },
  },
  activityLogs: {
    title: "Registros de actividad",
    search: "Buscar...",
    noData: "No se han encontrado registros",
    undone: "Anulado",
    automaticallyByCoro: "de forma automática por Coro",
    plusNLogs: "+ {n} activity logs",
    actions: {
      undo: "Anular",
      download: "Descargar",
    },
    errors: {
      csvDownloadLinkExpired:
        "Los enlaces de descarga vencen a las 24 horas. Vuelva a generar el CSV",
      reportDownloadLinkExpired:
        "Los enlaces de descarga vencen a las 24 horas. Vuelva a generar el informe",
    },
    filters: {
      types: "Tipos",
      dateRange: "Rango de fechas",
      type: {
        [ActivityLogsType.ACCOUNT]: "Account",
        [ActivityLogsType.CLOUD_APPS]: "Cloud Apps",
        [ActivityLogsType.DEVICE]: "Device",
        [ActivityLogsType.DLP]: "User Data Governance",
        [ActivityLogsType.DEVICE_DLP]: "Endpoint Data Governance",
        [ActivityLogsType.EMAIL]: "Email Security",
        [ActivityLogsType.MALWARE]: "Malware",
        [ActivityLogsType.PHISHING]: "Phishing",
        [ActivityLogsType.UNDO]: "Undo",
        [ActivityLogsType.USERS]: "Users",
        [ActivityLogsType.BILLING]: "Billing",
        [ActivityLogsType.DETECTION_LOGS]: "Detection Logs",
        [ActivityLogsType.CSV_EXPORT]: "CSV Export",
        [ActivityLogsType.WORKSPACE]: "Workspace",
        [ActivityLogsType.MSP]: "MSP",
        [ActivityLogsType.SIEM_CONFIG]: "SIEM Config",
        [ActivityLogsType.API_CREDENTIALS]: "API Credentials",
        [ActivityLogsType.WORKSPACE_REPORT]: "Workspace Report",
        [ActivityLogsType.WORKSPACE_SCHEDULED_REPORT]: "Scheduled Report",
        [ActivityLogsType.SOC]: "Managed Service",
        [ActivityLogsType.MOBILE]: "Mobile",
        [ActivityLogsType.PSA]: "PSA",
        [ActivityLogsType.MDM_DEVICE]: "MDM device",
        [ActivityLogsType.MDM_ACTION]: "MDM action",
        [ActivityLogsType.MDM_DEVICE_ACTION]: "MDM device action",
        [ActivityLogsType.MDM_SYSTEM]: "MDM system",
        [ActivityLogsType.WEBHOOK]: "Webhooks",
        mdm: "MDM",
      },
    },
  },
  thirdPartyApps: {
    columns: {
      thirdPartyApp: "Aplicación de terceros",
      cloudApplication: "Aplicación en la nube",
      protectedUsers: "Usuarios protegidos",
    },
    filters: {
      cloudApps: "Aplicaciones en la nube",
      cloudAppsNoData: "No hay aplicaciones que coincidan con la búsqueda",
      status: "Estado",
    },
    status: {
      [ThirdPartyAppStatus.ENABLED]: "Permitido",
      [ThirdPartyAppStatus.DISABLED]: "Bloqueado",
    },
    servicePermissionsMissing: {
      title: "Se requieren permisos de {service}",
      description:
        "Tus permisos actuales de {service} no permiten el acceso a aplicaciones de terceros. Otorga permisos adicionales para habilitar esta función para {service}.",
    },
    connectionDate: "Fecha de conexión:",
    publisher: "Editor:",
    users: "{n} usuario | {n} usuarios",
    allow: "Permitir",
    block: "Bloquear",
  },
  emailProxy: {
    title: "Servidor proxy del correo electrónico",
    addDomain: "Añadir dominio",
    directions: {
      inbound: "Entrantes",
      outbound: "Salientes",
    },
    tabs: {
      inbound: "Entrantes",
      outbound: "Salientes",
    },
    emailSecurityMode: {
      title: "Modo Email Security",
      types: {
        warning: "Solo advertencia",
        block: "Bloquear",
      },
      descriptions: {
        outbound: {
          warning:
            "Los correos electrónicos salientes sospechosos desde el punto de vista de la seguridad (phishing o malware) no se bloquean, sino que se marcan con advertencias explicativas para los destinatarios.",
          block:
            "Los correos electrónicos salientes sospechosos desde el punto de vista de la seguridad (phishing o malware) se bloquean y solo los administradores del Workspace pueden liberarlos de la cuarentena.",
        },
      },
    },
    dataGovernanceMode: {
      title: "Modo de gobernanza de datos",
      types: {
        warning: "Solo advertencia",
        block: "Bloquear",
      },
      descriptions: {
        inbound: {
          warning:
            "Los correos electrónicos entrantes con contenido confidencial desde el punto de vista de la privacidad no se bloquean, sino que se marcan con advertencias explicativas para los destinatarios.",
          block:
            "Los correos electrónicos entrantes con contenido confidencial desde el punto de vista de la privacidad se bloquean y solo los administradores del Workspace pueden liberarlos de la cuarentena.",
        },
        outbound: {
          warning:
            "Los correos electrónicos salientes con contenido confidencial desde el punto de vista de la privacidad no se bloquean, sino que se marcan con advertencias explicativas para los destinatarios.",
          block:
            "Los correos electrónicos salientes con contenido confidencial desde el punto de vista de la privacidad se bloquean y solo los administradores del Workspace pueden liberarlos de la cuarentena.",
        },
      },
    },
    enableProxyLink: "Cómo habilitar Inbound Gateway",
    table: {
      title: "Utilizar el servidor proxy {direction} para estos dominios",
      headers: {
        domain: "Dominio",
        smpt: "Retransmisión SMTP",
        host: "Host",
        port: "Puerto",
        status: "Estado de la prueba",
        serverAddress: "Dirección del servidor",
        lastActive: "Activo por última vez",
      },
      proxies: "{n} proxy | {n} proxies",
      statuses: {
        passed: "Aprobada",
        notTested: "No probada",
        pending: "Pendiente",
        failed: "Fallida",
      },
      actions: {
        testDomain: "Prueba",
        editDomain: "Editar",
        removeDomain: "Eliminar",
      },
      noOutgoingEmailsTooltip: "No hay correos electrónicos salientes en las últimas 12 horas",
      failedReason: {
        CORO_MX_IS_NOT_PRIMARY:
          "The domain's primary MX is not recognized as a Coro SMTP proxy server.<br>Verify your DNS settings.",
        PROXY_POSTFIX_RELAY_HOST_NOT_REACHABLE:
          "Coro cannot reach your SMTP relay host.<br>Verify the host and port settings.",
        PROXY_POSTFIX_SETTINGS_INACTIVE:
          "The SMTP relay host is inactive.<br>Contact Coro Support to activate it.",
        GENERAL_VERIFICATION_FAIL:
          "Inbound Gateway domain setup failed.<br>Verify your settings and try again.",
        PROXY_VERIFICATION_TIMEOUT:
          "The verification message did not reach the Coro SMTP proxy server.<br>Verify your SMTP relay host settings.",
        USER_VERIFICATION_TIMEOUT:
          "The verification email approval time has expired.<br>Verify the recipient and repeat the test.",
      },
    },
    noItems: {
      inbound: {
        description: "Añadir dominios de correo electrónico entrante",
        subDescription: "El proxy no se habilitará si no se añaden dominios",
      },
      outbound: {
        description: "Añada sus dominios de correo electrónico saliente",
        subDescription: "El proxy no se habilitará si no se añaden dominios",
      },
    },
    verification: {
      successMessage:
        "Se ha completado el proceso de verificación del servidor proxy del correo electrónico.",
      failureMessage1: "Se ha producido un error :(",
      failureMessage2:
        "Parece que el tráfico de su correo electrónico entrante no pasa a través del proxy de Email Security de Coro.",
      linkExpiredMessage1: "El enlace de verificación del proxy de Inbound Gateway ha vencido.",
      linkExpiredMessage2: "Vuelva a enviar el correo electrónico de prueba e inténtelo de nuevo.",
    },
  },
  cloudApplications: {
    title: "Aplicaciones en la nube",
    description: "Monitor and control access to cloud applications and data",
    boxEnforcement: "Box",
    googleDirectoryEnforcement: "Google Workspace",
    googleDirectoryEnforcementAlias: "G-Suite",
    dropboxEnforcement: "Dropbox",
    office365Enforcement: "Microsoft 365",
    emailProxyEnforcement: "email proxy",
    slackEnforcement: "Slack",
    salesforceEnforcement: "Salesforce",
    zoomEnforcement: "Zoom",
    noServicesToAdd: "No hay servicios para añadir",
    google: "Google",
    coronet: "Coro",
    connect: "Conectar",
    cloudApplication: "Aplicación en la nube",
    connectCloudApplication: "Conectar aplicación en la nube",
    numberConnected: "{n} se ha conectado",
    numberProtectedUsers: "{n} usuario | {n} usuarios",
    activeUsers: "{n} activo",
    inactiveUsers: "{n} inactivo",
    thirdPartyApps: "Aplicaciones de terceros",
    protectedUsers: "Usuarios protegidos",
    connectionStatus: "Estado de la conexión",
    servicesFound: "{n} servicio | {n} servicios",
    connected: "Conectado",
    disconnected: "Desconectado",
    disabled: "Deshabilitado",
    incomplete: "Incompleto",
    notConnected: "No conectado",
    hubspotEnforcement: "Hubspot",
    oktaEnforcement: "Okta",
    permissions: "Permisos",
    grantPermissions: "Otorgar permisos",
    connectionStatusValues: {
      [ServiceStatus.CONNECTED]: "Conectado",
      [ServiceStatus.CONNECTED_NOT_SECURE]: "Conectado (no seguro)",
      [ServiceStatus.NOT_CONNECTED]: "Desconectado",
    },
    buttons: {
      connectService: "Conectar",
      disconnectService: "Desconectar",
      continue: "Continuar",
      access: "Permisos de acceso",
      removeService: "Eliminar",
    },
  },
  service: {
    zoomEnforcement: {
      notice1:
        "Para configurar la gobernanza de Zoom, el administrador del sistema debe asegurarse de que la cuenta del administrador de Zoom\ntenga privilegios administrativos para leer, escribir y reubicar activos en la aplicación.",
      notice2:
        "Los permisos solicitados le permiten utilizar Coro para supervisar el acceso de usuarios y grupos de Zoom y las actividades de servicio.",
    },
    hubspotEnforcement: {
      notice1:
        "Para configurar la gobernanza de Hubspot, el administrador del sistema debe asegurarse de que la cuenta del administrador de Hubspot\ntenga privilegios administrativos para leer, escribir y reubicar activos en la aplicación.",
      notice2:
        "Los permisos solicitados le permiten usar Coro para supervisar el acceso de usuarios y grupos de Hubspot y las actividades de servicio.",
    },
    oktaEnforcement: {
      notice1:
        "Para configurar la gobernanza de Okta, el administrador del sistema debe asegurarse de que la cuenta del administrador de Okta\ntenga privilegios administrativos para leer, escribir y reubicar activos en la aplicación.",
      notice2:
        "Los permisos solicitados le permiten utilizar Coro para supervisar el acceso de usuarios y grupos de Okta y las actividades de servicio.",
    },
    dropboxEnforcement: {
      stepOne: {
        title: "Autorice la aplicación de gestión del equipo",
        description:
          'Autorice que Coro (aplicación "Coro Team Management") acceda a los miembros de su equipo.',
      },
      stepTwo: {
        title: "Autorice la aplicación de acceso a archivos del equipo",
        description:
          'Autorice que Coro (aplicación "Coro File Access") acceda a los archivos de los miembros de su equipo.',
      },
    },
    permissionsForGoogleDirectoryEnforcement: {
      step2: {
        title: "Editar ID de cliente",
        description1:
          "Ve a <a class='coro-link' href='https://admin.google.com/ac/owl/domainwidedelegation' target=\"_blank\">https://admin.google.com/ac/owl/domainwidedelegation</a> para realizar esta tarea.",
        description2:
          "Reemplaza los alcances en <span class='description-bold'>Alcances de OAuth</span> con los siguientes, incluidos los comas:",
        description3: 'Selecciona "Autorizar"',
      },
    },
    googleDirectoryEnforcement: {
      step1: {
        title: "Compruebe que es administrador de Google",
        description: "Inicie sesión en Google Workspace con una cuenta de administrador.",
        descriptionError: {
          notAdmin: "Tenemos que comprobar que {email} es una cuenta de administrador de Google.",
          alreadyConnected:
            "Si tienes acceso a la <a href='https://admin.google.com/u/1/ac/owl/list?tab=apps' target=\"_blank\">consola de administración de Google</a> de {email}, puede anular el acceso a la aplicación Coro e intentarlo de nuevo.",
          generic:
            "Se ha producido un error al conectarse a la cuenta seleccionada; póngase en contacto con <a href='https://docs.coro.net/' target=\"_blank\">el equipo de asistencia de Coro</a>.",
          genericWithoutCoro:
            "Se ha producido un error al conectarse a la cuenta seleccionada; póngase en contacto con el equipo de asistencia.",
        },
      },
      step2: {
        title: "Registrar Coro como cliente de API con Google",
        description1:
          "Vaya a <a href='https://admin.google.com/ac/owl/domainwidedelegation' target=\"_blank\">https://admin.google.com/ac/owl/domainwidedelegation</a>.",
        description2:
          "Haga clic en «<span class='description-bold'>añadir nuevo</span> cliente de API».",
        description3:
          "En <span class='description-bold'>ID del cliente</span>, copie y pegue el siguiente ID:",
        description4:
          "En <span class='description-bold'>ámbitos de OAuth</span>, copie y pegue los siguientes ámbitos, incluidas las comas:",
        description5: "<span class='description-bold'>Autorice </span>el nuevo ID del cliente.",
      },
      step3: {
        title: "Compruebe que la API del SDK de la unidad esté habilitada",
        description:
          'Vaya a <a href="https://admin.google.com/u/5/ac/appsettings/55656082996/data" target="_blank">https://admin.google.com/u/5/ac/appsettings/55656082996/data</a>.<br>Compruebe que la casilla «Permitir que los usuarios accedan a Google Drive con la API del SDK de la unidad» esté <b>seleccionada</b>.',
      },
      step4: {
        title: "Instale del dominio de Coro en Google Workspace Marketplace",
        description:
          'Vaya a <a href"https://workspace.google.com/marketplace/app/coronet_addon/98372960675" target="_blank">https://workspace.google.com/marketplace/app/coronet_addon/98372960675</a>.<br>Haga clic en «<b>instalar dominio</b>».',
      },
      successText: "Success! Google Workspace is connected",
    },
    salesforceEnforcement: {
      notice1:
        "Para configurar la gobernanza de Salesforce, el administrador del sistema debe asegurarse de que la cuenta del administrador de Salesforce\ntenga privilegios administrativos para leer, escribir y reubicar activos en la aplicación.",
      notice2:
        "Los permisos solicitados le permiten utilizar Coro para supervisar el acceso de usuarios y grupos de Salesforce y las actividades de servicio, detectar fugas de datos y malware en su correo electrónico y almacenamiento de archivos y mitigar amenazas.",
    },
    slackEnforcement: {
      notice1:
        "Debido a ciertas limitaciones impuestas por Slack con servicios de terceros como Coro, la protección de Coro ahora está disponible solo para clientes con planes <b>Slack Business+</b> y <b>Slack Enterprise Grid</b>.",
      notice2:
        "Al conectarse, autoriza a Coro a supervisar el acceso de los usuarios y de grupos de Slack y las actividades de servicio, detectando filtraciones de datos y malware en su correo electrónico y en su almacenamiento de archivos, y mitigando las amenazas.",
    },
    office365Enforcement: {
      stepOne: {
        accessDenied: "Se ha producido un error; inténtelo de nuevo",
        error: "La cuenta no está asociada con ninguna función de administrador",
        accountAlreadyExist:
          "Servicio ya conectado. Para continuar, contacte con los administradores <span class='text--link'>{admins}</span>.",
        title: "Conectar Coro a Microsoft 365",
        description:
          "Al conectarse, autoriza a Coro a supervisar el acceso de usuarios y grupos de Microsoft 365 y las actividades de servicio, detectando filtraciones de datos y malware en su correo electrónico y en su almacenamiento de archivos, y mitigando amenazas.",
        descriptionError:
          "Necesitamos comprobar que su cuenta sea una cuenta de administrador de Microsoft 365.",
      },
      stepTwo: {
        title:
          "Compruebe que el almacenamiento de registros de auditoría de Microsoft 365 esté habilitado",
        description:
          "Vaya a <a href='https://compliance.microsoft.com/auditlogsearch' target=\"_blank\">https://compliance.microsoft.com/auditlogsearch</a> para realizar esta tarea.",
      },
      successText: "¡Genial! Microsoft 365 está conectado",
    },
    boxEnforcement: {
      stepOne: {
        title: "Crear y configurar la aplicación Box",
        description:
          "Para ver una descripción paso a paso del proceso, consulte nuestro <a href='https://docs.coro.net/protection/connecting-to-box/' target=\"_blank\">artículo sobre ayuda y documentación</a>.",
      },
      stepTwo: {
        title:
          "Suba el archivo de configuración del par de claves pública y privada que ha descargado en el paso 1",
        error: "La cuenta no está asociada con ninguna función de administrador",
        label: "Subir el archivo de configuración",
      },
      successText: "¡Genial! Box está conectado",
    },
    errors: {
      notAdmin: "{email} no está asociado con ninguna función de administrador",
      alreadyConnected: "Ya hay otra cuenta de Coro utilizando {email}",
      generic: "¡Vaya! Se ha producido un error",
    },
  },
  serviceStatuses: {
    active: "Activo",
    suspendedByCoronet: "Retirado por Coro",
    suspendedByService: "Retirado por el servicio",
    invited: "Invitado",
    deleted: "Eliminado",
    inactive: "Inactivo",
  },
  cloudAppsSettings: {
    connect: "Conectar",
    connectionCompleted: "¡Excelente! Ahora su {service} se ha conectado.",
    noCloudApps: {
      title: "Añada sus aplicaciones en la nube a la protección de Coro",
      description:
        "Supervise las cuentas de sus aplicaciones empresariales en la nube para proteger su entorno y cumplir \ncon las normas de privacidad.",
      connectBtn: "Conectar aplicaciones en la nube",
      modalDescription: "Seleccione la aplicación en la nube que quiere conectar",
    },
  },
  connectors: {
    title: "Conexiones",
    tabs: {
      siem: "SIEM",
      apiKeys: "Credenciales de API",
      psa: "PSA",
      webhooks: "Webhooks",
    },
    siem: {
      add: "Añadir conexión",
      description: "Exporte datos a sistemas externos para añadir datos de seguridad.",
      table: {
        headers: {
          name: "Nombre",
          status: "Estado",
        },
        actions: {
          edit: "Editar",
          delete: "Eliminar",
          sync: "Sincronizar",
        },
      },
      format: {
        splunk: "Splunk",
        azureSentinel: "Azure Sentinel",
        fluency: "Fluency",
        generic: "Genérico",
      },
      noItems: {
        description:
          "Actualmente los datos de su Workspace de Coro no se exportan a sistemas de terceros",
        subDescription:
          "Añada conexiones a sistemas externos de información de seguridad y gestión de eventos",
      },
    },
    webhooks: {
      add: "Crear webhook",
      description: "Exporte datos a sistemas externos para añadir datos de seguridad.",
      status: {
        [WebhookStatus.ENABLED]: "Activo",
        [WebhookStatus.DISABLED]: "Deshabilitado",
        [WebhookStatus.DISCONNECTED]: "Desconectado",
        [WebhookStatus.INACTIVE]: "Deshabilitado",
        [WebhookStatus.ERROR]: "Error",
      },
      testStatus: {
        [WebhookTriggerTestStatus.SUCCESS]: "Aprobada",
        [WebhookTriggerTestStatus.FAILURE]: "Fallida",
        notTested: "No probada",
      },
      error: {
        title: "Respuesta fallida de la URL del webhook.",
        subtitle: "Compruebe que la URL se ha configurado correctamente y vuelva a probar.",
        retest: "Volver a probar",
      },
      expirationLabels: {
        expired: "Vencida",
        expiresIn: "Vence {n}",
      },
      triggerLabels: {
        newVersion: "Nueva versión",
        aboutToExpire: "Vence {expirationDate}",
        expired: "Vencida",
      },
      alerts: {
        aboutToExpire: {
          title:
            "Acción necesaria: actualice su webhook | Acción necesaria: actualice sus webhooks",
          body: "Hay disponible una nueva versión del activador <b>«{name}»</b>. Para mantener su webhook activo, seleccione <b>Editar</b> en el menú de tres puntos y configúrelo para el {expirationDate}.",
        },
        expired: {
          title: "Ahora el webhook «{name}» está deshabilitado",
          body: "Seleccione <b>Editar</b> en el menú de tres puntos para configurar y reactivar esta funcionalidad.",
        },
        triggerAboutToExpire: {
          title: "Hay disponible un activador nuevo",
          body: "Coro recomienda seleccionarlo junto con el activador de vencimiento para realizar pruebas. <br><b>Antes de que venza el activador anterior, desmárquelo para prevenir cualquier interrupción.</b>",
        },
        triggerExpired: {
          title: "Un activador ha vencido y ahora el webhook está desactivado.",
          body: "Desmarque el activador vencido y seleccione un activador nuevo para reactivar el webhook.",
        },
      },
      table: {
        headers: {
          name: "Nombre",
          status: "Estado",
          created: "Creado",
          expires: "Vence",
          appliedToChildWorkspace: "Aplicado al Workspace secundario",
        },
        sections: {
          description: "Descripción",
          authenticationUrl: "URL de autenticación",
          secret: "Secreto",
          authorization: "Autorización",
          trigger: "Activar",
          lastUsed: "Utilizado por última vez",
          testStatus: "Estado de la prueba",
          action: "Acción",
          header: "Encabezado",
          value: "Valor",
        },
        actions: {
          [WebhookAction.EDIT]: "Editar",
          [WebhookAction.TEST]: "Prueba",
          [WebhookAction.DEACTIVATE]: "Deshabilitar",
          [WebhookAction.ACTIVATE]: "Activar",
          [WebhookAction.REMOVE]: "Eliminar",
        },
      },
      noItems: {
        description: "Actualmente no hay webhooks configurados en este Workspace",
        subDescription: "Crear un webhook para este Workspace.",
      },
      webhookTriggers: {
        [WebhookTriggerType.WORKSPACE_CREATED]: "Workspace creado",
        [WebhookTriggerType.WORKSPACE_STATUS_UPDATED]: "Estado del Workspace actualizado",
        [WebhookTriggerType.SUBSCRIPTION_MODULES_UPDATED]: "Módulos de suscripción actualizados",
        [WebhookTriggerType.SUBSCRIPTION_ADDONS_UPDATED]:
          "Complementos de suscripción actualizados",
        [WebhookTriggerType.SUBSCRIPTION_BUNDLES_UPDATED]: "Paquetes de suscripción actualizados",
        [WebhookTriggerType.PROTECTED_USERS_ADDED]: "Usuarios protegidos añadidos",
        [WebhookTriggerType.PROTECTED_USERS_UPDATED]: "Usuarios protegidos actualizados",
        [WebhookTriggerType.PROTECTED_USERS_DELETED]: "Usuarios protegidos eliminados",
        [WebhookTriggerType.PROTECTED_USERS_COUNT_UPDATED]:
          "Número de usuarios protegidos actualizado",
        [WebhookTriggerType.PROTECTED_DEVICES_UPDATED]: "Dispositivos protegidos actualizados",
        [WebhookTriggerType.PROTECTED_DEVICES_ADDED]: "Dispositivos protegidos añadidos",
        [WebhookTriggerType.PROTECTED_DEVICES_DELETED]: "Dispositivos protegidos eliminados",
        [WebhookTriggerType.PROTECTED_DEVICES_COUNT_UPDATED]:
          "Número de dispositivos protegidos actualizado",
        [WebhookTriggerType.SUBSCRIPTION_UPDATED]: "Suscripción actualizada",
      },
    },
    psa: {
      title: "Conexiones de PSA",
      add: "Añadir conexión",
      connector: "Conexión",
      workspaceMapping: "Asignación de Workspaces",
      serviceMapping: "Asignación de servicios",
      ticketing: "Emisión de tickets",
      setupRequired: "Configuración obligatoria",
      nOfm: "{first} de {second}",
      mappingSelectPlaceholder: "Asignar",
      comingSoon: "Próximamente",
      clearMapping: "Borrar asignación",
      actions: {
        edit: "Editar conexión",
        disable: "Desconectar",
        connect: "Conectar",
      },
      provider: {
        gradient: "Gradient",
        connectwise: "ConnectWise",
        autotask: "AutoTask",
      },
      status: {
        disconnected: "Desconectado",
        connected: "Conectado",
        configSuspended: "Desconectado",
        configNotFound: "Configuración obligatoria",
      },
      activeWorkspaces: "Workspaces activos",
      noItems: {
        description: "Conecte sus Workspaces a los sistemas de PSA",
        subDescription: "Añada conexiones a su entorno de PSA.",
      },
      connectwiseWorkspaceMapping: {
        title: "Asignar Workspaces a empresas de ConnectWise",
      },
      connectwiseServiceMapping: {
        title: "Asignar módulos y complementos a los servicios de ConnectWise",
      },
      autotaskWorkspaceMapping: {
        title: "Asignar Workspaces a empresas de AutoTask",
      },
      autotaskServiceMapping: {
        title: "Asignar módulos y complementos a los servicios de AutoTask",
      },
      addPsaConnector: {
        title: "Añadir conexión de PSA",
        description: "Seleccione la conexión que quiere conectar",
        gradient: {
          label: "Clave de API de Gradient",
          placeholder: "Introduzca su clave de API de Gradient",
          description:
            "A través de Gradient, puede conectar su Workspace de Coro a la mayoría de los sistemas de PSA que haya en uso.",
        },
        connectwise: {
          subtitle: "Información de integración de la API de ConnectWise",
          description: "Crear una nueva conexión de ConnectWise",
        },
        autotask: {
          subtitle: "Información de integración de la API de AutoTask",
          description: "Crear una nueva conexión de AutoTask",
        },
        actionButton: "Add",
      },
      products: {
        [CoroProductNames.CLOUD_SECURITY]: "Cloud security",
        [CoroProductNames.ENDPOINT_SECURITY]: "Endpoint security",
        [CoroProductNames.EMAIL_SECURITY]: "Email security",
        [CoroProductNames.USER_DATA_GOVERNANCE]: "User data governance",
        [CoroProductNames.ENDPOINT_DATA_GOVERNANCE]: "Endpoint data governance",
        [CoroProductNames.EDR]: "EDR",
        [CoroProductNames.NETWORK]: "Network",
        [CoroProductNames.MDM]: "MDM",
        [CoroProductNames.INBOUND_GATEWAY]: "Inbound gateway",
        [CoroProductNames.SECURED_WEB_GATEWAY]: "Secured web gateway",
        [CoroProductNames.WIFI_PHISHING]: "WiFi phishing",
        [CoroProductNames.SECURED_MESSAGES]: "Secured messages",
        [CoroProductNames.MDR]: "MDR",
        [CoroProductNames.SOC_CLOUD_SECURITY]: "Servicio gestionado: Cloud security",
        [CoroProductNames.SOC_ENDPOINT_SECURITY]: "Servicio gestionado: Endpoint security",
        [CoroProductNames.SOC_EMAIL_SECURITY]: "Servicio gestionado: Email security",
        [CoroProductNames.SOC_USER_DATA_GOVERNANCE]: "Servicio gestionado: User data governance",
        [CoroProductNames.SOC_ENDPOINT_DATA_GOVERNANCE]:
          "Servicio gestionado: Endpoint data governance",
        [CoroProductNames.SOC_NETWORK]: "Servicio gestionado: Network",
        [CoroProductNames.ZERO_TRUST_NETWORK_ACCESS]: "Zero trust network access",
        [CoroProductNames.OUTBOUND_GATEWAY]: "Outbound gateway",
      },
    },
    apiKeys: {
      add: "Crear credenciales de API",
      expired: "Vencida",
      table: {
        clientId: "ID del cliente",
        expirationTime: "Vence",
        createdTime: "Creado",
        lastUsedTime: "Utilizado por última vez",
        description: "Descripción",
      },
      noItems: {
        description: "Actualmente no se han creado credenciales de API",
        subDescription: "Cree unas credenciales para obtener acceso a la API de Coro",
      },
    },
  },
  connectwise: {
    tabs: {
      workspaceMapping: "Asignación de Workspaces",
      serviceMapping: "Asignación de servicios",
      ticketing: "Emisión de tickets",
    },
  },
  workspaceMapping: {
    statusSelectPlaceholder: "Estado",
    companySelectPlaceholder: "Asignar",
    coroWorkspace: "Workspace de Coro",
    connectwiseCompany: "Empresa de ConnectWise",
    autotaskCompany: "Empresa de AutoTask",
    searchForWorkspaces: "Buscar Workspaces...",
    mappingFilterType: {
      mapped: "Asignado",
      unmapped: "No asignado",
      all: "Todo",
    },
  },
  serviceMapping: {
    statusSelectPlaceholder: "Estado",
    companySelectPlaceholder: "Asignar",
    coroModules: "Módulos o complementos de Coro",
    connectwiseServices: "Servicios de ConnectWise",
    autotaskServices: "Servicios de AutoTask",
    searchForModules: "Buscar módulos...",
    mappingFilterType: {
      mapped: "Asignado",
      unmapped: "No asignado",
      all: "Todo",
    },
  },
  betaChip: {
    title: "Beta",
    tooltip: "Esta función está disponible hasta {n} en la fase beta",
  },
  betaBanner: {
    title: "Le damos la bienvenida a la versión beta de {addonOrModule}",
    subtitle: "Esto es lo que tiene que saber:",
    limitedSupport: "Asistencia limitada",
    limitedSupportDescription: "El servicio de asistencia en esta fase es mínimo.",
    potentialBugs: "Posibles errores",
    potentialBugsDescription:
      "Su opinión es muy importante. Si encuentra algún problema, escríbanos a <span class='coro-link'>support@coro.net.</span>",
    notForProduction: "No se ha preparado para producción",
    notForProductionDescription:
      "Esta función aún no está lista para su uso en producción completa o para aplicaciones de misión crítica.",
    constantImprovements: "Mejoras constantes",
    constantImprovementsDescription:
      "Algunos aspectos pueden cambiar a medida que afinemos y mejoremos los componentes.",
    releaseUpdates: "Actualizaciones de la versión",
    releaseUpdatesDescription:
      "Esté pendiente a las noticias oficiales sobre la versión. Le informaremos si se produce algún cambio.",
  },
  accessPermissions: {
    title: "Permisos de acceso de {service}",
    description: "Permitir el acceso a {service} desde países o direcciones IP específicos",
    addRestriction: "Nuevos permisos",
    noItems: {
      description:
        "Actualmente sus usuarios protegidos no tienen permisos específicos para acceder a {service}",
      subDescription: "Permitir el acceso a {service} desde países o direcciones IP específicos",
    },
    types: {
      all: "Todos los usuarios",
      group: "Grupos específicos",
      user: "Usuarios específicos",
    },
    actions: {
      editAccessPermissions: "Editar",
      deleteAccessPermissions: "Eliminar",
    },
    table: {
      allUsers: "Todos los usuarios",
      headers: {
        groups: "Grupos",
        restrictions: "Países/IP permitidos",
        description: "Descripción",
        remediation: "Corrección automática",
      },
      remediationTypes: {
        none: "Nada",
        signin: "Cerrar sesión",
        suspend: "Retirar usuario",
      },
    },
  },
  mobileActivation: {
    success: {
      title: "Todo está listo",
      subtitle:
        "La activación se ha completado correctamente. Abra la aplicación de Coro en su dispositivo móvil para consultar el estado de su conexión VPN.",
      mobileSubtitle: "Activación completada correctamente",
      openApp: "Abrir aplicación",
    },
    failure: {
      title: "Error de activación",
      mobileSubtitle: "Error en la activación de su cuenta",
      resendButton: "Reenviar enlace de activación",
      errors: {
        [ActivateMobileErrorType.GENERIC]: "Error en la activación de su cuenta",
        [ActivateMobileErrorType.ACTIVATION_LINK_EXPIRED]: "El enlace de activación ha vencido",
        [ActivateMobileErrorType.PART_OF_WORKSPACE]: "Ya forma parte de {workspace}",
        [ActivateMobileErrorType.WORKSPACE_IS_NOT_PROTECTED]:
          "Se ha eliminado este usuario de la protección, por lo que no se puede unir al Workspace. <br>Póngase en contacto con su administrador para más información.",
        [ActivateMobileErrorType.WORKSPACE_ARCHIVED]:
          "El Workspace asociado a esta dirección de correo electrónico se ha archivado, por lo que no se puede unir al Workspace. <br> Póngase en contacto con su administrador para más información.",
        [ActivateMobileErrorType.NETWORK_MODULE_DISABLED]:
          "El módulo Network asociado a esta dirección de correo electrónico está deshabilitado, por lo que no se puede unir al Workspace. <br> Póngase en contacto con su administrador para más información.",
      },
    },
    activationLinkSent: {
      title: "Revise su correo electrónico",
      subtitle:
        "Hemos enviado un enlace de activación a {name}. Seleccione el Workspace al que desea unirse para activar su cuenta.",
      mobileSubtitle:
        "Hemos enviado un enlace de activación a {name}. Seleccione el Workspace al que desea unirse para activar su cuenta.",
    },
  },
  usersPage: {
    title: "Usuarios protegidos",
    selected: "{n} seleccionado",
    dynamicTitle: "Usuarios protegidos: {n}",
    usersQuantity: "{n} usuario | {n} usuarios",
    noData: "No se han encontrado usuarios",
    none: "Nada",
    noIssues: "No hay problemas",
    protectedSince: "Protegido desde",
    protectedCloudApps: "Aplicaciones en la nube protegidas",
    noProtectedCloudApps: "El usuario no aparece en ninguna aplicación en la nube protegida",
    protectedDevices: "Dispositivos protegidos",
    noProtectedDevices: "Actualmente ningún dispositivo está protegido",
    tableHeaders: {
      user: "Usuario",
      vulnerability: "Vulnerabilidad",
    },
    actions: {
      viewDetails: "Ver información",
      markAsProcessed: "Cerrar todos los tickets",
      suspendFromAll: "Retirar al usuario de todas las aplicaciones en la nube",
      signInToAll: "Solicitar al usuario que inicie sesión en todas las aplicaciones en la nube",
    },
    criticalIssues: "Problemas críticos",
  },
  subscriptions: {
    plans: {
      [SubscriptionType.FREEZE]: "Inactivo",
      [SubscriptionType.SUBSCRIPTION]: "Suscripción",
      [SubscriptionType.TRIAL]: "Prueba",
      [SubscriptionType.INITIAL]: "Nuevo",
      [SubscriptionType.ARCHIVED]: "Archivado",
    },
    bundles: {
      title: "Paquetes",
      [SubscriptionBundle.ENDPOINT_PROTECTION]: "Endpoint Protection",
      [SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION]: "Managed Endpoint Protection",
      [SubscriptionBundle.EMAIL_PROTECTION]: "Email Protection",
      [SubscriptionBundle.MANAGED_EMAIL_PROTECTION]: "Managed Email Protection",
      [SubscriptionBundle.SASE]: "SASE",
      [SubscriptionBundle.MANAGED_SASE]: "Managed SASE",
      [SubscriptionBundle.CORO_ESSENTIALS]: "Coro Essentials",
      [SubscriptionBundle.MANAGED_CORO_ESSENTIALS]: "Managed Coro Essentials",
      [SubscriptionBundle.CORO_CLASSIC]: "Coro Classic",
      [SubscriptionBundle.MANAGED_CORO_CLASSIC]: "Managed Coro Classic",
      [SubscriptionBundle.CORO_COMPLETE]: "Coro Complete",
      [SubscriptionBundle.MANAGED_CORO_COMPLETE]: "Managed Coro Complete",
    },
    modules: {
      title: "Módulos",
      [SubscriptionModule.CLOUD_SECURITY]: "Cloud Security",
      [SubscriptionModule.ENDPOINT_SECURITY]: "Endpoint Security",
      [SubscriptionModule.EMAIL_SECURITY]: "Email Security",
      [SubscriptionModule.USER_DATA_GOVERNANCE]: "User Data Governance",
      [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: "Endpoint Data Governance",
      [SubscriptionModule.EDR]: "EDR",
      [SubscriptionModule.MDM]: "MDM",
      [SubscriptionModule.NETWORK]: "Network",
    },
    addons: {
      title: "Complementos",
      [SubscriptionAddon.SOC]: "Servicio gestionado",
      [SubscriptionAddon.SOC_CLOUD]: "Servicio gestionado: Cloud Security",
      [SubscriptionAddon.SOC_ENDPOINT]: "Servicio gestionado: Endpoint Security",
      [SubscriptionAddon.SOC_EMAIL]: "Servicio gestionado: Email Security",
      [SubscriptionAddon.SOC_USER_DATA_GOVERNANCE]: "Servicio gestionado: User Data Governance",
      [SubscriptionAddon.SOC_ENDPOINT_DATA_GOVERNANCE]:
        "Servicio gestionado: Endpoint Data Governance",
      [SubscriptionAddon.SOC_NETWORK]: "Servicio gestionado: Network",
      [SubscriptionAddon.MDR]: "MDR",
      [SubscriptionAddon.WIFI_PHISHING]: "Wi-Fi Phishing",
      [SubscriptionAddon.SECURED_MESSAGES]: "Secure Messages",
      [SubscriptionAddon.INBOUND_GATEWAY]: "Inbound Gateway",
      [SubscriptionAddon.OUTBOUND_GATEWAY]: "Outbound Gateway",
      [SubscriptionAddon.SWG]: "Secure Web Gateway",
      [SubscriptionAddon.ZTNA]: "Zero Trust Network Access",
    },
    moduleIncludedInBundle:
      "Este módulo ya está incluido en uno de los paquetes que ha seleccionado",
    addonIncludedInBundle:
      "Este complemento ya está incluido en uno de los paquetes que ha seleccionado",
    usDataCenterOnly: "Solo centro de datos de EE. UU.",
    packages: "Paquetes",
  },
  emailSettings: {
    title: "Email Security",
    tabs: {
      settings: "Configuración",
      allowBlockList: "Permitir o bloquear",
      addIns: "Complementos",
      coroMail: "Secure Messages",
      inboundGateway: "Inbound Gateway",
    },
    settingsTab: {
      realTimeSecurity:
        "Analizar correos electrónicos para buscar los siguientes tipos de amenazas",
      scanBypass: "Permitir que los correos electrónicos omitan el análisis",
      securityMode: "Modo de seguridad",
      sensitivityLevel: "Sensitivity level",
      sensitivityLevelDescription: "Adjust the sensitivity for detecting email threats.",
      sensitivityLevels: {
        [ScanSensitivityLevels.LOW]: "Bajo",
        [ScanSensitivityLevels.MEDIUM]: "Medio",
        [ScanSensitivityLevels.HIGH]: "Alto",
      },
      securityModeInfoText:
        "CUARENTENA: se eliminan los correos electrónicos en los que se detecta malware. Los correos electrónicos que contienen spam o suplantación de identidad se ponen en cuarentena. Si Inbound Gateway se ha habilitado, todos los correos electrónicos sospechosos se ponen en cuarentena de forma segura." +
        "<br/><br/>" +
        "AVISAR AL DESTINATARIO: los correos electrónicos sospechosos no se bloquean, sino que incluyen una etiqueta o un banner de advertencia.",
      securityModes: {
        [SecurityMode.QUARANTINE_EMAIL]: "Poner en cuarentena",
        [SecurityMode.WARN_RECIPIENT]: "Advertir al destinatario",
      },
      threatType: "Tipo de amenaza",
      realTimeSecurityDisabled: "Seguridad en tiempo real deshabilitada",
      scanMalware: "Malware en archivos adjuntos de correo electrónico",
      scanSpam: "Correo no deseado",
      scanPhishing: "Phishing sospechoso",
      scanNewlyRegisteredDomains: "Nuevos dominios registrados",
      scanEncryptedAttachments: "Archivo adjunto cifrado",
      scanUnverifiedSender: "Remitente no verificado",
      scanUnknownExternalSender: "Remitente externo desconocido",
      quarantineByAttachmentType: "Poner en cuarentena por tipo de archivo",
      subjectLine: "Subject line",
      securityAwarenessTraining: "Formación sobre concienciación en materia de seguridad",
      securityAwarenessTrainingSubtitle:
        "Permitir correos electrónicos con fines de formación sobre concienciación en materia de seguridad",
      emailSubjectKeywordsPlaceHolder: "Palabras clave del asunto",
      securityAwarenessTable: {
        configurationDescription: "{quantity} dominio o IP y | {quantity} dominios o IP y",
        headerDescription: "{quantity} encabezado | {quantity} encabezados",
        domainsOrIp: "Dominio o IP",
        headersTitle: "Encabezados",
        headers: {
          name: "Nombre",
          value: "Valor",
          configuration: "Configuración",
          date: "Fecha añadida",
        },
        actions: {
          remove: "Eliminar",
          edit: "Edit",
        },
      },
      allowList: "Lista de permitidos",
      seeAll: "Ver todos los tipos",
      seeLess: "Ver menos tipos",
      quarantineEmails:
        "Ponga en cuarentena los correos electrónicos con archivos adjuntos de estos tipos de archivos específicos",
      customFileTypes: "Tipos de archivos personalizados",
      apiBasedEmailSecurity: "Email Security basado en API",
      quarantineFolder: "Carpeta en cuarentena",
      scanBypassLabel:
        "Permitir correos electrónicos con palabras clave específicas en la línea del asunto",
      quarantineFolderDescription:
        "Los correos electrónicos se pondrán en cuarentena en esta carpeta:",
      quarantineFolderType: {
        suspected: "Carpeta dedicada de «sospechosos»",
        trash: "Carpeta de basura del sistema",
      },
      quarantineFolderInfoTooltip:
        "Conecte una o más aplicaciones en la nube a Coro para habilitar esta configuración.",
      disclaimer:
        "Para obtener protección antiphishing transparente, conecte la cuenta de Microsoft 365 o Google Workspace de su empresa, o configure la puerta de enlace de entrada del servidor proxy del correo electrónico.",
    },
    addInsSettings: {
      title: "Complementos para comentarios de los usuarios",
      description:
        "Coro recopila de forma continua comentarios directamente de los usuarios sobre clasificaciones de correo electrónico que puedan ser inexactas y los entrega para su revisión cuando este complemento de correo electrónico está instalado en los clientes de correo electrónico de sus usuarios. La instalación es muy sencilla: puede hacer la instalación de forma simultánea para todos los usuarios actuales (y futuros).",
      installFor: "Instalar para {provider}",
    },
  },
  entityTicketsList: {
    modulesTooltip:
      "Los tickets de {modules} no están incluidos porque no tiene acceso | Los tickets de {modules} no están incluidos porque no tiene acceso",
    noPermissionsText: "No tiene permisos para acceder a los tickets",
  },
  devices: {
    defaultTitle: "Dispositivos",
    advancedTitle: "Dispositivos: {n}",
    selected: "{n} seleccionado",
    clientOutdated: "Cliente desactualizado",
    criticalIssues: "Problemas críticos",
    noIssues: "No hay problemas",
    actions: {
      [DeviceAction.EXAMINE_MALWARE]: "Examinar malware",
      [DeviceAction.ENABLE_FIREWALL]: "Habilitar cortafuegos",
      [DeviceAction.REMOTE_SCAN]: "Análisis remoto para buscar malware",
      [DeviceAction.DLP_PARTIAL_SCAN]: "Análisis remoto para buscar datos confidenciales",
      [DeviceAction.STOP_REMOTE_SCAN]: "Detener el malware del análisis remoto",
      [DeviceAction.STOP_DLP_SCAN]: "Detener el análisis para buscar datos confidenciales",
      [DeviceAction.REMOVE_FROM_PROTECTION]: "Deshabilitar protección",
      [DeviceAction.ENFORCE_UAC]: "Imponer la notificación de UAC",
      [DeviceAction.DISABLE_DEVELOPER_MODE]: "Deshabilitar modo desarrollador",
      [DeviceAction.MARK_AS_PROCESSED]: "Cerrar tickets relacionados",
      [DeviceAction.VIEW_DETAILS]: "Ver información",
      [DeviceAction.ENABLE_TAMPER_RESISTANCE]: "Habilitar protección contra manipulaciones",
      [DeviceAction.DISABLE_TAMPER_RESISTANCE]: "Deshabilitar protección contra manipulaciones",
      [DeviceAction.ALLOW_NO_ENCRYPTION]: "Permitir elementos no cifrados",
      [DeviceAction.COLLECT_LOGS]: "Recopilar registros",
      [DeviceAction.COLLECT_QUARANTINE_DATA]: "Recopilar lista en cuarentena",
      [DeviceAction.DOWNLOAD_LOGS]: "Descargar registros",
      [DeviceAction.ENABLE_NETWORK_BLOCK_MODE]: "Aislar de la red",
      [DeviceAction.DISABLE_NETWORK_BLOCK_MODE]: "Deshabilitar aislamiento de red",
      [DeviceAction.REBOOT_DEVICE]: "Reiniciar dispositivo",
      [DeviceAction.SHUTDOWN_DEVICE]: "Apagar dispositivo",
      [DeviceAction.UPDATE_AGENT_TO_LATEST]: "Actualizar agente a la última versión estable",
      [DeviceAction.ADD_LABEL]: "Añadir etiqueta",
      [DeviceAction.REMOVE_LABEL]: "Eliminar etiqueta",
      [DeviceAction.SHOW_ADVANCED_INFORMATION]: "Mostrar información avanzada",
      [DeviceAction.OPEN_REMOTE_SHELL_SESSION]: "Abrir shell remoto",
      [DeviceAction.EXPORT_TO_CSV]: "Exportar CSV",
    },
    noDevices: "No hay dispositivos conectados",
    clientVersion: "Coro",
    drives: {
      statuses: {
        [DriveEncryptionStatus.ENCRYPTED]: "Cifrado",
        [DriveEncryptionStatus.ENCRYPTED_BY_CORO]: "Cifrado por Coro",
        [DriveEncryptionStatus.NOT_ENCRYPTED]: "No cifrado",
        [DriveEncryptionStatus.FAILED]: "Fallida",
        [DriveEncryptionStatus.SUSPENDED]: "Retirada",
        [DriveEncryptionStatus.IN_PROGRESS]: "En curso",
      },
      types: {
        removable: "Extraíble",
        nonRemovable: "No extraíble",
      },
      encryptBtn: {
        windows: "Cifrar",
        osx: "Cifrar",
      },
      password: "Clave de recuperación",
      model: "Modelo",
      serialKey: "Número de serie",
      encryptionInProgress: "Cifrado en curso",
    },
    statuses: {
      none: "Nada",
      shutdown: "Apagar",
      isolated: "Aislado",
      online: "En línea",
      offline: "Desconectado",
    },
    filters: {
      osType: "Tipo de SO",
      osVersion: "Versión del SO",
      clientVersion: "Versión de Coro",
      isolationStatus: "Estado de aislamiento",
      vulnerability: "Vulnerabilidad",
      status: "Estado",
      groups: "Grupos",
      labels: "Etiquetas",
      label: {
        title: "Etiqueta",
        noData: "No existen etiquetas",
      },
    },
  },
  deviceDetails: {
    lastSeen: "Visto por última vez",
    labels: "Etiquetas",
    duplicateBanner: {
      title: "Se detectaron registros duplicados",
      description:
        "Hemos encontrado dispositivos adicionales asociados a este usuario. Si son duplicados de este dispositivo, recomendamos fusionarlos y sus registros.",
      merge: "Agrupar dispositivos",
      ignore: "Ignorar",
    },
    deviceUser: {
      title: "Usuario del dispositivo",
      status: "Estado",
      email: "Correo electrónico",
      viewUser: "Ver usuario",
      name: "Nombre",
      username: "Nombre de usuario de inicio de sesión del dispositivo",
      upn: "UPN",
      onPremiseUPN: "UPN local",
      enrollmentCode: "Código de inscripción",
      clientVersion: "Versión del cliente",
      location: "Ubicación",
      vulnerabilities: "Vulnerabilidades generales",
      licenseToShowVulnerabilities: "Dar licencia al usuario para mostrar vulnerabilidades",
      userNotLicensed: "El usuario asociado con este usuario del dispositivo no tiene licencia",
      incompliantVulnerabilities: "Vulnerabilidades no conformes",
      firstActivity: "Primera actividad",
      lastActivity: "Última actividad",
      activity: "Actividad",
      user: "Usuario",
    },
    device: {
      title: "Dispositivo",
      model: "Modelo",
      os: "SO",
      osVersion: "Versión del SO",
      subVersion: "Subversión",
      hostname: "Nombre del host",
      deviceID: "ID del dispositivo de Coro",
      type: "Tipo",
      modelAndId: "Modelo e ID",
      hostName: "Nombre del host",
      coroProtection: "Protección de Coro",
      staticIp: "Dirección IP estática de la oficina virtual",
      version: "Versión",
      added: "Añadido",
      lastKnownIp: "Última dirección IP conocida",
      lastRemoteScan: "Último análisis remoto el",
      malwareScanInProgress: "Análisis remoto en curso",
      noRemoteScans: "Aún no se han hecho análisis remotos",
      lastLogsUploadedAt: "Registros subidos el",
      modelId: "ID de modelo",
      serialNumber: "Número de serie",
      rebootNeeded: "Reinicio necesario",
      mobileId: "ID de móvil",
      username: "Nombre de usuario",
    },
  },
  coroMailSettings: {
    pageTitle: "Secure Messages",
    title: "Complementos para usar Secure Messages",
    description:
      "Usando el complemento Secure Messages de Coro, los usuarios de Outlook 365 y Gmail pueden enviar mensajes seguros directamente desde sus aplicaciones web y de correo electrónico nativas. Uso los siguientes botones para empezar.",
    coroMailPortal: {
      title: "Portal web de Secure Messages",
      description: "Enviar y recibir mensajes seguros en el portal web de Secure Messages.",
    },
    installFor: "Instalar para {provider}",
    messageSettings: "Configuración de mensajes",
    restrictMessageForwarding: "Restringir el reenvío de mensajes",
    setMessageExpiration: "Establecer periodo de caducidad del mensaje (días)",
    numberOfDays: {
      label: "Número de días",
      placeholder: "Introduzca un número de días.",
    },
  },
  usersSettings: {
    title: "Usuarios protegidos",
    excluded: "excluido",
    addUsersBtn: "Añadir usuarios",
    addGroupsBtn: "Añadir grupos",
    addExceptionsBtn: "Añadir exclusiones",
    aliases: "Alias",
    tabs: {
      users: "Usuarios protegidos",
      groups: "Grupos protegidos",
      exceptions: "Exclusiones",
    },
    noItems: {
      description:
        "Añada grupos de usuarios a la protección de Coro en lugar de añadirlos de uno en uno y ahorre tiempo",
      groupsDescription:
        "Añada los grupos de usuarios que ya ha definido en sus cuentas empresariales en la nube, como Microsoft 365 o Google Workspace, para añadir varios usuarios a la protección de Coro de forma rápida.",
      usersDescription: "Otra opción es añadir usuarios a la protección de Coro de uno en uno.",
      groupsShortDescription:
        "Añada los grupos de usuarios que ya ha definido en sus cuentas empresariales en la nube.",
    },
    actions: {
      addUsers: "Añadir usuarios específicos",
      addGroups: "Añadir grupos específicos",
      addAllUsers: "Añadir protección para todos los usuarios",
      importUsersFromCsv: "Importar de un CSV",
    },
    userGroupsTab: {
      groupsList: "Lista de grupos",
      noGroups: "No hay grupos que coincidan con esta consulta",
      tableHeaders: {
        groupName: "Nombre del grupo",
        size: "Tamaño",
        protectionGrantedTime: "Protección concedida",
      },
      actions: {
        removeGroups: "Eliminar grupo",
        removeException: "Eliminar exclusiones",
      },
      groupsSelected: "{n} seleccionado",
      removeGroupsBtn: "Eliminar {n} grupo | Eliminar {n} grupos",
    },
    usersTab: {
      noUsers: "No hay usuarios protegidos que coincidan con esta consulta",
      directMember: "Añadido de forma individual",
      addedViaCsv: "Añadido por CSV",
      excluded: "(excluido)",
      active: "Activo",
      inactive: "Inactivo",
      groups: "Grupos",
      status: {
        [UserSettingsAppStatus.ACTIVE]: "Activo",
        [UserSettingsAppStatus.INACTIVE]: "Inactivo",
      },
      tableHeaders: {
        user: "Usuario",
        joinedVia: "Se ha unido por",
        appsProtected: "Aplicaciones protegidas",
        status: "Estado",
        protectionGranted: "Protección concedida",
      },
      userActions: {
        removeUsers: "Eliminar usuario",
        addToException: "Añadir a la exclusión",
        removeException: "Eliminar exclusión",
      },
    },
    exceptionsTab: {
      exceptionsList: "Exclusiones",
      noExceptions: "No hay exclusiones que coincidan con esta consulta",
      notExcluded: "(administrador no excluido)",
      actions: {
        removeException: "Eliminar exclusión",
      },
      tableHeaders: {
        email: "Correo electrónico",
        addedBy: "Añadido por",
        addedOn: "Añadido el",
      },
    },
    filters: {
      protectedVia: "Se ha unido por",
      inboundGateway: "Inbound Gateway",
      cloudServices: "Servicios de la nube",
      status: "Estado",
      protectedApps: "Aplicaciones protegidas",
      joinedVia: {
        cloudServices: {
          [Service.BOX]: {
            subtitle: "Box grupos",
          },
          [Service.DROPBOX]: {
            subtitle: "Grupos de Dropbox",
          },
          [Service.G_SUITE]: {
            subtitle: "Grupos de Google Workspace",
          },
          [Service.OFFICE_365]: {
            subtitle: "Grupos de Microsoft 365",
          },
          [Service.SLACK]: {
            subtitle: "Grupos de Slack",
          },
          [Service.SALES_FORCE]: {
            subtitle: "Grupos de Salesforce",
          },
        },
        general: {
          subtitle: "General",
          [UserSettingsJoinedVia.ADMIN]: "Añadido por el usuario",
          [UserSettingsJoinedVia.INDIVIDUALLY]: "Añadido de forma individual",
          [UserSettingsJoinedVia.CSV]: "Añadido por CSV",
        },
        inboundGateway: "Inbound gateway",
      },
    },
  },
  manageWorkspaces: {
    title: "Gestionar Workspaces",
    bundles: {
      title: "Paquetes",
      [SubscriptionBundle.ENDPOINT_PROTECTION]: "Endpoint Protection",
      [SubscriptionBundle.MANAGED_ENDPOINT_PROTECTION]: "Endpoint Protection",
      [SubscriptionBundle.EMAIL_PROTECTION]: "Email Protection",
      [SubscriptionBundle.MANAGED_EMAIL_PROTECTION]: "Email Protection",
      [SubscriptionBundle.SASE]: "SASE",
      [SubscriptionBundle.MANAGED_SASE]: "SASE",
      [SubscriptionBundle.CORO_ESSENTIALS]: "Coro Essentials",
      [SubscriptionBundle.MANAGED_CORO_ESSENTIALS]: "Coro Essentials",
      [SubscriptionBundle.CORO_CLASSIC]: "Coro Classic",
      [SubscriptionBundle.MANAGED_CORO_CLASSIC]: "Coro Classic",
      [SubscriptionBundle.CORO_COMPLETE]: "Coro Complete",
      [SubscriptionBundle.MANAGED_CORO_COMPLETE]: "Coro Complete",
    },
    tabs: {
      mspWorkspaces: "Workspaces",
      mspAdminUsers: "Administradores de MSP",
      mspRoles: "Funciones de MSP",
    },
    table: {
      name: "Nombre",
      type: "Tipo",
      plan: "Estado",
      addons: "Complementos",
      maxProtectedUsers: "Máximo de usuarios",
      protectedDevices: "Dispositivos",
      createdOn: "Creado el",
      protectedUsers: "Usuarios",
      protectableUsers: "Potencial de usuario",
      lastActivity: "Activo por última vez",
    },
    filters: {
      type: "Tipo",
      plan: "Estado",
      addons: "Módulos",
    },
    addonTypes: {
      SOC: "Servicio gestionado",
      SOC_PRO: "Servicio gestionado profesional",
      EDR: "EDR",
      MDR: "MDR",
    },
    actions: {
      [MspWorkspaceAction.CREATE_CHANNEL_WORKSPACE]: "Crear un Workspace de canal",
      [MspWorkspaceAction.CREATE_REGULAR_WORKSPACE]: "Crear un Workspace regular",
      [MspWorkspaceAction.EDIT_WORKSPACE]: "Editar Workspace",
      [MspWorkspaceAction.VIEW_WORKSPACE]: "Ver Workspace",
      [MspWorkspaceAction.CONVERT]: "Convertir workspace",
      [MspWorkspaceAction.EXPORT_MSP]: "Exportar CSV de lista de Workspaces",
      [MspWorkspaceAction.EXPORT_MSP_SUMMARY]: "Exportar CSV de resumen de Workspaces",
      [MspWorkspaceAction.CREATE_CHILD_WORKSPACE]: "Crear un Workspace secundario",
      [MspWorkspaceAction.EDIT_SUBSCRIPTION]: "Editar suscripción",
      [MspWorkspaceAction.START_SUBSCRIPTION]: "Iniciar suscripción",
      [MspWorkspaceAction.STOP_SUBSCRIPTION]: "Cancelar suscripción",
      [MspWorkspaceAction.EXPORT_MSP_NOTIFICATIONS]: "Exportar notificaciones de administrador",
      [MspWorkspaceAction.ARCHIVE_WORKSPACE]: "Archivar Workspace",
      [MspWorkspaceAction.UNARCHIVE_WORKSPACE]: "Restaurar Workspace",
      [MspWorkspaceAction.EXTEND_TRIAL]: "Ampliar el periodo de prueba",
    },
    workspaceTypes: {
      [WorkspaceType.CHANNEL]: "Canal",
      [WorkspaceType.REGULAR]: "Regular",
      [WorkspaceType.CHILD]: "Secundario",
    },
    createDisabled: "Solo puede crear un Workspace cuando está en el workspace principal",
    noData: "No hay resultados",
    roles: {
      workspacePermissionsSectionTitle: "Workspace secundario",
      table: {
        headers: {
          role: "Función",
          assigneesCount: "N.º de administradores",
          workspace: "Workspace",
        },
        actions: {
          [MspRolesAction.ADD]: "Añadir función de MSP",
          [MspRolesAction.EDIT]: "Editar función",
          [MspRolesAction.DUPLICATE]: "Función duplicada",
          [MspRolesAction.DELETE]: "Eliminar función",
        },
      },
    },
    adminUsers: {
      table: {
        headers: {
          name: "Nombre",
          email: "Correo electrónico",
          role: "Función",
          workspace: "Workspace",
        },
        actions: {
          [MspAdminUsersAction.ADD]: "Añadir administrador de MSP",
          [MspAdminUsersAction.EDIT]: "Editar administrador de MSP",
          [MspAdminUsersAction.DELETE]: "Eliminar administrador de MSP",
        },
        you: "Usted",
      },
    },
  },
  logoUploader: {
    imageError: "La imagen no cumple con los requisitos",
    headerIcon: "Icono del encabezado",
    emailIcon: "Icono del correo electrónico",
    replaceImage: "Haga clic para reemplazar",
    description:
      "Utilice un icono cuadrado blanco sobre un fondo transparente. Máximo 500 KB. png, svg",
    emailDescription:
      "Utilice un icono cuadrado oscuro sobre fondo transparente. Máximo 500 KB. png, svg",
    imageHint:
      "Barra del encabezado: su icono del canal aparecerá en la barra de encabezado de la plataforma junto al suyo.",
    emailImageHint:
      "Encabezado del correo electrónico: el logotipo del canal aparecerá en las comunicaciones del correo electrónico junto al suyo.",
  },
  backOffice: {
    widgets: {
      overviewWidget: {
        types: {
          [BackOfficeOverviewWidgetType.WINDOWS_DEVICES]: {
            title: "Dispositivos Windows",
          },
          [BackOfficeOverviewWidgetType.MACOS_DEVICES]: {
            title: "Dispositivos macOS",
          },
          [BackOfficeOverviewWidgetType.CUSTOMERS]: {
            title: "Clientes",
          },
          [BackOfficeOverviewWidgetType.PROTECTABLE_USERS]: {
            title: "Usuarios protegibles",
          },
          [BackOfficeOverviewWidgetType.PROTECTED_USERS]: {
            title: "Usuarios protegidos",
          },
          [BackOfficeOverviewWidgetType.PROTECTED_USERS_REPORTED_TO_RECURLY]: {
            title: "Usuarios protegidos notificados de forma recurrente",
          },
          [BackOfficeOverviewWidgetType.PROTECTED_USERS_IN_PAID_WORKSPACES]: {
            title: "Usuarios protegidos en Workspaces pagados",
          },
        },
      },
      table: {
        headers: {
          coroVersion: "Versión de Coro",
          count: "Número de dispositivos",
        },
      },
    },
    manageAdminPermissions: "Gestionar permisos de administrador",
    title: "Oficina administrativa",
    beVersion: "Hash de sucursal o compromiso de BE:",
    agentsGradualUpdate: "Actualización progresiva de agentes",
    workspaceGroup: "Grupo del Workspace",
    deviceGroup: "Grupo del dispositivo",
    betaUpdateGroup: "Grupo actualizado beta",
    totalWorkspaces: "Workspaces totales",
    totalDevices: "Dispositivos totales",
    permissions: "Permisos",
    manageWorkspaceCodes: "Administrar códigos de Workspace",
    roles: {
      workspacePermissionsSectionTitle: "Permisos del Workspace",
      table: {
        headers: {
          role: "Función",
          assigneesCount: "N.º de administradores",
        },
        actions: {
          [GlobalRolesAction.ADD]: "Añadir función global",
          [GlobalRolesAction.EDIT]: "Editar función",
          [GlobalRolesAction.DUPLICATE]: "Función duplicada",
          [GlobalRolesAction.DELETE]: "Eliminar función",
        },
      },
    },
    adminUsers: {
      table: {
        headers: {
          name: "Nombre",
          email: "Correo electrónico",
          role: "Función",
        },
        actions: {
          [GlobalAdminUsersAction.ADD]: "Añadir administrador global",
          [GlobalAdminUsersAction.EDIT]: "Editar el administrador global",
          [GlobalAdminUsersAction.DELETE]: "Eliminar administrador global",
        },
        you: "Usted",
      },
    },
  },
  workspaceCodes: {
    title: "Workspace codes",
    description: "Create workspace promo code",
    subDescription: "Create workspace code which will be used during onboarding",
    type: {
      [WorkspaceCodeType.SUBSCRIPTION]: "Subscription",
      [WorkspaceCodeType.CREDIT_CARD_DETAILS]: "Credit card details",
      [WorkspaceCodeType.FIXED_DISCOUNT]: "Fixed discount",
      [WorkspaceCodeType.COMMERCIAL_TERMS]: "Commercial terms",
      [WorkspaceCodeType.TRIAL_DURATION]: "Trial duration",
      [WorkspaceCodeType.COMBINED]: "Combined",
    },
    status: {
      [WorkspaceCodeStatus.ACTIVATED]: "Active",
      [WorkspaceCodeStatus.DEACTIVATED]: "Deactivated",
    },
    actions: {
      [WorkspaceCodeActions.DELETE]: "Delete",
      [WorkspaceCodeActions.ACTIVATE]: "Activate",
      [WorkspaceCodeActions.DEACTIVATE]: "Deactivate",
      [WorkspaceCodeActions.COPY_LINK_TO_SIGNUP]: "Copy Link to Signup",
      [WorkspaceCodeActions.CREATE]: "Create workspace code",
      [WorkspaceCodeActions.EXPORT_CSV]: "Export CSV",
    },
    codeName: "Name",
    codeType: "Type",
    codeStatus: "Status",
    validTill: "Valid till",
    assignedWorkspacesCount: "Workspaces count",
    priceBookNumber: "Price book number",
    agentDetails: "Agent",
    mspDetails: "MSP",
    salesDetails: "Sales agent",
    expirationDate: "Expiration date",
    workspaceType: "Workspace type",
    relatedTo: "Related to",
    relationTypes: {
      [WorkspaceCodeRelationType.GENERAL]: "General",
      [WorkspaceCodeRelationType.AGENT]: "Agent",
      [WorkspaceCodeRelationType.MARKETING]: "Marketing",
      [WorkspaceCodeRelationType.MSP]: "MSP",
      [WorkspaceCodeRelationType.SALES]: "Sales",
    },
    modules: "Modules",
    addons: "Addons",
    bundles: "Bundles",
    commercialTerms: "Commercial terms",
    discount: "Discount",
    trialDuration: "Trial duration",
    creditCardRequired: "Credit card required",
  },
  oauthPage: {
    pleaseWait: "Espere, estamos iniciando su sesión",
  },
  rangeBundleSelector: {
    [SubscriptionBundle.ENDPOINT_PROTECTION]: {
      description:
        "Registre toda la actividad de los puntos finales, analice anomalías de datos y automatice la resolución de la mayoría de los incidentes de seguridad.",
    },
    [SubscriptionBundle.SASE]: {
      description:
        "Añada protección a los dispositivos de la empresa dondequiera que estén a través de una red en la nube que es impenetrable.",
    },
    [SubscriptionBundle.EMAIL_PROTECTION]: {
      description:
        "Analice los correos electrónicos para buscar amenazas y corríjalas de forma automática, reduciendo el tiempo necesario para gestionar Email Security.",
    },
    [SubscriptionBundle.CORO_ESSENTIALS]: {
      description:
        "Consiga cobertura básica para puntos finales, correo electrónico y aplicaciones en la nube y automatice la resolución de la mayoría de los incidentes de seguridad.",
    },
    [SubscriptionBundle.CORO_COMPLETE]: {
      description: "",
    },
    [SubscriptionBundle.CORO_CLASSIC]: {
      description: "",
    },
    billedAnnually: "Facturación anual",
    perUser: "Por usuario/mes",
    managed: "Gestionado",
    unmanaged: "No gestionado",
  },
  moduleOrAddonSelector: {
    [SubscriptionAddon.SOC]: {
      description:
        "Deje que nuestro equipo de servicios de seguridad gestionados se encargue de Email Security, Endpoint Security y SASE",
    },
    [SubscriptionModule.EMAIL_SECURITY]: {
      description: "Proteger contra phishing, malware y otros mensajes fraudulentos",
    },
    [SubscriptionModule.NETWORK]: {
      description: "Proteger su red con VPN en la nube, cortafuegos y grupos de oficina remota",
    },
    [SubscriptionModule.USER_DATA_GOVERNANCE]: {
      description:
        "Imponer un manejo seguro de la información identificable personal (PII) y otros datos críticos",
    },
    [SubscriptionModule.ENDPOINT_DATA_GOVERNANCE]: {
      description: "Imponer políticas de seguridad de datos en los puntos finales",
    },
    [SubscriptionModule.MDM]: {
      description: "Asegurarse de que sus dispositivos móviles están libres de amenazas",
    },
    [SubscriptionModule.ENDPOINT_SECURITY]: {
      description:
        "Establecer la posición del dispositivo, implementar NGAV y permitir o bloquear procesos",
    },
    [SubscriptionModule.CLOUD_SECURITY]: {
      description:
        "Protéjase frente al malware en las unidades en la nube y la actividad administrativa anormal",
    },
    [SubscriptionModule.EDR]: {
      description: "Reiniciar, apagar y aislar dispositivos y procesos comprometidos",
    },
    [SubscriptionAddon.WIFI_PHISHING]: {
      description:
        "Evitar que los usuarios finales se conecten a puntos finales de wifi sospechosos",
    },
    [SubscriptionAddon.SECURED_MESSAGES]: {
      description:
        "Cifrar los correos electrónicos salientes para que solo los destinatarios puedan verlos",
    },
    [SubscriptionAddon.INBOUND_GATEWAY]: {
      description: "Filtra el tráfico web para proteger su negocio",
    },
    [SubscriptionAddon.SWG]: {
      description: "Filtra el tráfico web para proteger su negocio",
    },
    [SubscriptionAddon.ZTNA]: {
      description: "Garantizar que el acceso a aplicaciones, datos y servicios sea seguro",
    },
    addonDisabled: "Seleccione el módulo <b>{module}</b> para activar este complemento",
    perUser: "por usuario",
    month: "/mes",
    managed: "Gestionado",
    unmanaged: "No gestionado",
  },
  network: {
    title: "Network",
    noData: {
      title: "Se está configurando su oficina virtual. <br> Vuelva en unos minutos.",
      subtitle:
        "Si el proceso de configuración tarda más de 10 minutos,<br> póngase en contacto con nuestro equipo de asistencia.",
      contactSupport: "Contactar con el equipo de asistencia",
    },
    virtualOffice: {
      modes: {
        ztna: "ZTNA",
        vpn: "VPN",
      },
      title: "Virtual Office",
      vpnPolicy: "VPN Policy",
      labelsAutocompleteLabel: "Device labels",
      activateFullTunnelingVpn: "Activate full-tunneling VPN",
      vpnMobileApp: "VPN Mobile APP",
      deActivateFullTunnelingVpn: "Deactivate full-tunneling VPN",
      labelsAutocompletePlaceholder: "Select labels...",
      includedExternalResources: "Included external resources",
      excludedExternalResources: "Excluded external resources",

      defaultPolicy: "Default VPN policy",
      defaultPolicyDescription:
        "By default your protected devices are not granted access to your virtual office.",
      splitTunneling: "Split-tunnelling VPN",
      fullTunneling: "Full-tunnelling VPN",
      mode1Description:
        "Use the VPN to access all networking except for any external resources listed below.",
      mode2Description:
        "Use the VPN to access your virtual office and any external resources listed below.",
      mode3Description:
        "Use the VPN to access all networking except for any external resources marked as Excluded below.",
      manualDisconnectionEnabled: "Allow manual disconnection from VPN",
      manualDisconnectionDescription:
        "By default, users cannot manually disconnect their devices from VPN and thus the VPN usage policy is enforced completely automatically.",
      blockLocalNetworkEnabled: "Block Local Area Network",
      blockLocalNetworkDescription:
        "Specify for which devices the Local Area Network should be blocked.",
      includedExcludedResources: "Include/Exclude Lists",
      excluded: "Excluded",
      included: "Included",
      allLists: "All lists",
      trafficNotThroughVpn: "Traffic not through VPN",
      trafficThroughVpn: "Traffic through VPN",
      ipAddresses: "IP address | IP addresses",
      encryptionStrength: {
        title: "Encryption strength",
        AES128: "AES-128",
        AES256: "AES-256",
        AES256Hint: "Slower, military-grade security.",
        AES128Hint: "Faster, enterprise-grade security.",
      },
      actions: {
        addUrlToIncludelist: "Add to includelist",
        addUrlToExcludelist: "Add to excludelist",
        remove: "Remove",
      },
      table: {
        domainOrIp: "Domain/IP address",
        status: "Status",
      },
      mobileBanner: {
        title: "Enable mobile app access",
        description:
          "Only protected users can use the Coro Endpoint Protection app.<br> Make sure your mobile users are included in the protected users list.",
        importList: "Import mobile users list",
      },
      ztna: {
        ztnaActivationLabel: "Activate ZTNA",
        ztnaResourceList: "Resources list",
        ztnaActivationDescription:
          "Zero trust network access (ZTNA) restricts virtual office networking to only what is permitted in the records below, blocking all other connections.",
        table: {
          headers: {
            resourceName: "Resource name",
            ipAddress: "IP address",
            ports: "Ports",
            protocol: "Protocol",
            devices: "Devices",
          },
        },
        alert: "The records below are used when ZTNA is enabled.",
        actions: {
          [ZtnaAction.DELETE_ZTNA_RULE]: "Remove resource",
          [ZtnaAction.EDIT_ZTNA_RULE]: "Edit resource",
        },
      },
    },
    secureWebGateway: {
      title: "SWG",
      dnsFilteringLabel: "Filtrado de DNS",
      dnsFilteringDescription:
        "Enable DNS filtering for <b>devices in your virtual office</b> to block access to undesired resources.",
      dnsFilteringInfo:
        "Additionally, apply DNS filtering to <b>devices outside your virtual office</b> with these labels:",
      dnsResolverLabel: "Modo anónimo del solucionador de DNS",
      labelsAutocompletePlaceholder: "Select labels...",
      labelsAutocompleteLabel: "Device labels",
      dnsResolverDescription:
        "En modo anónimo, el historial de solicitudes de DNS no se analiza ni se almacena. Solo están disponibles las estadísticas generales sobre solicitudes de DNS.",
      localDnsEnabledLabel: "Utilice el servidor local para las solicitudes de DNS locales",
      localNetwork: "Red local",
      localNetworkInputPlaceholder: "Introduzca el rango de IP en notación CIDR...",
      localNetworkInputLabel: "Dirección IP",
      localDNSServer: "Servidor DNS local",
      localDNSServerInputPlaceholder: "Introduzca la IP…",
      localDNSServerInputLabel: "Dirección IP",
      allowBlockList: "Listas de permitidos o bloqueados",
      allowBlockListDescription:
        "Los siguientes registros están activos cuando el filtrado de DNS está activado",
      allLists: "Todas las listas",
      allCategories: "Todas las categorías",
      actions: {
        addUrlToAllowlist: "Añadir a la lista de permitidos",
        addUrlToBlocklist: "Añadir a la lista de bloqueados",
        importUrlGroupToAllowlist: "Importar grupo de URL a la lista de permitidos",
        importUrlGroupToBlocklist: "Importar grupo de URL a la lista de bloqueados",
        removeUrlFromAllowlist: "Eliminar lista",
        removeUrlFromBlocklist: "Eliminar lista",
        viewUrlList: "Ver lista",
        disableUrlGroupAllowList: "Deshabilitar lista",
        disableUrlGroupBlockList: "Deshabilitar lista",
        enableUrlGroupAllowList: "Habilitar lista",
        enableUrlGroupBlockList: "Habilitar lista",
        removeUrlGroupFromAllowList: "Eliminar lista",
        removeUrlGroupFromBlockList: "Eliminar lista",
      },
      filters: {
        allowed: "Permitido",
        blocked: "Bloqueado",
        allowedDisabled: "Permitido (deshabilitado)",
        blockedDisabled: "Bloqueado (deshabilitado)",
        url: "URL",
        urlGroup: "Grupo de URL",
        lockedUrlGroup: "Grupo de URL bloqueado",
      },
      table: {
        name: "Nombre",
        action: "Acción",
        allowed: "Permitido",
        blocked: "Bloqueado",
      },
    },
    settings: {
      title: "Configuración",
      voFirewall: "Excepciones del cortafuegos de la oficina virtual",
      voFirewallDisclaimer:
        "Todos los puertos del cortafuegos de la oficina virtual están cerrados a las comunicaciones entrantes a menos que se solicite explícitamente lo contrario en un registro a continuación",
      voFirewallTable: {
        protocol: "Protocolo",
        port: "Puerto",
        allowConnection: "Permitir conexión de",
      },
      actions: {
        editVoFirewall: "Editar puerto del cortafuegos",
        deleteVoFirewall: "Eliminar puerto del cortafuegos",
      },
      tunnels: {
        title: "Túneles de sitio a sitio",
        empty: {
          title: "Añadir túnel de cortafuegos",
          description:
            "Permita que los dispositivos habilitados para Coro se conecten a recursos locales a través del cortafuegos de la empresa",
          addButton: "Añadir túnel",
        },
        addButton: "+ Añadir",
        table: {
          tunnel: "Túnel",
          siteName: "Nombre del sitio",
          remoteGateway: "Puerta de enlace remota",
          status: "Estado",
          [SiteToSiteTunnelStatus.CONNECTED]: "Conectado",
          [SiteToSiteTunnelStatus.DISABLED]: "Deshabilitado",
          [SiteToSiteTunnelStatus.DISCONNECTED]: "Desconectado",
          testState: {
            title: "Estado de la prueba",
            [SiteToSiteTunnelTestState.FAILED]: "Fallida",
            [SiteToSiteTunnelTestState.PASSED]: "Aprobada",
          },
        },
        actions: {
          [SiteToSiteTunnelsAction.TEST]: "Prueba",
          [SiteToSiteTunnelsAction.EDIT]: "Editar",
          [SiteToSiteTunnelsAction.DELETE]: "Eliminar",
          [SiteToSiteTunnelsAction.DOWNLOAD_LOG]: "Descargar registro",
          [SiteToSiteTunnelsAction.ENABLE_TUNNEL]: "Habilitar",
          [SiteToSiteTunnelsAction.DISABLE_TUNNEL]: "Deshabilitar",
        },
      },
    },
    initialSetup: {
      title: "Configurando su oficina virtual",
      selectRegionTitle: "Seleccione una región para el enrutamiento VPN global",
      selectRegionDisclaimer:
        "La selección de región es un proceso que se hace una sola vez durante la configuración.",
      selectRegionPlaceholder: "Seleccione una región",
    },
  },
  billing: {
    title: "Facturación y suscripciones",
    tabs: {
      subscription: "Suscripción",
      paymentMethod: "Método de pago",
    },
    subscriptionTab: {
      subscriptionSummary: "Resumen de la suscripción",
      planDetails: "Información del plan",
      devicesDescription:
        "Actualmente tiene una suscripción para el dispositivo <strong>{protectedDevicesCount}.</strong> | Actualmente tiene una suscripción para los dispositivos <strong>{protectedDevicesCount}.</strong>",
      usersDescription:
        "Actualmente tiene una suscripción para el usuario <strong>{protectedUsersCount}.</strong> | Actualmente tiene una suscripción para los usuarios <strong>{protectedUsersCount}.</strong>",
      updateSubscriptionDescription:
        "Puede actualizar su suscripción si sus necesidades han cambiado",
      addDevices: "Añadir dispositivos",
      addUsers: "Añadir usuarios",
      updatePlan: "Actualizar plan",
    },
  },
  messages: {
    title: "Mensajes",
    categories: {
      cloudApps: "Cloud Security",
      connectors: "Conexiones",
    },
    types: {
      [MessageType.ON_BOARDING_COMPLETED]:
        "¡Le damos la bienvenida a Coro! Su cuenta empresarial de {service} se ha conectado correctamente y\n ahora Coro se encarga de proteger su empresa. Ahora nuestros sistemas se adaptarán y optimizarán aún más para ofrecerle \nla seguridad de los datos que mejor se adapte a las características específicas de su empresa. Por lo general, este proceso tarda unas horas, pero merece la pena. &#x1F609;",
      [MessageType.AUDIT_LOGS_DISABLED]:
        "No hay acceso a los registros de auditoría de {service}.\nCompruebe que <a href='{link}' target=\"_blank\">{linkDescription}</a> se ha habilitado.",
      [MessageType.AUDIT_LOGS_DISABLED_RESOLVED]:
        "Se ha restablecido el acceso a los registros de auditoría de {service}.",
      [MessageType.AUDIT_LOGS_INTERRUPTED]:
        "Se ha interrumpido el acceso a los registros de auditoría de {service}.\nNo hay registros de auditoría desde {startDate}.",
      [MessageType.AUDIT_LOGS_INTERRUPTED_RESOLVED]:
        "Se ha interrumpido el acceso a los registros de auditoría de {service}.\nEl problema se produjo entre el {startDate} y el {endDate}.\nSe ha resuelto el problema.",
      [MessageType.GOOGLE_DRIVE_SDK_DISABLED]: "Se ha deshabilitado el SDK de Google Drive",
      [MessageType.GOOGLE_DRIVE_SDK_DISABLED_RESOLVED]: "Se ha habilitado el SDK de Google Drive",
      [MessageType.SERVICE_HAS_BEEN_DISCONNECTED]: "{service} se ha desconectado de Coro",
      [MessageType.SERVICE_HAS_BEEN_DISCONNECTED_RESOLVED]:
        "Se ha restablecido la conexión a {service}",
      [MessageType.SERVICE_HAS_BEEN_DISCONNECTED_BY_OPERATOR]:
        "{operator_name} ha restablecido la conexión al servicio {service}.",
      [MessageType.ADDON_NOT_INSTALLED]:
        "Recomendamos instalar el complemento de Coro para {service} para permitir comentarios instantáneos sobre correos electrónicos de phishing de los usuarios de su empresa.",
      [MessageType.CONNECTOR_HAS_BEEN_DISCONNECTED]:
        "El conector {connectorName} se ha desconectado de Coro",
      [MessageType.MAX_PROTECTED_USERS_HAS_EXCEEDED]:
        "El número de usuarios protegidos ha superado su límite de {MAX_PROTECTED_USERS} usuarios. Para aumentar el límite, póngase en contacto con su proveedor de servicios.",
    },
    auditLogs: "Registros de auditoría",
    gSuiteAuditLogs: "Registros de auditoría de Google Workspace",
    learnMore: "Más información",
    noAlerts: "No tiene ninguna alerta",
    noNewAlerts: "No tiene ninguna alerta nueva",
    upgradeNow: "Actualizar ahora",
    subscriptionExpired: "Suscripción vencida",
    regularSubscriptionExpiredDescription:
      "Actualmente su Workspace está inactivo.<br>Para suscribirse, póngase en contacto con el administrador de su cuenta de Coro.",
    mspSubscriptionExpiredDescription:
      "Actualmente su Workspace está inactivo.<br>Para suscribirse, póngase en contacto con su proveedor de servicios.",
    daysRemaining: "sobrante en su prueba gratuita",
  },
  pniVerification: {
    success: "¡Gracias!",
    failure: "Se ha producido un error",
    successMessage: "Sus comentarios se han recibido correctamente.",
    failureMessage: "Sus comentarios no se han recibido. Inténtelo de nuevo en unos minutos.",
  },
  mdmCallbackPage: {
    contactSupport: "Contactar con el equipo de asistencia",
    errors: {
      generic: "Se ha producido un error al acceder a Mobile Device Management",
    },
  },
  login: {
    title: "Iniciar sesión",
    email: "Correo electrónico",
    password: "Contraseña",
    signIn: "Iniciar sesión",
    signUp: "Registrarse",
    dontHaveAccount: "¿No tiene una cuenta?",
    forgotPassword: "¿Ha olvidado su contraseña?",
    errors: {
      badCredentials: "Credenciales incorrectas. Compruébelas e inténtelo de nuevo.",
      noAccountFound: "No se ha encontrado ninguna cuenta de Coro",
      generalError: "Se ha producido un error",
      genericError: "Se ha producido un error",
      workspaceIsArchived: "No se ha encontrado ninguna cuenta de Coro",
      loginUnsuccessful: "No hemos podido iniciar su sesión. Inténtelo de nuevo más tarde.",
      noOfficeAccount:
        "Esta cuenta de Microsoft 365 no está asociada a una cuenta de Coro. Inténtelo de nuevo o haga clic en el enlace «Registrarse» que aparece a continuación",
      noGoogleAccount:
        "Esta cuenta de Google Workspace no está asociada a una cuenta de Coro. Inténtelo de nuevo o haga clic en el enlace «Registrarse» que aparece a continuación",
      [AccountErrors.NOT_ACCEPTED_INVITATION]:
        "Su cuenta aún no está activada. Revise su correo electrónico para ver un enlace de invitación.",
    },
  },
  forgotPassword: {
    title: "Restablecer contraseña",
    resetPasswordBtn: "Restablecer contraseña",
    explanation: "Le ayudaremos a restablecerla y a recuperar el acceso.",
    backToLoginBtn: "Volver al inicio de sesión",
    backToDashboardBtn: "Volver al panel de control",
    successText: "Compruebe su correo electrónico y haga clic en el enlace.",
    emailNotFound:
      "No se ha encontrado el correo electrónico. Inténtelo con otro correo electrónico.",
    genericError: "Se ha producido un error al restablecer la contraseña. Inténtelo de nuevo.",
    resendLink: "Reenviar enlace",
    tryDifferentEmail: "Intentar con otro correo electrónico",
    checkYourEmail: "Compruebe su correo electrónico",
    recoverInstruction: "Hemos enviado instrucciones para recuperar la contraseña a {email}",
    didNotReceiveEmail:
      "¿No ha recibido el correo electrónico? Revise su carpeta de correo no deseado",
  },
  resetPassword: {
    title: "Crear nueva contraseña",
    resetPasswordBtn: "Restablecer contraseña",
    newPassword: "Nueva contraseña",
    repeatNewPassword: "Repetir la contraseña nueva",
    successText: "Su contraseña se ha restablecido correctamente",
    passwordsNotMatch: "Las contraseñas no coinciden.",
    passwordShouldBeDifferent: "La contraseña debe ser diferente a la anterior.",
    resetLinkInvalid: "El enlace para restablecer la contraseña ha vencido o no es válido.",
    genericError: "Se ha producido un error al restablecer la contraseña. Inténtelo de nuevo.",
    description:
      "Su nueva contraseña debe ser diferente a las contraseñas utilizadas anteriormente.",
    weakPassword:
      "La contraseña debe tener un mínimo de 8 símbolos e incluir al menos 1 mayúscula, 1 minúscula y 1 símbolo numérico.",
  },
  forms: {
    email: {
      label: "Correo electrónico",
      placeholder: "Escriba la dirección de correo electrónico",
    },
    password: {
      title: "Contraseña",
      placeholder: "Escriba la contraseña",
    },
  },
  downloadsPage: {
    title:
      "{customer} le ha invitado a instalar la <b>protección de terminales de Coro</b> en sus dispositivos.",
    downloadBtn: "Descargar e instalar",
    footerDescription:
      "Después de la instalación, su dispositivo quedará protegido bajo la suscripción de {customer}. Cuando instale la <b>protección de terminales de Coro</b> y la active con su dirección de correo electrónico del trabajo, su dispositivo quedará registrado en la cuenta de {customer}. Esto permitirá que {customer} supervise la posición de seguridad de su dispositivo, así como comprobar su ubicación en caso de que se produzca un evento de seguridad.",
  },
  mfa: {
    title: "Autenticación de dos pasos",
    notice:
      "La actualización de su autenticación de dos pasos aquí también se aplicará a todos los Workspaces de los que forme parte.",
    mfaEnabled: "La autenticación de dos pasos está habilitada",
    mfaEnabledDesc:
      "You’ve enabled two factor authentication for your account using an authenticator app.",
    mfaDisabled: "La autenticación de dos pasos está deshabilitada",
    recoveryMobile: "Su número de teléfono móvil de recuperación",
    disableMFAButton: "Eliminar los datos de la autenticación de dos pasos",
    fallbackTitle: "Habilitar el método de recuperación alternativo",
    fallbackDesc:
      "Introduzca un número de teléfono en caso de que no pueda autenticarse y necesite recuperar su código por SMS.",
    fallbackCodeTitle: "Introduzca el código de 6 dígitos que ha recibido en su teléfono",
    finishSetupBtn: "Finalizar configuración",
    smsSent: "SMS enviado",
    createMFATitle: "Crear autenticación de dos pasos",
    createMFADesc:
      "Escanee este código QR en su aplicación móvil de autenticación (como <a href='https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid&oco=0' target='_blank'>Google Authenticator</a>) y, después, introduzca el código que aparece a continuación para confirmar. Para añadir la entrada de forma manual, utilice este código: <strong>{code}</strong>. La autenticación de dos pasos no estará activa hasta que haga este paso.",
    enterCode: "Introducir código",
    resendSMS: "Reenviar SMS",
  },
  verification: {
    title: "Confirme que es usted",
    app: {
      enterCode: "Introduzca el código de 6 dígitos desde su aplicación de autenticación",
      fallbackLink: "Enviar código desde SMS",
    },
    sms: {
      enterCode: "Introduzca el código de 6 dígitos que ha recibido en su teléfono",
      fallbackLink: "Introduzca el código de la aplicación de autenticación",
      noSMS: "¿No ha recibido el SMS?",
    },
    supportLink:
      "<a href='https://docs.coro.net/' target=\"_blank\">Contactar con el equipo de asistencia</a>",
    verifyBtn: "Confirmar",
    cantFindCode: "¿No encuentra su código?",
  },
  invitedPage: {
    cloudAppsSecurity: "Aplicaciones en la nube",
    emailSecurity: "Correo electrónico",
    deviceSecurity: "Dispositivos terminales",
    dataSecurity: "Datos",
    usersSecurity: "Usuarios",
    title: "Establezca una contraseña",
    welcomeToCoro: "Le damos la bienvenida a Coro",
    youHaveBeenInvited:
      "<span class='text--semibold'>{email}</span> le ha invitado a unirse a <span class='text--semibold'>{workplace}</span>",
    youHaveBeenInvitedViaApi:
      "Se le ha invitado a unirse a <span class='text--semibold'>{workplace}</span>",
    acceptInvitationBtn: "Aceptar invitación",
    acceptAndLoginBtn: "Aceptar e iniciar sesión",
    setPasswordBtn: "Establecer contraseña",
    newPassword: "Nueva contraseña",
    successText: "Su contraseña se ha establecido correctamente",
    successfullyJoined: "Se ha unido al Workspace correctamente",
    goToLogin: "Ir a inicio de sesión",
    errors: {
      tokenExpired:
        "El enlace de la invitación ha vencido. Póngase en contacto con  {admin} para recibir un enlace nuevo.",
      inviteRevoked:
        "La invitación se ha anulado. Póngase en contacto con {admin} para recibir un enlace nuevo.",
      tokenExpiredViaApi:
        "El enlace de la invitación ha vencido. Póngase en contacto con la persona que le invitó.",
      inviteRevokedViaApi:
        "La invitación se ha anulado. Póngase en contacto con la persona que le invitó.",
      privacyPolicy: "Tiene que aceptar los términos de uso y la política de privacidad",
    },
    termsOfUseDescription: "Acepto",
    termsOfUseLink: "los términos de uso de Coro",
    privacyPolicyLink: "la política de privacidad de Coro",
  },
  settings: {
    title: "Panel de control",
    protectionCoverage: "Cobertura de protección",
    account: "Cuenta",
    adminAccounts: "Administradores",
    accessControl: "Control de acceso",
    activityLogs: "Registros de actividad",
    users: "Usuarios",
    notifications: "Notificaciones",
    endpointSecurity: "Endpoint Security",
    devices: "Dispositivos",
    connect: "Conectar",
    soc: "Servicio gestionado",
    security: "Configuración de la seguridad",
    malware: "Malware y ransomware",
    phishing: "Phishing",
    accountBlocker: "Bloqueador de cuentas",
    dataLoss: "Gobernanza de datos",
    cloudSecurity: "Cloud Security",
    cloudApps: "Aplicaciones en la nube",
    accountSecurity: "Sesiones activas",
    settingsBreadCrumb: "Configuración",
    protection: "Protección",
    connectors: "Conexiones",
    reports: "Informes",
    billing: "Facturación y suscripciones",
    email: "Email Security",
    userDataGovernance: "User Data Governance",
    endpointDataGovernance: "Endpoint Data Governance",
    workspace: "Workspace",
    emailProxy: "Servidor proxy del correo electrónico",
    coroMail: "Mensajes seguros",
    network: "Network",
    edr: "EDR",
    mdm: "Mobile Device Management",
    mdmShort: "Mobile Device Mgmt.",
    demoMode: {
      title: "Modo de prueba",
      resetBtn: "Restablecer",
    },
  },
  sso: {
    loginInProcess: "Espere, estamos iniciando su sesión.",
    errors: {
      genericError: "Se ha producido un error al iniciar sesión. Inténtelo de nuevo más tarde.",
    },
  },
  signUpPage: {
    title: "Pruebe Coro gratis",
    signUpOffice: "Regístrese con su cuenta de administrador de Microsoft 365",
    signUpGoogle: "Regístrese con su cuenta de administrador de Google Workspace",
    signUpBtn: "Registrarse",
    description: "Su prueba gratis incluye todas las funciones «premium»",
    cloudAppsSecurity: "Aplicaciones en la nube",
    emailSecurity: "Correo electrónico",
    deviceSecurity: "Dispositivos terminales",
    dataSecurity: "Datos",
    usersSecurity: "Usuarios",
    welcomeToCoronet: "Le damos la bienvenida a Coro",
    office365NextStep: "Está a un paso de obtener protección total para Microsoft 365.",
    googleNextStep: "Está a un paso de obtener protección total para Google Workspace.",
    finishSetupBtn: "Finalizar configuración",
    errors: {
      notAdmin: "Debe iniciar sesión en Coro con una cuenta de administrador.",
      accountNotAssociatedOffice:
        "La cuenta no está asociada con ninguna función de administrador en Microsoft 365",
      accountNotAssociatedGoogle:
        "La cuenta no está asociada con ninguna función de administrador en Google Workspace",
      accountAlreadyCreated: "La cuenta de su empresa ya está creada.",
      contactAdmin:
        "Póngase en contacto con <span class='text--link'>{admin}</span> para crear una cuenta.",
      newOfficeAccountAlertText: "Parece que esta cuenta de Microsoft 365 es nueva.",
      newOfficeAccountErrorText:
        "Como Microsoft 365 tarda unas horas en configurar privilegios para las nuevas cuentas, regístrese en Coro un poco más tarde.",
      privacyPolicy: "Tiene que aceptar los términos de uso y la política de privacidad",
    },
    termsOfUseDescription: "Acepto",
    termsOfUseLink: "los términos de uso de Coro",
    privacyPolicyLink: "la política de privacidad de Coro",
  },
  dashboard: {
    addons: "Complementos",
    seeDisabledModules: "Ver módulos deshabilitados...",
    hideDisabledModules: "Ocultar módulos deshabilitados...",
    seeDisabledAddons: "Ver complementos deshabilitados...",
    hideDisabledAddons: "Ocultar complementos deshabilitados...",
    widgets: {
      overviewWidget: {
        noIssues: "No hay problemas",
        addProtection: "Añadir protección",
        ticketsToReview: "ticket para revisión | tickets para revisión",
        types: {
          userDataGovernance: {
            title: "User Data Governance",
            description: "Problemas",
          },
          endpointDataGovernance: {
            title: "Endpoint Data Governance",
            description: "Problemas",
          },
          emailSecurity: {
            title: "Email Security",
            description: "Problemas",
          },
          endpointSecurity: {
            title: "Endpoint Security",
            description: "Problemas",
          },
          cloudSecurity: {
            title: "Cloud Security",
            description: "Problemas",
          },
          edr: {
            title: "EDR",
          },
          network: {
            title: "Network",
            description: "Configuración de los ajustes",
          },
          mdm: {
            title: "Mobile Device Management",
          },
          inboundGateway: {
            title: "Inbound Gateway",
            description: "Configuración de los ajustes",
          },
          wifiPhishing: {
            title: "Wi-Fi Phishing",
            description: "Configuración de los ajustes",
          },
          secureWebGateway: {
            title: "Secure Web Gateway",
            description: "Configuración de los ajustes",
          },
          securedMessages: {
            title: "Mensajes seguros",
            description: "Configuración de los ajustes",
          },
          placeholder: {
            title: "No hay ningún módulo habilitado",
            noAccessTitle: "Sin acceso a módulos habilitados",
          },
        },
        resolved: "resuelto",
        ninetyDays: "90 días",
      },
      alerts: {
        title: "Mensajes",
        categories: {
          cloudApps: "Cloud Security",
          connectors: "Conexiones",
        },
        types: {
          ON_BOARDING_COMPLETED:
            "¡Le damos la bienvenida a Coro! Su cuenta empresarial de {service} se ha conectado correctamente y\n ahora Coro se encarga de proteger su empresa. Ahora nuestros sistemas se adaptarán y optimizarán aún más para ofrecerle \nla seguridad de los datos que mejor se adapte a las características específicas de su empresa. Por lo general, este proceso tarda unas horas, pero merece la pena. &#x1F609;",
          AUDIT_LOGS_DISABLED:
            "No hay acceso a los registros de auditoría de {service}.\nCompruebe que <a href='{link}' target=\"_blank\">{linkDescription}</a> se ha habilitado.",
          AUDIT_LOGS_DISABLED_RESOLVED:
            "Se ha restablecido el acceso a los registros de auditoría de {service}.",
          AUDIT_LOGS_INTERRUPTED:
            "Se ha interrumpido el acceso a los registros de auditoría de {service}.\nNo hay registros de auditoría desde {startDate}.",
          AUDIT_LOGS_INTERRUPTED_RESOLVED:
            "Se ha interrumpido el acceso a los registros de auditoría de {service}.\nEl problema se produjo entre el {startDate} y el {endDate}.\nSe ha resuelto el problema.",
          GOOGLE_DRIVE_SDK_DISABLED: "Se ha deshabilitado el SDK de Google Drive",
          GOOGLE_DRIVE_SDK_DISABLED_RESOLVED: "Se ha habilitado el SDK de Google Drive",
          SERVICE_HAS_BEEN_DISCONNECTED: "{service} se ha desconectado de Coro",
          SERVICE_HAS_BEEN_DISCONNECTED_RESOLVED: "Se ha restablecido la conexión a {service}",
          SERVICE_HAS_BEEN_DISCONNECTED_BY_OPERATOR:
            "{operator_name} ha restablecido la conexión al servicio {service}.",
          ADDON_NOT_INSTALLED:
            "Recomendamos instalar el complemento de Coro para {service} para permitir comentarios instantáneos sobre correos electrónicos de phishing de los usuarios de su empresa.",
          CONNECTOR_HAS_BEEN_DISCONNECTED: "El conector {connectorName} se ha desconectado de Coro",
          MAX_PROTECTED_USERS_HAS_EXCEEDED:
            "El número de usuarios protegidos ha superado su límite de {MAX_PROTECTED_USERS} usuarios. Para aumentar el límite, póngase en contacto con su proveedor de servicios.",
        },
        auditLogs: "Registros de auditoría",
        gSuiteAuditLogs: "Registros de auditoría de Google Workspace",
        learnMore: "Más información",
        noAlerts: "No tiene ninguna alerta",
        noNewAlerts: "No tiene ninguna alerta nueva",
        upgradeNow: "Actualizar ahora",
        subscriptionExpired: "Suscripción vencida",
        regularSubscriptionExpiredDescription:
          "Actualmente su Workspace está inactivo.<br>Para suscribirse, póngase en contacto con el administrador de su cuenta de Coro.",
        mspSubscriptionExpiredDescription:
          "Actualmente su Workspace está inactivo.<br>Para suscribirse, póngase en contacto con su proveedor de servicios.",
        daysRemaining: "sobrante en su prueba gratuita",
      },
      allIssues: "Todos los problemas",
      userDataGovernance: {
        title: "User Data Governance",
        noDataLossProtection:
          "Actualmente no tiene User Data Governance ni protección frente a pérdida de datos",
        noDLPServicesConnected: "Conecte Microsoft 365 o Google Workplace a Coro",
        objectsProcessed: "Objetos de datos procesados",
        topViolators: "Usuarios con violaciones de permisos",
        last90Days: "90 días",
      },
      endpointDataGovernance: {
        title: "Endpoint Data Governance",
        noDataLossProtection:
          "Actualmente no tiene Endpoint Data Governance ni protección frente a pérdida de datos",
        noDLPServicesConnected:
          "Instale el cliente de Endpoint Security de Coro en los dispositivos de los usuarios",
        objectsProcessed: "Objetos de datos procesados",
        topViolators: "Usuarios con violaciones de permisos",
        last90Days: "90 días",
        addProtectionBtn: "Añadir protección",
      },
      devices: {
        title: "Endpoint Security",
        chartLabel: "{n} dispositivo | {n} dispositivos",
        protectedDevices: "Dispositivos",
        noDevicesProtected: "Actualmente no tiene ningún dispositivo protegido",
        installCoroClients:
          "Instale el cliente de Endpoint Security de Coro en los dispositivos de los usuarios",
        addProtectionBtn: "Añadir protección",
        protectedUsers: "Usuarios protegidos",
        last90Days: "90 días",
      },
      emailPhishing: {
        title: "Email Security",
        emailSecurityDisabled: "Actualmente Email Security está deshabilitado",
        enableEmailMonitoring:
          "Habilite la supervisión de sus correos electrónicos para detectar malware y phishing",
        enableEmailMonitoringBtn: "Habilitar",
        noEmailServicesConnected: "Su aplicación de correo electrónico no está conectada",
        connectSecureCloud: "Actualmente no está conectado a Microsoft 365 ni a Google Workspace",
        addProtectionBtn: "Añadir protección",
        topSuspiciousDomains: "Principales fuentes de correos electrónicos sospechosos",
        emailsScanned: "Correos electrónicos procesados",
        maliciousEmails: "Malicioso",
        quarantined: "En cuarentena",
        blockedAndDiscarded: "Bloqueado y descartado",
        noSuspiciousDomainsFound: "No se han encontrado dominios sospechosos",
        suspiciousPhishing: "Contenido del correo electrónico sospechoso",
        suspiciousMalware: "Malware en archivos adjuntos del correo electrónico",
        last90Days: "90 días",
      },
      cloudSecurity: {
        title: "Cloud Security",
        noAppsProtected: "Actualmente no tiene ninguna aplicación en la nube protegida",
        noAppsProtectedDescription:
          "Conecte Microsoft 365 o Google Workspace para iniciar la protección",
        noIssuesFound: "No se han encontrado problemas",
        notConnected: "No conectado",
        connectionIncomplete: "Conexión incompleta",
        auditLogsAreDisabled: "Los registros de auditoría están deshabilitados",
        protectedUsers: "Usuarios protegidos",
        chartLabel: "{n} usuario | {n} usuarios",
        services: {
          boxEnforcement: "Box",
          googleDirectoryEnforcement: "Google",
          dropboxEnforcement: "Dropbox",
          office365Enforcement: "Microsoft 365",
          slackEnforcement: "Slack",
          salesforceEnforcement: "Salesforce",
          zoomEnforcement: "Zoom",
        },
      },
      disclaimerWidget: {
        title: "No tiene módulos habilitados",
        regularWorkspaceDescription:
          "Póngase en contacto con el departamento de ventas de Coro para habilitar los módulos que quiera.",
        mspWorkspaceDescription:
          "Póngase en contacto con su proveedor de servicios para habilitar los módulos que quiera.",
        noAccess: "No tiene acceso a todos los módulos.",
      },
      edr: {
        title: "EDR",
        processes: "Procesos",
        topProcesses: "Procesos que más afectan",
        last30Days: "30 días",
      },
    },
  },
  comments: {
    title: "Comentarios",
    sentTo: "Notificaciones enviadas a:",
    notifySocContacts: "Contactos del servicio gestionado",
    notifyAllWorkspaceAdmins: "Todos los administradores del Workspace",
    notifyAffectedUsers: "Todos los usuarios afectados",
    plusNComments: "+{n} comentarios",
    confidential: "Confidencial",
    noPermission: "No tiene permisos para comentar en este ticket",
    unread: "No leído",
    read: "Leído",
    markAsRead: "Marcar como leído",
    markAsUnread: "Marcar como no leído",
    noComments: "Sin comentarios",
  },
  phishingEmailDetails: {
    emailBodyEmpty: "Cuerpo del correo electrónico vacío",
  },
  processGraph: {
    backToTicket: "Volver al ticket",
    relatedTicket: "Ticket relacionado",
    pid: "PID",
    relatedUser: "Usuario relacionado",
    executionTime: "Tiempo de ejecución",
    commandLineArguments: "Argumentos de la línea de comandos",
    persistence: "Persistencia",
    fileInformation: "Información del archivo",
    imageFileInformation: "Ubicación del archivo de imagen",
    shaHash: "Hash SHA256",
    fileSize: "Tamaño del archivo",
    fileCreationTime: "Hora de creación del archivo",
    lastModifiedTime: "Hora de la última modificación",
    signatureStatus: "Estado de la firma",
    verificationStatus: "Estado de la verificación",
    anchorProcess: "Proceso de anclaje",
    processStatus: "Process {status} on {date}",
  },
};
