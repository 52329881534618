import { i18n } from "@/plugins/i18n";
import { defineStore } from "pinia";
import { axiosInstance } from "@/plugins/https";
import api from "@/_helpers/api";
import { SnackbarTypes, useSnackbarStore } from "@/_store/snackbar.module";
import { useAccountStore } from "@/_store/account.module";
import type { MfaStatus } from "@/constants/account";
import type { WorkspaceLocale } from "@/constants/workplaces";
import cloneDeep from "lodash/cloneDeep";

interface MyAccountModuleState {
  myAccount: {
    profileData: ProfileData;
    notificationsSettings: NotificationsSettings;
    skeletonLoader: boolean;
    loading: boolean;
  };
}

export interface NotificationsSettings {
  dailyEmailUpdates: boolean;
  criticalTickets: boolean;
  couldBeUpdated: boolean;
  releaseNotes: boolean;
  productAnnouncements: boolean;
  eventTriggers: { [key: string]: boolean };
}

export interface ProfileData {
  allowMfa: boolean;
  firstName?: string;
  lastName?: string;
  mfaConfig?: MfaStatus;
  preferredLanguageCode?: WorkspaceLocale;
}

const defaultMyAccountState: MyAccountModuleState = {
  myAccount: {
    profileData: {
      allowMfa: true,
    },
    notificationsSettings: {
      eventTriggers: {},
      couldBeUpdated: false,
      criticalTickets: false,
      dailyEmailUpdates: false,
      releaseNotes: false,
      productAnnouncements: false,
    },
    skeletonLoader: false,
    loading: false,
  },
};

export const useMyAccountStore = defineStore("myAccount", {
  state: (): MyAccountModuleState => cloneDeep(defaultMyAccountState),
  persist: true,
  actions: {
    setProfileData(payload: ProfileData) {
      this.myAccount.profileData = payload;
    },
    setLoadingState(status: boolean) {
      this.myAccount.loading = status;
    },
    resetState() {
      this.$reset();
    },
    async getProfileData() {
      const request = {
        ...api.myAccount,
      };
      this.myAccount.skeletonLoader = true;
      try {
        const { data } = await axiosInstance.request(request);
        this.setProfileData(data);
        this.myAccount.skeletonLoader = false;
      } catch {
        this.myAccount.skeletonLoader = false;
      }
    },
    async updateProfileData({ firstName, lastName, preferredLanguageCode }: ProfileData) {
      this.myAccount.loading = true;

      const request = {
        ...api.myAccount,
        method: "PUT",
        data: {
          firstName,
          lastName,
          preferredLanguageCode,
        },
      };

      try {
        await axiosInstance.request(request);

        this.myAccount.profileData = {
          ...this.myAccount.profileData,
          firstName,
          lastName,
          preferredLanguageCode,
        };

        const snackbarStore = useSnackbarStore();
        snackbarStore.add({
          html: i18n.global.t("snackbar.messages.myAccount.updated"),
          type: SnackbarTypes.SUCCESS,
        });
      } catch (error) {
        console.error(error);
      }

      this.myAccount.loading = false;
    },
    async setPassword(payload: { oldPassword?: string; newPassword: string }) {
      const accountStore = useAccountStore();
      const brandingAlias = accountStore.account.brandingAlias;
      const params: { brandingAlias?: string } = {};
      if (brandingAlias) {
        params.brandingAlias = brandingAlias;
      }
      const request = {
        ...api.changePassword,
        method: "PUT",
        data: payload,
        params,
      };

      if (!payload.oldPassword) {
        request.method = "POST";
      }
      try {
        await axiosInstance.request(request);
        if (!payload.oldPassword && accountStore.logged) {
          await this.getProfileData();
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async getNotificationsSettings() {
      const request = {
        ...api.getNotificationsSettings,
      };

      this.myAccount.skeletonLoader = true;
      try {
        const { data } = await axiosInstance.request(request);
        this.myAccount.skeletonLoader = false;
        this.myAccount.notificationsSettings = data;
      } catch (e) {
        this.myAccount.skeletonLoader = false;
        console.error(e);
      }
    },
    async updateNotificationsSettings(payload: NotificationsSettings) {
      const request = {
        ...api.getNotificationsSettings,
        method: "PUT",
        data: payload,
      };
      try {
        await axiosInstance.request(request);
        this.myAccount.notificationsSettings = payload;
      } catch (e) {
        console.error(e);
      }
    },
  },
});
