<template>
  <div class="margin-auto workspaces-page" data-testid="workplaces-page-container">
    <div class="headline5 workspaces-page__title mb-3">
      {{ $t("workspaces.title") }}
    </div>
    <div class="d-inline-flex justify-space-between align-center w-100 mb-4">
      <v-tabs
        v-model="selectedTab"
        align-with-title
        color="orange-base"
        background-color="white"
        slider-color="orange-base"
      >
        <v-tab v-for="(item, index) in tabs" :key="item" :ripple="false" class="mr-6" :height="42">
          {{ item }}
          <v-avatar
            v-if="index === 1"
            class="ml-2"
            :text="pendingInvitesTotal.toString()"
            variant="tonal"
            density="compact"
          />
        </v-tab>
      </v-tabs>
      <filter-wrapper :show-clear-button="false">
        <v-text-field
          v-if="selectedTab === 0"
          v-model="workplaceName"
          data-testid="workplace-page-workplace-search"
          :placeholder="$t('general.search')"
          class="search-field filter-menu"
          :class="{ 'filter-active': workplaceName?.length }"
          prepend-inner-icon="icon-search"
          hide-details
          clearable
          clear-icon="icon-x"
          density="compact"
        ></v-text-field>
        <v-text-field
          v-else
          v-model="inviteName"
          data-testid="workplace-page-invite-search"
          :placeholder="$t('general.search')"
          class="search-field filter-menu"
          :class="{ 'filter-active': inviteName?.length }"
          prepend-inner-icon="icon-search"
          hide-details
          clearable
          clear-icon="icon-x"
          density="compact"
        ></v-text-field>
      </filter-wrapper>
    </div>
    <v-window v-model="selectedTab">
      <v-window-item :value="0">
        <workspaces-table
          :items="workspaces"
          :total-items="total"
          :action="workplacesActions.LAUNCH"
          :pagination="pagination"
          :loading="workspacesLoading"
          @action-applied="selectWorkplace($event)"
          @page-changed="onWorkplacePageChanged($event)"
        />
      </v-window-item>
      <v-window-item :value="1">
        <workspaces-table
          :items="pendingInvites"
          :total-items="pendingInvitesTotal"
          :action="workplacesActions.JOIN"
          :pagination="pendingInvitesPagination"
          :loading="pendingInvitesLoading"
          @action-applied="onJoinWorkplaceClick($event)"
          @page-changed="onInvitePageChanged($event)"
        />
      </v-window-item>
    </v-window>
  </div>
</template>

<script lang="ts">
import { WorkspaceAction } from "@/constants/workplaces";
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
import FilterWrapper from "@/components/FilterWrapper.vue";
import { useWorkspacesStore, type WorkspaceItem } from "@/_store/workspaces.module";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import WorkspacesTable from "@/components/tables/WorkspacesTable.vue";
import type { Pagination } from "@/types";
import { debounce } from "lodash";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants/routes.ts";

export default defineComponent({
  components: {
    WorkspacesTable,
    FilterWrapper,
  },
  setup() {
    const workspaceStore = useWorkspacesStore();
    const i18n = useI18n();
    const selectedTab = ref(0);
    const workplaceName = ref("");
    const inviteName = ref("");
    const workspacesLoading = ref(false);
    const pendingInvitesLoading = ref(false);
    const router = useRouter();
    const {
      setPagination,
      setPendingInvitesPagination,
      getWorkspaces: getWorkspacesFromStore,
      getPendingInvites: getPendingInvitesFromStore,
      resetState,
      joinWorkplace,
      setWorkspaces,
      setPendingInvites,
    } = workspaceStore;
    const {
      total,
      workspaces,
      pendingInvites,
      pagination,
      pendingInvitesPagination,
      pendingInvitesTotal,
    } = storeToRefs(workspaceStore);

    const tabs = computed(() => {
      const defaultTabs = [i18n.t("workspaces.tabs.workplaces")];
      if (pendingInvitesTotal.value > 0) {
        defaultTabs.push(i18n.t("workspaces.tabs.pendingInvitations"));
      }
      return defaultTabs;
    });

    const selectWorkplace = async (workspace: WorkspaceItem) => {
      await workspaceStore.updateWorkspaceAndPermissions(workspace.workspaceId);
      await router.push({
        name: RouteName.DASHBOARD,
        query: { workspaceId: workspace.workspaceId },
      });
    };

    const onJoinWorkplaceClick = async (workspace: WorkspaceItem) => {
      await joinWorkplace(workspace.workspaceId);
      await workspaceStore.updateWorkspaceAndPermissions(workspace.workspaceId);
      await router.push({
        name: RouteName.DASHBOARD,
        query: { workspaceId: workspace.workspaceId },
      });
    };

    const getWorkspaces = async (name: string = "") => {
      workspacesLoading.value = true;
      await getWorkspacesFromStore(name);
      workspacesLoading.value = false;
    };

    const getPendingInvites = async (name: string = "") => {
      pendingInvitesLoading.value = true;
      await getPendingInvitesFromStore(name);
      pendingInvitesLoading.value = false;
    };

    watch(
      workplaceName,
      debounce((newVal) => {
        setWorkspaces([]);
        setPagination({
          page: 0,
          pageSize: 25,
        });
        getWorkspaces(newVal.length < 3 ? "" : newVal);
      }, 500)
    );

    watch(
      inviteName,
      debounce((newVal) => {
        setPendingInvites([]);
        setPendingInvitesPagination({
          page: 0,
          pageSize: 25,
        });
        getPendingInvites(newVal.length < 3 ? "" : newVal);
      }, 500)
    );

    watch(pendingInvitesTotal, (newVal) => {
      if (newVal) {
        selectedTab.value = 1;
      }
    });

    onMounted(async () => {
      await getPendingInvites();
    });

    onUnmounted(() => {
      resetState();
    });

    const onWorkplacePageChanged = (pagination: Pagination) => {
      setPagination(pagination);
      getWorkspaces();
    };

    const onInvitePageChanged = (pagination: Pagination) => {
      setPendingInvitesPagination(pagination);
      getPendingInvites();
    };

    return {
      selectedTab,
      workplaceName,
      inviteName,
      workplacesActions: WorkspaceAction,
      tabs,
      workspaces,
      total,
      pagination,
      pendingInvites,
      pendingInvitesPagination,
      pendingInvitesTotal,
      workspacesLoading,
      pendingInvitesLoading,
      selectWorkplace,
      onJoinWorkplaceClick,
      onWorkplacePageChanged,
      onInvitePageChanged,
    };
  },
});
</script>
<style lang="scss" scoped>
.workspaces-page {
  width: 716px;

  &__title {
    margin-top: 70px;
  }
}
</style>
