<template>
  <v-app class="vue3">
    <v-main>
      <navigation-drawer v-if="logged" />
      <app-bar
        v-if="logged && account.workplace"
        :app-title="appTitle"
        :show-workspace-selector="!isMspRoute"
      />
      <v-container fluid :class="{ 'pa-0 h-100': !logged }">
        <router-view :key="workspaceAndPermissionsUpdatedTimestamp" />
      </v-container>
    </v-main>
    <CoroDialog
      :config="dialogConfig"
      :value="showDialog"
      v-if="showDialog"
      @onClose="closeDialog"
    />
    <CoroSnackbar />
  </v-app>
</template>

<script lang="ts">
import AppBar from "@/components/AppBar.vue";
import CoroSnackbar from "@/components/CoroSnackbar.vue";
import CoroDialog from "@/components/modals/CoroDialog.vue";

import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useErrorsStore } from "@/_store/errors.module";
import { SnackbarTypes, useSnackbarStore } from "@/_store/snackbar.module";
import { useDialogsStore } from "@/_store/dialogs.module";
import { storeToRefs } from "pinia";
import { useWorkspacesStore } from "@/_store/workspaces.module";
import { useMyAccountStore } from "@/_store/my-account.module";
import { useSubscriptionStore } from "@/_store/subscription.module";
import {
  dialogsConfigConstructor,
  extractCoroSubdomain,
  getSelectedAddons,
  getSelectedModules,
  handleFaviconAndTitle,
} from "@/_helpers/utils";
import ErrorModal from "@/components/modals/ErrorModal.vue";
import { ModalWidth } from "@/constants/modals";
import { useAccountStore } from "@/_store";
import NavigationDrawer from "@/components/NavigationDrawer.vue";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants/routes";
import { enc, HmacSHA256 } from "crypto-js";
import { i18n } from "@/plugins/i18n";
import moment from "moment/moment";
import { WorkspaceType } from "@/constants/workplaces";
import { useLocale } from "vuetify";
import { useRedirectsStore } from "@/_store/redirects.module.ts";
const chameleonSecret = import.meta.env.VITE_CHAMELEON_SECRET;
const environment = import.meta.env.VITE_CLIENT_ENV;

export default defineComponent({
  components: {
    NavigationDrawer,
    CoroSnackbar,
    CoroDialog,
    AppBar,
  },
  setup() {
    const errorsStore = useErrorsStore();
    const snackbarStore = useSnackbarStore();
    const dialogsStore = useDialogsStore();
    const workspacesStore = useWorkspacesStore();
    const myAccountStore = useMyAccountStore();
    const accountStore = useAccountStore();
    const subscriptionStore = useSubscriptionStore();
    const { handleSocAutomationAfterLogin } = useRedirectsStore();
    const { closeDialog, openDialog } = dialogsStore;
    const { showDialog, dialogConfig } = storeToRefs(dialogsStore);
    const { error, errors } = storeToRefs(errorsStore);
    const { subscription } = storeToRefs(subscriptionStore);
    const { add } = snackbarStore;
    const { logged, account, displayLanguage } = storeToRefs(accountStore);
    const { workspaceAndPermissionsUpdatedTimestamp } = storeToRefs(workspacesStore);
    const { current: vuetifyLocale } = useLocale();
    const workspaceTimestamp = ref(0);
    const route = useRoute();
    const router = useRouter();

    const subdomain = computed(() => {
      return extractCoroSubdomain(window.location.hostname);
    });

    const isMspRoute = computed(() => {
      const mspParentRoute = router.resolve({ name: RouteName.MSP_PAGE });
      return route.fullPath.startsWith(mspParentRoute.fullPath);
    });

    const appTitle = computed(() => {
      if (isMspRoute.value) {
        return i18n.global.t("general.workspacesManagement");
      }
      if (account.value.parentWorkspaceName) {
        return account.value.parentWorkspaceName;
      }

      if (account.value.workspaceType === WorkspaceType.CHANNEL) {
        return account.value.customerName;
      }

      return i18n.global.t("general.coroConsole");
    });

    watch(subdomain, (newVal) => {
      handleFaviconAndTitle(newVal!);
    });

    watch(
      () => route.name,
      (currentRouteName) => {
        const brandedRoutesNames = [
          RouteName.INVITED_PAGE,
          RouteName.LOGIN,
          RouteName.RESET_PASSWORD,
          RouteName.WORKSPACES,
          RouteName.FORGOT_PASSWORD,
          RouteName.SSO_PAGE,
          RouteName.DOWNLOADS_PAGE,
        ];
        if (brandedRoutesNames.includes(currentRouteName as RouteName)) {
          subdomain.value
            ? accountStore.requestBrandingInfo(subdomain.value)
            : accountStore.$patch((state) => {
                state.account.brandingAlias = subdomain.value ?? "";
              });
        }
      }
    );

    const openErrorModal = (errorMessage: string) => {
      const errorModal = {
        width: ModalWidth.SMALL,
        header: undefined,
        content: {
          component: ErrorModal,
        },
        footer: null,
        data: {
          item: errorMessage,
        },
      };
      const dialogConfig = dialogsConfigConstructor(errorModal);
      openDialog(dialogConfig);
    };

    const getLoggedUserDataForChameleon = () => {
      const modules = getSelectedModules(subscription.value);
      const bundles = subscription.value.bundles;
      const addons = getSelectedAddons(subscription.value);
      const { workspaceType, workplace } = account.value;
      return {
        workspaceType,
        uid: workplace,
        workspaceId: workplace,
        subscription: {
          modules,
          bundles,
          addons,
        },
        subscriptionType: subscription.value.currentPlan.subscriptionType,
      };
    };

    const identifyChameleon = () => {
      const { email, workplace } = account.value;
      const userId = email && workplace ? `${email}+${workplace}` : "unknown user";
      const uid_hash = HmacSHA256(userId, chameleonSecret).toString(enc.Hex);
      if (accountStore.logged) {
        chmln.identify(userId, {
          uid_hash,
          environment,
          email,
          ...getLoggedUserDataForChameleon(),
        });
      } else {
        chmln.identify(userId, {
          uid_hash,
          environment,
        });
      }
    };

    watch(
      () => [...errors.value],
      (newValue, previousValue) => {
        // If error is null or popping errors from store - do nothing
        if (!error.value || newValue.length < previousValue.length) return;
        const errorMessage = i18n.global.te(`errors.${error.value.message}`)
          ? i18n.global.t(`errors.${error.value.message}`)
          : error.value.message;
        const showModal = error.value.showDialog;
        if (showModal) {
          openErrorModal(errorMessage);
        } else {
          const snackbarMessage = {
            html: errorMessage,
            type: SnackbarTypes.ERROR,
          };
          add(snackbarMessage);
        }
      },
      { deep: true }
    );

    // logging out in other open tabs if logout event happened
    window.addEventListener("storage", (event) => {
      if (event.key === "logout-event") {
        accountStore.$reset();
      }

      if (event.key === "report-navigation") {
        subscriptionStore.$reset();
        window.location.reload();
      }
    });

    onMounted(async () => {
      if (logged.value) {
        await workspacesStore.getCurrentWorkspace();
        await myAccountStore.getProfileData();
      }
      handleFaviconAndTitle(subdomain.value!);
      identifyChameleon();
    });

    watch(
      () => account.value.workplace,
      async (newVal, oldVal) => {
        if (oldVal !== newVal) {
          identifyChameleon();
        }
      }
    );

    watch(
      () => logged.value,
      async (newVal) => {
        if (newVal) {
          await handleSocAutomationAfterLogin();
        }
      },
      { immediate: true }
    );

    watch(
      displayLanguage,
      (value, previousValue) => {
        if (value !== previousValue) {
          i18n.global.locale.value = value;
          moment.locale(value);
          vuetifyLocale.value = value;
        }
      },
      { immediate: true }
    );

    return {
      errors,
      dialogConfig,
      showDialog,
      closeDialog,
      logged,
      account,
      workspaceTimestamp,
      isMspRoute,
      appTitle,
      workspaceAndPermissionsUpdatedTimestamp,
    };
  },
});
</script>

<style lang="scss">
@use "main";
</style>
