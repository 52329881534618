<template>
  <v-card class="h-100">
    <v-card-text>
      <div class="ml-4 mt-3 mr-4">
        <div class="subtitle2 mb-5">
          {{ $t("devicesSettings.settingsCard.heartbeatInterval") }}
        </div>
        <v-select
          v-model="localValue.heartbeatInterval"
          :disabled="actionNotAllowed"
          class="heartbeat-interval-select"
          :items="[1, 7, 17, 29]"
          data-testid="devices-settings-heartbeat-interval"
          outlined
        >
          <template #item="{ item, props }">
            <v-list-item
              v-bind="props"
              :title="$t('devicesSettings.settingsCard.selectOption', item.value)"
            ></v-list-item>
          </template>
          <template #selection="{ item }">
            <span data-testid="devices-settings-heartbeat-interval-selection">
              {{ $t("devicesSettings.settingsCard.selectOption", item.value) }}
            </span>
          </template>
        </v-select>
        <div class="body2">
          {{ $t("devicesSettings.settingsCard.heartbeatIntervalDescription") }}
        </div>

        <v-divider class="mt-3 mb-6" />

        <v-checkbox
          v-model="localValue.allowToUninstallEnabled"
          data-testid="device-settings-tamper-resistance-checkbox"
          :ripple="false"
          class="mt-3"
          :label="$t('devicesSettings.settingsCard.allowToUninstallEnabled')"
          :disabled="actionNotAllowed"
        />
        <div
          class="body2 ml-7"
          v-html="$t('devicesSettings.settingsCard.allowToUninstallDescription')"
        />

        <div class="body1 mt-4 ml-7" :class="{ 'text-disabled': actionNotAllowed }">
          {{ $t("devicesSettings.settingsCard.applyToDevices") }}
        </div>
        <v-autocomplete
          v-model="localValue.allowToUninstallLabels"
          class="mt-4 ml-7"
          :items="labels"
          :label="$t('devicesSettings.settingsCard.deviceLabelsPlaceholder')"
          :placeholder="$t('devicesSettings.settingsCard.deviceLabelsPlaceholder')"
          :disabled="actionNotAllowed"
          :loading="loading"
          variant="outlined"
          multiple
          return-object
          chips
          closable-chips
          data-testid="device-settings-tamper-resistance-labels"
          item-value="id"
          item-title="name"
        >
          <template v-slot:chip="{ props, item }">
            <v-chip
              v-bind="props"
              :closable="true"
              variant="flat"
              size="default"
              :color="item.raw.color"
              close-icon="$closeCircle"
              :text="item.title"
              class="text-primary"
            >
              <div class="d-flex align-center">
                <span class="ml-1 mr-2">{{ item.title }}</span>
              </div>
            </v-chip>
          </template>
          <template #item="{ item, props }">
            <v-list-item v-bind="props" title="">
              <div class="d-flex align-center label-popup-block">
                <div class="color-preview mr-2" :style="{ 'background-color': item.raw.color }" />
                <span
                  :data-testid="`device-settings-tamper-resistance-${kebabCase(
                    item.raw.name
                  )}-select-item`"
                >
                  {{ item.raw.name }}
                </span>
              </div>
            </v-list-item>
          </template>
        </v-autocomplete>

        <v-divider class="mt-3 mb-6" />

        <v-checkbox
          v-model="localValue.visibilityModeEnabled"
          data-testid="device-settings-visibility-mode-checkbox"
          :ripple="false"
          class="mt-3"
          :label="$t('devicesSettings.settingsCard.visibilityMode')"
          :disabled="actionNotAllowed"
        />
        <div class="body2 ml-7">
          {{ $t("devicesSettings.settingsCard.visibilityModeDescription") }}
        </div>
        <div class="body1 mt-4 ml-7" :class="{ 'text-disabled': actionNotAllowed }">
          {{ $t("devicesSettings.settingsCard.applyToDevices") }}
        </div>
        <v-autocomplete
          v-model="localValue.visibilityModeLabels"
          class="mt-4 ml-7"
          :items="labels"
          :label="deviceLabelsPlaceholder(localValue.visibilityModeLabels?.length)"
          :placeholder="$t('devicesSettings.settingsCard.deviceLabelsPlaceholder')"
          :disabled="actionNotAllowed"
          :loading="loading"
          variant="outlined"
          multiple
          return-object
          chips
          closable-chips
          data-testid="device-settings-visibility-mode-labels"
          item-value="id"
          item-title="name"
        >
          <template v-slot:chip="{ props, item }">
            <coro-label v-bind="props" class="mr-1" :background-color="item.raw.color">
              <div class="d-flex align-center">
                <span class="ml-1 mr-2">{{ item.raw.name }}</span>
                <v-icon small rounded icon="$x" @click="props['onClick:close']"> </v-icon>
              </div>
            </coro-label>
          </template>
          <template #item="{ item, props }">
            <v-list-item v-bind="props" title="">
              <div class="d-flex align-center label-popup-block">
                <div class="color-preview mr-2" :style="{ 'background-color': item.raw.color }" />
                <span
                  :data-testid="`device-settings-visibility-mode-${kebabCase(
                    item.raw.name
                  )}-select-item`"
                >
                  {{ item.raw.name }}
                </span>
              </div>
            </v-list-item>
          </template>
        </v-autocomplete>

        <v-divider class="mt-8 mb-8" />

        <div class="subtitle1">
          {{ $t("devicesSettings.settingsCard.autoUpdateTitle") }}
        </div>
        <div class="body2">
          {{ $t("devicesSettings.settingsCard.autoUpdateLabelsDescription") }}
        </div>
        <div class="body1 mt-4" :class="{ 'text-disabled': actionNotAllowed }">
          {{ $t("devicesSettings.settingsCard.applyToDevices") }}
        </div>
        <v-autocomplete
          v-model="localValue.autoUpdateLabels"
          class="mt-4"
          :items="labels"
          :loading="loading"
          loa
          :label="deviceLabelsPlaceholder(localValue.autoUpdateLabels?.length)"
          :placeholder="$t('devicesSettings.settingsCard.deviceLabelsPlaceholder')"
          :disabled="actionNotAllowed"
          variant="outlined"
          multiple
          return-object
          chips
          closable-chips
          data-testid="enable-atc-labels"
          item-value="id"
          item-title="name"
        >
          <template v-slot:chip="{ props, item }">
            <coro-label v-bind="props" class="mr-1" :background-color="item.raw.color">
              <div class="d-flex align-center">
                <span class="ml-1 mr-2">{{ item.raw.name }}</span>
                <v-icon small rounded icon="$x" @click="props['onClick:close']"> </v-icon>
              </div>
            </coro-label>
          </template>
          <template #item="{ item, props }">
            <v-list-item v-bind="props" title="">
              <div class="d-flex align-center label-popup-block">
                <div class="color-preview mr-2" :style="{ 'background-color': item.raw.color }" />
                <span
                  :data-testid="`device-settings-auto-update-${kebabCase(
                    item.raw.name
                  )}-select-item`"
                >
                  {{ item.raw.name }}
                </span>
              </div>
            </v-list-item>
          </template>
        </v-autocomplete>
        <div class="d-inline-flex align-center mt-2">
          <span class="caption">{{ $t("general.poweredBy") }}</span>
          <v-img class="ml-1" src="/images/logos/bitdefender-logo.png" width="84" contain />
        </div>
        <!--        @TODO uncomment when BE will be ready-->
        <!--                <div class="subtitle2 mb-5">{{ $t("devicesSettings.settingsCard.allowToUninstallTitle") }}</div>-->
        <!--                <div class="body1 mt-4">{{ $t("devicesSettings.settingsCard.applyToDevices") }}</div>-->
        <!--                <v-autocomplete-->
        <!--                  v-model="localValue.allowToUninstallLabels"-->
        <!--                  class="mt-4"-->
        <!--                  :items="labels"-->
        <!--                  :label="deviceLabelsPlaceholder(localValue.allowToUninstallLabels?.length)"-->
        <!--                  :placeholder="$t('devicesSettings.settingsCard.deviceLabelsPlaceholder')"-->
        <!--                  :disabled="actionNotAllowed"-->
        <!--                  outlined-->
        <!--                  multiple-->
        <!--                  return-object-->
        <!--                  data-testid="enable-atc-labels"-->
        <!--                  item-value="id"
                              item-text="name"-->
        <!--                >-->
        <!--                  <template #selection="{ attrs, item, parent, selected }">-->
        <!--                    <v-chip-->
        <!--                      v-bind="attrs"-->
        <!--                      class="label-chip"-->
        <!--                      :style="{ 'background-color': item.color }"-->
        <!--                      :input-value="selected"-->
        <!--                    >-->
        <!--                      <div class="d-flex align-center">-->
        <!--                        <span class="ml-1 mr-2 body2">{{ item.name }}</span>-->
        <!--                        <v-icon-->
        <!--                          small-->
        <!--                          rounded-->
        <!--                          @click="parent.selectItem(item)"-->
        <!--                        >-->
        <!--                          {{ $vuetify.icons.values.closeCircle }}-->
        <!--                        </v-icon>-->
        <!--                      </div>-->
        <!--                    </v-chip>-->
        <!--                  </template>-->
        <!--                  <template #item="{ item, attrs, on }">-->
        <!--                    <v-list-item v-bind="attrs" v-on="on">-->
        <!--                      <v-list-item-content class="d-flex align-center label-popup-block">-->
        <!--                        <span class="color-preview mr-2" :style="{ 'background-color': item.color }" />-->
        <!--                        <span>{{ item.name }}</span>-->
        <!--                      </v-list-item-content>-->
        <!--                    </v-list-item>-->
        <!--                  </template>-->
        <!--                </v-autocomplete>-->
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { isWorkspaceFrozenOrActionRestricted, useTwoWayBinding } from "@/_helpers/utils";
import { type DevicesSettings, useDevicesSettingsStore } from "@/_store/devices-settings.module";
import { storeToRefs } from "pinia";
import { RolePermissionsScope, WorkspaceManagementScopeSections } from "@/_store/roles.module";
import { useI18n } from "vue-i18n";
import kebabCase from "lodash/kebabCase";
import CoroLabel from "@/components/CoroLabel.vue";

export default defineComponent({
  components: { CoroLabel },
  setup() {
    const i18n = useI18n();
    const loading = ref(false);
    const deviceSettingsStore = useDevicesSettingsStore();
    const { settings, labels } = storeToRefs(deviceSettingsStore);
    const updateSettings = async (payload: DevicesSettings) => {
      loading.value = true;
      await deviceSettingsStore.updateDevicesSettings(payload);
      loading.value = false;
    };
    const localValue = useTwoWayBinding(settings, updateSettings);

    const actionNotAllowed = computed(() => {
      return isWorkspaceFrozenOrActionRestricted(
        RolePermissionsScope.WORKSPACE_MANAGEMENT,
        WorkspaceManagementScopeSections.DEVICES
      );
    });

    onMounted(async () => {
      await deviceSettingsStore.getDevicesSettings();
      await deviceSettingsStore.getLabels();
    });

    const deviceLabelsPlaceholder = (labelsLength: number | undefined) => {
      return labelsLength ? i18n.t("devicesSettings.settingsCard.deviceLabelsPlaceholder") : "";
    };

    return {
      labels,
      localValue,
      actionNotAllowed,
      loading,
      deviceLabelsPlaceholder,
      kebabCase,
    };
  },
});
</script>

<style lang="scss" scoped>
.heartbeat-interval-select {
  width: 200px;
}

.color-preview {
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  border-radius: 2px !important;
}

.label-popup-block span {
  flex: unset !important;
}

:deep(*) {
  .label-chip {
    border-radius: 2px;
  }
}
</style>
